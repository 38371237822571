<div class="search-container">
  <input
    class="search-input"
    type="text"
    placeholder="Chercher un produit, une reference, une marque …"
    [formControl]="searchControl"
    [matAutocomplete]="searchAutocomplete"
    (keyup.enter)="search($event)"
  />
  <div class="search-icon" (click)="searchIconClicked()">
    <img src="/assets/icons/search.svg" alt="Icone search">
  </div>
</div>
<mat-autocomplete #searchAutocomplete="matAutocomplete">

</mat-autocomplete>
