import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  AbstractControl,
  Validators,
} from '@angular/forms';

import { ClientService } from 'tekkeys-common';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from 'shared/services/snackbar/snackbar.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
  isLoading = false;
  error = false;
  success = false;

  errorMessage = '';

  passwordResetForm: UntypedFormGroup;
  get passwordControl(): AbstractControl {
    return this.passwordResetForm.get('password');
  }

  constructor(
    private clientService: ClientService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.onVerifiedToken();

    this.passwordResetForm = this.fb.group({
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\s])(\S){8,}$/
          ),
        ]),
      ],
    });
  }

  onVerifiedToken() {
    const token = this.route.snapshot.queryParamMap.get('token');

    this.clientService.verifyPasswordResetToken(token).subscribe(
      () => {},
      (e) => {
        this.error = true;
        this.success = false;
        setTimeout(() => {
          this.router.navigate(['/']);
        }, 3000);
        if (e.status === 401){
          this.errorMessage = 'Le lien est expiré !';
        } else {
          this.errorMessage = 'Une erreur est survenue !';
        }
      }
    );
  }

  onPasswordReset() {
    const token = this.route.snapshot.queryParamMap.get('token');
    const payload = this.passwordControl.value;
    this.isLoading = true;
    this.clientService.passwordReset(token, payload).subscribe(
      () => {
        this.isLoading = false;
        this.success = true;
        setTimeout(() => {
          this.router.navigate(['account', 'login']);
        }, 2000);
      },
      () => {
        this.isLoading = false;
        this.error = true;
        this.success = false;
        this.snackbarService.openSnackBar(
          'Une erreur est survenue !',
          'error-snackbar'
        );
        setTimeout(() => {
          this.router.navigate(['/']);
        }, 2000);
      }
    );
  }
}
