<input
  [(ngModel)]="value"
  (blur)="onTouch()"
  class="password-input"
  [type]="showPassword ? 'text' : 'password'"
  [placeholder]="placeholder"
/>
<img
  *ngIf="visibilityToggle"
  class="visibility-toggle"
  (click)="showPassword = !showPassword"
  [src]="
    showPassword
      ? 'assets/icons/visibility_off.svg'
      : 'assets/icons/visibility-on.svg'
  "
  alt="show password"
/>
