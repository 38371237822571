import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, switchMapTo } from 'rxjs/operators';
import { SnackbarService } from 'shared/services/snackbar/snackbar.service';
import { ClientAddressDTO, ClientService } from 'tekkeys-common';

import {
  addToAddresses,
  addToAddressesFailed,
  addToAddressesSuccess,
  loadAddresses,
  loadAddressesSuccess,
  removeAddress,
  removeAddressSuccess,
  updateAddresses,
  updateAddressesFailure,
  updateAddressesSuccess,
} from './addresses.actions';

@Injectable()
export class AddressesEffects {
  constructor(
    private clientService: ClientService,
    private actions: Actions,
    private snackbarService: SnackbarService
  ) {}
  init = createEffect(() =>
    this.actions.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMapTo(of(loadAddresses()))
    )
  );
  loadAddresses = createEffect(() =>
    this.actions.pipe(
      ofType(loadAddresses),
      switchMap((action) =>
        this.clientService.getClientAddresses().pipe(
          map((response: ClientAddressDTO[]) =>
            loadAddressesSuccess({
              addresses: response,
            })
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  addToAddresse = createEffect(() =>
    this.actions.pipe(
      ofType(addToAddresses),
      switchMap((action) =>
        this.clientService
          .addClientAddress(action.addresses, action.userId)
          .pipe(
            map((response) => addToAddressesSuccess({ addresses: response })),
            catchError((e) => of(addToAddressesFailed({ error: e })))
          )
      )
    )
  );
  updateAddresse = createEffect(() =>
    this.actions.pipe(
      ofType(updateAddresses),
      switchMap((action) =>
        this.clientService
          .updateClientAddress(action.addresses, action.userId)
          .pipe(
            map((response) =>
              updateAddressesSuccess({
                addresses: action.addresses,
                partialAddress: action.partialAddress,
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(updateAddressesFailure({ errorCode: error.status }))
            )
          )
      )
    )
  );
  removeAddresse = createEffect(() =>
    this.actions.pipe(
      ofType(removeAddress),
      switchMap((action) =>
        this.clientService.deleteClientAddress(action.idUser, action.id).pipe(
          map((response) => removeAddressSuccess({ id: action.id })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  addToAddressesSuccess$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(addToAddressesSuccess),
        map(() =>
          this.snackbarService.openSnackBar(
            'Demande envoyée. Pour plus d\'informations, veuillez contacter le service commercial.',
            'success-snackbar'
          )
        )
      ),
    { dispatch: false }
  );

  addToAddressesFailed$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(addToAddressesFailed),
        map(() =>
          this.snackbarService.openSnackBar(
            'Ce nom d\'adresse est déjà utilisée!',
            'error-snackbar'
          )
        )
      ),
    { dispatch: false }
  );
}
