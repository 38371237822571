<div
  fxLayout
  class="category-dropdown"
  [ngClass]="{ dropdown: showDropDown }"
  (mouseleave)="showDropDown = true"
>
  <div
         fxFlex="317px" fxLayout="column" class="parent-categories" >
    <a
      *ngFor="let category of parentCategories | async"
      [routerLink]="['/catalog/browse', category.id, category.name]"
      (click)="showDropDown = true"
      (mouseenter)="selectCategory(category)"
    >
      {{ category.name }}
    </a>
  </div>

  <div

    *ngIf="selectedCategory"
    fxFlex="grow"
    fxLayout="column wrap"
    fxLayoutAlign="start start"
    class="child-categories"
  >
    <a
      *ngFor="let category of childCategories | async"
      [routerLink]="[
        '/catalog/browse',
        selectedCategory.id,
        selectedCategory.name
      ]"
      (click)="showDropDown = true"
      [queryParams]="{ categories: category.id }"
    >
      {{ category.name }}
    </a>
  </div>
</div>
