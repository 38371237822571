import { Action, createReducer, on } from '@ngrx/store';

import { loadCategories, loadCategoriesFailure, loadCategoriesSuccess } from './categories.actions';
import { CategoriesState } from './categories.state';

export const initialState: CategoriesState = {
  categories: [],
  isLoading: false,
  error: false,
  errorMessage: null,
};

const reducer = createReducer(
  initialState,
  on(loadCategories, (state: CategoriesState) => {
    return {
      categories: state.categories,
      isLoading: true,
      error: false,
      errorMessage: null,
    };
  }),
  on(loadCategoriesSuccess, (state: CategoriesState, action) => {
    return {
      categories: action.categories,
      isLoading: false,
      error: false,
      errorMessage: null,
    };
  }),
  on(loadCategoriesFailure, (state: CategoriesState) => {
    return {
      categories: state.categories,
      isLoading: false,
      error: true,
      errorMessage: 'Une erreur est survenue lors du chargement des catégories',
    };
  })
);

export function categoriesReducer(state: CategoriesState, action: Action) {
  return reducer(state, action);
}
