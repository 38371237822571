import { Pipe, PipeTransform } from '@angular/core';
import { ClientAddressDTO } from 'tekkeys-common';

@Pipe({
  name: 'addressTrans',
})
export class AddressTransPipe implements PipeTransform {
  transform(address: Partial<ClientAddressDTO>): string {
    return (
      address.contactName +
      '#' +
      address.contactPhone +
      '#' +
      address.streetName +
      '  ' +
      address.additionalInfo +
      ' ' +
      address.cedex +
      '#' +
      address.postalCode +
      '#' +
      address.city +
      '#' +
      address.countryID
    );
  }
}
