import { createAction, props } from '@ngrx/store';

import { AuthTokenDTO } from 'tekkeys-common';

export const login = createAction(
  '[Auth] Login',
  props<{ mail: string; password: string }>()
);

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ AuthTokenDTO: AuthTokenDTO; mail: string }>()
);

export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{
    errorCode: number;
    remainingTime?: number;
    remainingAttempts?: number;
  }>()
);

export const signIn = createAction(
  '[Auth] Sign In',
  props<{ registrationToken: string }>()
);

export const signInSuccess = createAction(
  '[Auth] Sign In Success',
  props<{ AuthTokenDTO: AuthTokenDTO }>()
);

export const signInFailure = createAction(
  '[Auth] Sign In Failure',
  props<{
    errorCode: number;
  }>()
);

export const fadeOut = createAction('[Auth] Login FadeOut');

export const logout = createAction('[Auth] Logout');
