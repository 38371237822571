import { Component, Inject } from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';
export interface Snack {
  message: string;
  action: string;
  type: string;
}

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent{
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: Snack,
    private snackBar: MatSnackBar
  ) {}


  exit() {
    this.snackBar.dismiss();
  }
}
