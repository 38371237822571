<div fxLayout="column" [style.margin-left]="'20px'" [style.margin-right]="'20px'" [style.margin-bottom]="'24px'">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <div *ngIf="product.productResources.length>1" class="prev-button" (click)="previousSlide()">
            <div class="inner-button">
                <img src="assets/icons/previous.svg" alt="previous" />
            </div>
        </div>

        <div class="carousel">
            <div class="slides"
                [ngClass]="{'scroll-disabled': scrollbar}"
                [ngStyle]="{ transform: 'translateX(' + -currentSlideIndex * 100 + '%)' }">
                <div *ngFor="let slide of product.productResources" class="slide">
                    <figure class="zoom" (mouseleave)="reset($event)" (mousewheel)="resize($event)"
                        (mousemove)="zoom($event)" [ngStyle]="{
            backgroundImage: 'url(\'' + slide.link + '\')',
            backgroundSize: '150%'
          }">
                        <img [src]="slide.link" alt=""/>
                    </figure>
                </div>
            </div>

        </div>


        <div *ngIf="product.productResources.length>1" class="next-button" (click)="nextSlide()">
            <div class="inner-button">
                <img src="assets/icons/next.svg" alt="next" />
            </div>
        </div>

    </div>

    <div *ngIf="product.productResources.length>1" class="indicators">
        <div class="indicator" *ngFor="let slide of product.productResources; index as index"
            [ngClass]="{ 'indicator-active': index === currentSlideIndex }" (click)="setSlide(index)"></div>
    </div>

</div>
