import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {MatAutocomplete} from '@angular/material/autocomplete';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
  ],
})
export class TextInputComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() matAutocomplete: MatAutocomplete;
  @Input() postIcon: string;
  @Input() readOnly = false;
  private innerValue: string;

  get value(): string {
    return this.innerValue;
  }

  set value(v: string) {
    if (!this.readOnly) {
      this.innerValue = v;
      this.onChange(v);
    }
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: string): void {
    this.innerValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  toggleSelect(input: any){
    if (!this.readOnly) {
      input.focus();
    }
  }
}
