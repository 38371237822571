import { NgModule } from '@angular/core';
import { RouterModule, Routes, InitialNavigation} from '@angular/router';
import { FullLayoutComponent } from 'shared/components/full-layout/full-layout.component';
import { MinimalLayoutComponent } from 'shared/components/minimal-layout/minimal-layout.component';
import { NotFoundComponent } from 'shared/components/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'catalog',
        loadChildren: () =>
          import('./catalog/catalog.module').then((m) => m.CatalogModule),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'about',
        loadChildren: () =>
          import('./about/about.module').then((m) => m.AboutModule),
      },
      {
        path: 'blog',
        loadChildren: () =>
          import('./blog/blog.module').then((m) => m.BlogModule),
      },
    ],
  },
  {
    path: '',
    component: MinimalLayoutComponent,
    children: [
      {
        path: 'account',
        loadChildren: () =>
          import('./account/account.module').then((m) => m.AccountModule),
      },
    ],
  },
  {
    path: 'checkout',
    loadChildren: () =>
    import('./checkout/checkout.module').then((m) => m.CheckoutModule),
  },
  { path: '**', redirectTo: 'not-found' },
  { path: 'not-found', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    initialNavigation: 'enabled' as InitialNavigation,
    scrollPositionRestoration: 'enabled'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
