import { Component, OnInit } from '@angular/core';
import { OverlayService } from 'shared/services/overlay/overlay.service';
import {FooterInfoService} from '../../../../../projects/tekkeys-common/src/lib/DAO/footer/footer-info.service';
import {footerInfoDto} from '../../../../../projects/tekkeys-common/src/lib/DTO/footer-info.dto';
import {Store} from '@ngrx/store';
import {selectIsConnected} from 'store/account/account.selectors';
import {selectCompanyPageLoading} from 'store/company/company.selectors';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss'],
})
export class SupportDialogComponent implements OnInit {
  isOpen = true;
  contactInformation: footerInfoDto [];
  tel: string ;
  mail: string;
  isConnected: boolean;
  name: string;
  lastname: string;
  dateOuvert: string ;
  dateClose: string ;
  iFrame: SafeHtml;
  constructor(private overlayService: OverlayService,
              private serviceFooter: FooterInfoService,
              private store: Store,
              private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.store.select(selectIsConnected).subscribe((isConnected) => {
      this.isConnected = isConnected;
      this.store.select(selectCompanyPageLoading).subscribe(me => {
        if (this.isConnected && me.commercials.length > 0){
          this.tel = me.commercials[0].phone1;
          this.mail = me.commercials[0].mail;
          this.name = me.commercials[0].name;
          this.lastname = me.commercials[0].lastname;
          // get the adress from serviceFooter
          this.serviceFooter.getAllContactInformation().subscribe(
            res => {
              this.contactInformation  = res;
              this.dateOuvert = res[0].openingTime.slice(0, -3);
              this.dateClose = res[0].closingTime.slice(0, -3);
              this.iFrame = this.sanitizer.bypassSecurityTrustHtml(this.contactInformation[0].carteHTML);
              const hour = new Date().getHours();
              this.isOpen = hour.toString()  < this.dateClose;

            }
          );
        } else {
          this.serviceFooter.getAllContactInformation().subscribe(
            res => {
              this.contactInformation  = res;
              this.tel = this.contactInformation[0].phoneOne;
              this.mail = this.contactInformation[0].mail;
              this.dateOuvert = this.contactInformation[0].openingTime.slice(0, -3);
              this.dateClose = this.contactInformation[0].closingTime.slice(0, -3);
              this.iFrame = this.sanitizer.bypassSecurityTrustHtml(this.contactInformation[0].carteHTML);
              const hour = new Date().getHours();
              this.isOpen = hour.toString()  < this.dateClose;

            }
          );
        }
      } );
    });
  //  this.tel[] = this.contactInformation[0].phoneOne;
  }

    closeDialog() {
        this.overlayService.closeDilog();
      }

    sendEmail() {
        window.location.href = 'mailto:' + this.mail;
        this.closeDialog();
      }

    call() {
        window.location.href = 'tel:' + this.tel;
        this.closeDialog();
      }
    }
