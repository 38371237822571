<div class="dialog" xmlns="http://www.w3.org/1999/html" style="display: flex; flex-direction: column; align-items: center; margin-bottom: 10px">
  <div class="dialog-titlebar">
    &nbsp;<h3></h3>
    <button style="background-color: var(--red-700);border: transparent;cursor: pointer" (click)="close()">
      <img  src="assets/icons/close.svg" alt="close" />
    </button>
  </div>
  <div class="dialog-content">
    Vous devez rejoindre une entreprise pour poursuivre vos achats
  </div>
  <button
    style="width: 55%; height: 2.5em"
    class="app-stroked-button"
    type="button"
    (click)="addCompany()"
  >
    AJOUTER UNE ENTREPRISE
  </button>
</div>
