import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActionsSubject, Store } from '@ngrx/store';
import { ClientAddressDTO, CountryDTO, CountryService } from 'tekkeys-common';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, shareReplay, skip, startWith, take } from 'rxjs/operators';
import { AccountState } from 'store/account/account.state';
import {
  addToAddresses,
  addToAddressesSuccess,
} from 'store/addresses/addresses.actions';
import {OverlayService} from 'shared/services/overlay/overlay.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-add-addresse-dialog',
  templateUrl: './add-addresse-dialog.component.html',
  styleUrls: ['./add-addresse-dialog.component.scss'],
})
export class AddAddresseDialogComponent implements OnInit {
  isLoading = false;
  payload: any;
  idUser: any;
  addAddressForm: UntypedFormGroup;
  DELIVRY_ADDRESS = 'delivryAddress';
  loadingSubject = new BehaviorSubject<boolean>(true);
  // private PHONE_REGEX = /^(0|\+33|0033)[1-9](\s*?[0-9]\s*?){8}$/;

  countries: CountryDTO[];
  countries$: Observable<CountryDTO[]> = this.countryService
    .countries()
    .pipe(take(1));

  isCountry = true;

  accountStore: Observable<AccountState> = this.store
    .select('account')
    .pipe(shareReplay());

  constructor(
    public  overlayService: OverlayService,
    public dialogRef: MatDialogRef<AddAddresseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private countryService: CountryService,
    private store: Store<{ account: AccountState }>,
    private actionListener$: ActionsSubject
  ) {}

  ngOnInit(): void {
    this.countries$.subscribe((countries) => {
      this.countries = countries;
      this.countries$ = this.addAddressForm.get('countryID').valueChanges.pipe(
        startWith(''),
        map((value) => {
          this.isCountry = !!this.countries?.find(
            (country) =>
              country.name === this.addAddressForm.get('countryID').value
          );
          return this.countries?.filter((country) => {
            return (
              country.name.toLowerCase().includes(value.toLowerCase()) &&
              country.name !== value
            );
          });
        })
      );
    });
    this.accountStore.subscribe((data) => (this.idUser = data.account.id));

    this.addAddressForm = new UntypedFormGroup({
      id: new UntypedFormControl(0),
      type: new UntypedFormControl(this.DELIVRY_ADDRESS),
      city: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-ZÀ-ÿ-. ]*$'),
      ]),
      countryID: new UntypedFormControl('', Validators.required),
      addressTitle: new UntypedFormControl(''),
      streetName: new UntypedFormControl('', Validators.required),
      additionalInfo: new UntypedFormControl(''),
      postalCode: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$'),
      ]),
      cedex: new UntypedFormControl('', Validators.pattern('^[0-9]{2}$')),
      contactName: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9 ]*$')
      ]),
      contactPhone: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          // Validators.minLength(10),
         // Validators.maxLength(14),
         // Validators.pattern(this.PHONE_REGEX),
        ])
      ),
      isDefault: new UntypedFormControl(0),
    });
    this.dialogRef.disableClose = true;

    this.markAllFieldsAsTouched();
  }
  markAllFieldsAsTouched() {
    this.addAddressForm.get('city').markAllAsTouched();
    this.addAddressForm.get('contactPhone').markAllAsTouched();
    this.addAddressForm.get('postalCode').markAllAsTouched();
    this.addAddressForm.get('contactName').markAllAsTouched();
    this.addAddressForm.get('streetName').markAllAsTouched();
    this.addAddressForm.get('countryID').markAllAsTouched();


  }
  onKeyPress(event: KeyboardEvent): boolean {
    const regex = new RegExp('^[a-zA-Z0-9 ]*$'); // Autoriser lettres, chiffres et espaces
    const inputChar = String.fromCharCode(event.keyCode || event.which);

    if (!regex.test(inputChar)) {
      event.preventDefault(); // Empêche la saisie si ce n'est pas valide
      return false;
    }
    return true;
  }
  onAdd() {
    this.payload = this.addAddressForm.value;
    this.addClientAddress();
  }

  addClientAddress() {
    let clientAddressToAdd: ClientAddressDTO;
    const selectedCountryName = this.addAddressForm.get('countryID').value;
    const selectedCountry = this.countries.find((c) => c.name === selectedCountryName);
    clientAddressToAdd = {
      ...this.payload,
      countryID: selectedCountry.id,
    };
    this.store.dispatch(
      addToAddresses({ addresses: clientAddressToAdd, userId: this.idUser })
    );
    this.isLoading = true;
    this.actionListener$
      .pipe(
        skip(1) // optional: skips initial logging done by ngrx
      )
      .subscribe((action) => {
        if (action.type === addToAddressesSuccess.type) {
          this.dialogRef.close();
        }
      });
  }
}
