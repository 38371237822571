<mat-radio-group class="radio-group">
  <div class="divRadio" *ngFor="let item of items">
    <mat-radio-button
      class="radio-button"
      #input="ngModel"
      [(ngModel)]="value"
      ngDefaultControl
      [value]="item.value"
      [checked]="item.checked"
      >{{ item.label }}</mat-radio-button
    >
  </div>
</mat-radio-group>
