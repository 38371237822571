import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  constructor(private meta: Meta) {
    /*  for SEO
     ** in order to handle soft errors:  prevent crawlers from crawling the content of the page */
    this.meta.addTag({ name: 'robots', content: 'noindex' });
  }
}
