<div class="content">
  <app-minimal-navbar></app-minimal-navbar>
  <router-outlet></router-outlet>
  <!--Footer-->
  <mat-divider
    style="border: solid 3em #E5E5E5; margin-top: 100px"
    ngStyle.lt-sm="margin-bottom: 0; border-width: 6em;display: block;"
  ></mat-divider>
  <app-footer class="footer container"></app-footer>
  <app-copyright-notice></app-copyright-notice>
</div>
