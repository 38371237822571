<label
  [ngClass]="{
    'label-center': !input.value,
    'label-top': input.value
  }"
>
  {{ label }}
</label>

<textarea
  class="text-input"
  #input="ngModel"
  [(ngModel)]="value"
  (blur)="onTouch()"
  [rows]="rows"
  [style]="{ height: height }"
></textarea>
