<div class="header">
  <img matRipple [matRippleCentered]="false" [matRippleUnbounded]="true" class="logo"
    src="assets/imtech-new-logo.svg" alt="logo imtech" routerLink="/" />

  <img class="close-btn" src="assets/icons/close-gray.svg" alt="" (click)="closeSidenav()" />
</div>

<ng-container *ngFor="let category of parentCategories | async">

  <button  (click)="selectCategory(category.id); $event.stopPropagation()"  matRipple
    class="category" [class.selected]="selectedCategoryId === category.id"
    >

    <span >{{ category.name }} </span>

    <img (click)="selectCategory(category.id); $event.stopPropagation()"
         [src]="selectedCategoryId === category.id
          ? 'assets/icons/remove-white.svg'
          : 'assets/icons/add.svg'" alt="" />
  </button>

  <div class="subcategories" [@expansion]="selectedCategoryId === category.id ? 'expanded' : 'collapsed'">
    <ng-container *ngFor="let subcategory of childCategories(category.id) | async">
      <button matRipple class="category"  [class.selected2]="selectedChildCategoryId === subcategory.id"
      (click)="selectChildCategory(subcategory.id); $event.stopPropagation()">

        <span>{{ subcategory.name }}</span>
        <img (click)="selectChildCategory(subcategory.id); $event.stopPropagation()" [src]="
          selectedChildCategoryId === subcategory.id
            ? 'assets/icons/remove-white.svg'
            : 'assets/icons/add.svg'
        " alt="" />
      </button>

      <div class="subsubcategories" [@expansion]="selectedChildCategoryId === subcategory.id ? 'expanded' : 'collapsed'">
        <button matRipple *ngFor="let subChildCategory of subChildCategories(subcategory.id) | async" (click)="closeSidenav()"
          [routerLink]="['catalog',
                         'browse',
                          category.id,
                          category.name,
                          category.status,
                          subcategory.id,
                          subcategory.name,
                          subcategory.status]"
          [queryParams]="{ categories: subChildCategory.id }">
          <span>{{ subChildCategory.name }}</span>
        </button>
      </div>
    </ng-container>
  </div>

</ng-container>

<div class="controls">
  <button matRipple (click)="onNews()">
    <img src="assets/icons/newspaper_black.svg" alt="" />
    <span>Actualités</span>
  </button>
  <button matRipple (click)="onFAQ()">
    <img src="assets/icons/help_outline_black.svg" alt="" />
    <span>Foire aux questions</span>
  </button>
  <button matRipple (click)="openSupportDialog()">
    <img src="assets/icons/headset.svg" alt="" />
    <span>Service commercial</span>
  </button>
  <button matRipple (click)="onLogin()">
    <img src="assets/icons/person.svg" alt="" />
    <span>{{ username$ | async }}</span>
  </button>
</div>
