import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../config/app.config';
import { QuestionDTO } from '../../DTO/question.dto';
import { FaqDto } from '../../DTO/faq.dto';
import { Page } from '../../DTO/page.dto';

// @ts-ignore
@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  private url: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/user_question`;
  }

  public addQuestion(questionDTO: QuestionDTO): Observable<QuestionDTO> {
    return this.http.post<QuestionDTO>(this.url, questionDTO);
  }

  public getAll(): Observable<Page<FaqDto>> {
    const url = this.url + '/faq';
    const params = new HttpParams().append('size', '50');

    return this.http.get<Page<FaqDto>>(url, { params });
  }
}
