import { AbstractDTO } from './abstract.dto';
import { FormControl, Validators } from '@angular/forms';

export enum QuestionType {
  GENERAL = 'GENERAL',
  ORDER = 'ORDER',
  PAYMENT = 'PAYMENT',
  SHIPPING = 'SHIPPING',
}

export interface FaqDto extends AbstractDTO {
  body: string;
  title: string;
  type?: QuestionType;
}
