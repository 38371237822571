import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../config/app.config';
import { ClientFunctionDTO } from '../../DTO/client-function.dto';

@Injectable({
  providedIn: 'root',
})
export class ClientFunctionService {
  private url: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/client/function`;
  }

  public getAll(): Observable<ClientFunctionDTO[]> {
    return this.http.get<ClientFunctionDTO[]>(this.url);
  }
}
