import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../config/app.config';
import { CountryDTO } from '../../DTO/country.dto';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private readonly url: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/countries`;
  }

  public countries(): Observable<CountryDTO[]> {
    const url = `${this.url}`;
    return this.http.get<CountryDTO[]>(url);
  }
}
