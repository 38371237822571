<div
  matRipple
  [matRippleCentered]="true"
  [matRippleUnbounded]="true"
  style="display: grid;
  width: 7em;
"
>
<a routerLink="/">
  <img
    src="assets/imtech-new-logo.svg"
    alt="logo imtech"
  />
</a>
</div>
