import { Action, createReducer, on } from '@ngrx/store';

import { clearCollaborators, loadCollaboratorsSuccess } from './collaborators.actions';
import { collaboratorsAdapter, CollaboratorsState } from './collaborators.state';

export const initialState: CollaboratorsState = collaboratorsAdapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(loadCollaboratorsSuccess, (state, action) => {
    return collaboratorsAdapter.setAll(action.collaborators, state);
  }),
  on(clearCollaborators, (state) => {
    return collaboratorsAdapter.removeAll(state);
  })
);

export function collaboratorsReducer(
  state: CollaboratorsState,
  action: Action
) {
  return reducer(state, action);
}
