import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {logout} from 'store/auth/auth.actions';
import {CompanyState} from 'store/company/company.state';
import {HomepageService} from 'tekkeys-common';

@Component({
  selector: 'app-logout-drop-down-menu',
  templateUrl: './logout-drop-down-menu.component.html',
  styleUrls: ['./logout-drop-down-menu.component.scss'],
})
export class LogoutDropDownMenuComponent {
  accountNumber: Observable<string> = this.store
    .select('company')
    .pipe(map((state: CompanyState) => state.company?.accountNumber));

  constructor(
    private router: Router,
    private store: Store<{ company: CompanyState }>,
    private homeService: HomepageService
  ) {
  }


  onLogOut() {
    this.store.dispatch(logout());
    this.router.navigate(['/']);
    if (JSON.parse(localStorage.getItem('history'))) {
      this.homeService
        .addCategoryVisitHistory(JSON.parse(localStorage.getItem('history')))
        .subscribe(() => {
            localStorage.removeItem('history');
          },
          () => {
            localStorage.removeItem('history');
          });
    }

    if (JSON.parse(localStorage.getItem('quotation'))) {
      localStorage.removeItem('quotation');
    }
  }
}
