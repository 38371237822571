import { Injectable, Inject } from '@angular/core';
import { PartnerImageDTO } from '../../DTO/partner-image.dto';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfig, APP_CONFIG } from '../../config/app.config';
import { Observable } from 'rxjs';
import {CarouselItemDTO} from "../../DTO/carousel-item.dto";

@Injectable({
  providedIn: 'root',
})
export class LogoService {
  private url: string;
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/logo`;
  }

  public getPartners(): Observable<Object> {
    const params = new HttpParams().append('page', '0').append('size', '100');
    return this.http.get<Object>(this.url, { params });
  }

  public getActivateLogos(): Observable<any[]> {
    const url = `${this.url}/activateLogos`;
    return this.http.get<any[]>(url);
  }
}
