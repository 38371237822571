import { Component, Input } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss'],
})
export class EditDialogComponent {
  @Input() title: string;
  @Input() dialogRef: MatDialogRef<any>;
  close() {
    this.dialogRef.close();
  }
}
