import { InjectionToken } from '@angular/core';

export interface AppConfig {
  name: string;
  apiEndpoint: string;
}

export const APP_CONFIG = new InjectionToken<AppConfig>(
  'application configuration'
);
