import { createAction, props } from '@ngrx/store';

import { FavoriteDTO } from 'tekkeys-common';

export const loadFavorites = createAction('[Favorites] Load Favorites');

export const loadFavoritesSuccess = createAction(
  '[Favorites] Load Favorites Success',
  props<{ favorites: FavoriteDTO[] }>()
);

export const favoriteExists = createAction('[Favorites] favorite Exists');

export const addToFavorites = createAction(
  '[Favorites] Add To Favorites',
  props<{ productId: number }>()
);

export const addToFavoritesSuccess = createAction(
  '[Favorites] Add To Favorites Success',
  props<{ favorite: FavoriteDTO }>()
);

export const removeFavorite = createAction(
  '[Favorites] Remove Item',
  props<{ id: number }>()
);

export const removeFavoriteSuccess = createAction(
  '[Favorites] Remove Item Success',
  props<{ id: number }>()
);

export const clearFavorites = createAction('[Favorites] Clear Favorites');
