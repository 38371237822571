import { createAction, props } from '@ngrx/store';
import { QuotationListDto } from '../../../../projects/tekkeys-common/src/lib/DTO/quotation-list.dto';

// get my list quotation
export const loadQuotation = createAction(
  '[Quotation List Page] Quotation Page Requested ',
  props<{pageSize: number, pageIndex: number }>()
);
export const getMyListQuotation = createAction(
  '[Quotaion List Page] Quotaion List Requested ',
  props<{id: number, page: number, size: number, search: string }>()
);
export const getMyListQuotationSuccess = createAction(
  '[Quotaion API] Quotaion List Loaded Successfully ',
  props<{ quotation: QuotationListDto[], totalElements: number, totalPages: number }>()
);
export const getMyListQuotationFailde = createAction(
  '[Quotaion API] Quotaion List Request Failed ',
  props<{ error: any }>()
);

export const clearQuotation = createAction('[Quotation] Clear Quotation');
