import {Component, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {OrderInvoiceComponent} from '../../../profile/components/order-invoice/order-invoice.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-quotation-success',
  templateUrl: './quotation-success.component.html',
  styleUrls: ['./quotation-success.component.scss']
})
export class QuotationSuccessComponent{

  constructor(private dialogRef: MatDialogRef<QuotationSuccessComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private router: Router,
              private dialog: MatDialog) { }

  closeDialog() {
    this.dialogRef.close();
  }

  redirect() {
    this.router.navigate(['profile/quotations/', this.data?.quotationNum ]);
  }

  openInvoice() {
    this.dialog.open(OrderInvoiceComponent, {
      data : this.data.order.id,
      width: '900px',
    });
  }
}
