<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #sidenav="matSidenav"
    class="sidenav"
    fixedInViewport
    role="dialog"
    mode="over"
    [opened]="false"
  >
    <app-sidenav></app-sidenav>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">
    <app-navbar class="navbar"></app-navbar>
    <router-outlet></router-outlet>
    <mat-divider
      style="border: solid 3em #E5E5E5; margin-top: 100px"
      ngStyle.lt-sm="margin-bottom: 0; border-width: 6em"
    ></mat-divider>
    <app-footer class="footer"></app-footer>
    <app-copyright-notice></app-copyright-notice>
  </mat-sidenav-content>
</mat-sidenav-container>
