import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SnackbarService } from 'shared/services/snackbar/snackbar.service';
import { FavoriteDTO, FavoritesService, ProductDTO } from 'tekkeys-common';

@Injectable({
  providedIn: 'root',
})
export class LocalFavoritesService {
  favoriteItems: FavoriteDTO[] = [];

  favoriteSubject = new BehaviorSubject<FavoriteDTO[]>([]);
  public readonly favoriteItems$ = this.favoriteSubject.asObservable();

  constructor(
    private favoritesService: FavoritesService,
    private snackbarService: SnackbarService
  ) {
    this.favoriteItems = localStorage.getItem('favorites')
      ? JSON.parse(localStorage.getItem('favorites'))
      : [];
    this.favoriteSubject.next(this.favoriteItems);
  }

  getFavorites() {
    this.favoriteItems = localStorage.getItem('favorites')
      ? JSON.parse(localStorage.getItem('favorites'))
      : this.favoriteItems;
    this.favoriteSubject.next(this.favoriteItems);
    return this.favoriteItems;
  }

  addFavorite(product: ProductDTO) {
    if (this.favoriteItems.find((f) => f.product.id === product.id)) {
      this.snackbarService.openSnackBar(
        'Le porduit existe déjà dans vos favoris',
        'information-snackbar'
      );
      return;
    }
    this.favoriteItems.push({product, createdAt: new Date()});
    localStorage.setItem('favorites', JSON.stringify(this.favoriteItems));
    this.snackbarService.openSnackBar(
      'Le produit a été ajouté à vos favoris',
      'success-snackbar'
    );
    this.getFavorites();
  }

  deleteFavorite(productId: number) {
    localStorage.setItem(
      'favorites',
      JSON.stringify(
        this.favoriteItems.filter((f) => f.product.id !== productId)
      )
    );
    this.getFavorites();
  }

  addToFavorites() {
    if (this.getFavorites().length) {
      this.favoritesService
        .addMultiple(this.getFavorites()).pipe().subscribe();
    }
  }
  clearFavorites() {
    localStorage.removeItem('favorites');
    this.favoriteItems = [];
    this.favoriteSubject.next(null);
  }
}
