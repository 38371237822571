import { Component } from '@angular/core';
import {
  selectNotificationsCount,
  selectUnseenNotificationsCount,
} from 'store/notifications/notifications.selectors';
import { Store } from '@ngrx/store';
import { NotificationsState } from 'store/notifications/notifications.state';

@Component({
  selector: 'app-notifications-drop-down-menu',
  templateUrl: './notifications-drop-down-menu.component.html',
  styleUrls: ['./notifications-drop-down-menu.component.scss'],
})
export class NotificationsDropDownMenuComponent  {
  unseenNotificationsCount = this.store.select(selectUnseenNotificationsCount);
  notificationsCount = this.store.select(selectNotificationsCount);

  constructor(private store: Store<{ notifications: NotificationsState }>) {}

}
