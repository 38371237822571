import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType, ROOT_EFFECTS_INIT
} from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, switchMapTo } from 'rxjs/operators';

import { FavoritesService } from 'tekkeys-common';

import { SnackbarService } from 'shared/services/snackbar/snackbar.service';
import { loginSuccess, logout } from 'store/auth/auth.actions';
import {
  addToFavorites,
  addToFavoritesSuccess, clearFavorites, loadFavorites, removeFavorite,
  removeFavoriteSuccess
} from './favorites.actions';

@Injectable()
export class FavoritesEffects {
  init = createEffect(() =>
    this.actions.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMapTo(of(loadFavorites()))
    )
  );

  loginSuccess = createEffect(() =>
    this.actions.pipe(ofType(loginSuccess), switchMapTo(of(loadFavorites())))
  );

  logout = createEffect(() =>
    this.actions.pipe(ofType(logout), switchMapTo(of(clearFavorites())))
  );

  addToFavorites = createEffect(() =>
    this.actions.pipe(
      ofType(addToFavorites),
      switchMap((action) =>
        this.favoritesService.add(action.productId).pipe(
          map((response) => addToFavoritesSuccess({ favorite: response })),
          catchError((e) => {
            if (e.status === 409) {
              this.snackbarService.openSnackBar(
                'Le porduit existe déjà dans vos favoris',
                'information-snackbar'
              );
            }
            return EMPTY;
          })
        )
      )
    )
  );

  addToFavoritesSuccess = createEffect(
    () =>
      this.actions.pipe(
        ofType(addToFavoritesSuccess),
        map(() =>
          this.snackbarService.openSnackBar(
            'Le produit a été ajouté à vos favoris',
            'success-snackbar'
          )
        )
      ),
    { dispatch: false }
  );

  removeFavorite = createEffect(() =>
    this.actions.pipe(
      ofType(removeFavorite),
      switchMap((action) =>
        this.favoritesService.remove(action.id).pipe(
          map((response) => removeFavoriteSuccess({ id: action.id })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private favoritesService: FavoritesService,
    private snackbarService: SnackbarService,
    private actions: Actions
  ) {}
}
