import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../config/app.config';
import { FavoriteDTO } from '../../DTO/favorite.dto';

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  private url: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/favorite`;
  }

/*  public get(): Observable<FavoriteDTO[]> {
    const url = `${this.url}/me`;
    return this.http.get<FavoriteDTO[]>(url);
  }*/

  public add(productId: number): Observable<FavoriteDTO> {
    return this.http.post<FavoriteDTO>(this.url, { productId });
  }

  public addMultiple(favorites: FavoriteDTO[]): Observable<FavoriteDTO[]> {
    const url = `${this.url}/multiple`;
    return this.http.post<FavoriteDTO[]>(url, favorites);
  }

  public remove(id: number): Observable<void> {
    const url = `${this.url}/${id}`;
    return this.http.delete<void>(url);
  }
}
