import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig, APP_CONFIG } from '../../config/app.config';
import { Page } from '../../DTO/page.dto';
import { AccessoriesDto } from '../../DTO/accessories.dto';

@Injectable({
  providedIn: 'root'
})
export class AccessoriesService {
  private url: string;
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}`;
  }

  public getListAccessories(
    page: number,
    size: number,
    categoryId: number,
  ): Observable<Page<AccessoriesDto[]>>{
    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('size', size.toString());
    params = params.append('categoryId', categoryId.toString());
    const url = `${this.url}/product/category_accessories/products`;
    const option = {params};
    return this.http.get<Page<AccessoriesDto[]>>(url, option);
  }
}
