import {Component, OnInit} from '@angular/core';
import {FooterInfoService} from '../../../../../../projects/tekkeys-common/src/lib/DAO/footer/footer-info.service';
import {footerInfoDto} from '../../../../../../projects/tekkeys-common/src/lib/DTO/footer-info.dto';

@Component({
  selector: 'app-footer-info',
  templateUrl: './footer-info.component.html',
  styleUrls: ['./footer-info.component.scss'],
})
export class FooterInfoComponent implements OnInit{

  contactInformation: footerInfoDto [];
  constructor(private serviceFooter: FooterInfoService) {}

  ngOnInit(): void {

   this.serviceFooter.getAllContactInformation().subscribe(
      res => {
        this.contactInformation  = res;

      }
    );

  }
}
