import { Injectable, Inject } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../config/app.config';
import { CompanyDTO } from '../../DTO/company.dto';
import { ClientDTO } from '../../DTO/client.dto';
import { PageableDTO } from '../../DTO/pageable.dto';


@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private url: string;
  private  url1: string;
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/company`;
    this.url1 = `${this.appConfig.apiEndpoint}`;

  }

  public me(): Observable<CompanyDTO> {
    const url = `${this.url}/me`;
    return this.http.get<CompanyDTO>(url);
  }

  public changeRequest(changeRequest: any): Observable<any> {
    const url = `${this.url}/changerequest`;
    return this.http.patch<any>(url, changeRequest);
  }

  public addCompany(companyDTO: CompanyDTO): Observable<CompanyDTO> {
    return this.http.post<CompanyDTO>(this.url, companyDTO);
  }

  addCompanyRequest(companyDTO: CompanyDTO, files: File[]): Observable<any> {
    const formData = new FormData();
    const companyDTOBlob = new Blob([JSON.stringify(companyDTO)], { type: 'application/json' });
    formData.append('companyDTO', companyDTOBlob, 'companyDTO.json');

    if (files && files.length > 0) {
      for (const file of files) {
        formData.append('files', file, file.name);
      }
    }
    const url = `${this.url}/createrequest`;
    // Enlève l'en-tête Content-Type
    return this.http.post<any>(url, formData);
  }


  public getCompanies(query: string): Observable<PageableDTO<CompanyDTO>> {
    let params = new HttpParams();
    params = params.append('search', query);
    return this.http.get<PageableDTO<CompanyDTO>>(this.url, { params });
  }

  public getCompaniesGuichet(query: string): Observable<PageableDTO<CompanyDTO>> {
    let params = new HttpParams();
    const url = `${this.url}/guichet`;
    params = params.append('search', query);
    return this.http.get<PageableDTO<CompanyDTO>>(url, { params });
  }
  public getLegalSituation(){
    const url = `${this.url1}/legal-situations/all`;
    return this.http.get<any>(url);
  }
  public downloadFormulaire(lang: string) {
    const url = `${this.url1}/files/Ouverture-comptes-clients?lang=${lang}`;
    console.log(lang,'lang service');
    const headers = new HttpHeaders({
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Type MIME pour les fichiers Excel
    });
    const options = {
      headers,
      responseType: 'blob' as 'json',
    };
    return this.http.get(url, options);
  }

  public getPrincipalContact(companyId: any): Observable<ClientDTO> {
    const url = `${this.url}/maincontact/${companyId}`;
    return this.http.get<ClientDTO>(url);
  }

  public getAccountManager(companyId: any): Observable<ClientDTO> {
    const url = `${this.url}/accountmanager/${companyId}`;
    return this.http.get<ClientDTO>(url);
  }

  public joinRequest(companyId: number, token?: string): Observable<ClientDTO> {
    const url = `${this.url}/join/${companyId}${token ? '?token=' + token : ''}`;
    return this.http.get<ClientDTO>(url);
  }
  public getById(id: number): Observable<CompanyDTO> {
    const url = `${this.url}/${id}`;
    return this.http.get<CompanyDTO>(url);
  }
}
