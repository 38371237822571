import { Action, createReducer, on } from '@ngrx/store';

import {
  addToAddressesSuccess,
  loadAddressesSuccess,
  removeAddressSuccess,
  updateAddressesSuccess,
} from './addresses.actions';
import { addressAdapter, AddressesState } from './addresses.state';

export const initialState: AddressesState = addressAdapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(loadAddressesSuccess, (state, action) => {
    return addressAdapter.setAll(action.addresses, state);
  }),
  on(addToAddressesSuccess, (state, action) => {
    return addressAdapter.upsertOne(action.addresses, state);
  }),
  on(removeAddressSuccess, (state, action) => {
    return addressAdapter.removeOne(action.id, state);
  }),
  on(updateAddressesSuccess, (state, action) => {
    if (action.addresses.isDefault) {
      Object.values(state.entities).forEach( v => {
        if (v.isDefault && v.id !== action.addresses.id) {
          state = addressAdapter.updateOne({id: v.id, changes: {...v, isDefault: false}}, state);
        }
      });
    }
    return addressAdapter.updateOne(action.partialAddress, state);
  })
);

export function addressesReducer(state: AddressesState, action: Action) {
  return reducer(state, action);
}
