

<h1 class="title">LIENS UTILES</h1>
<div *ngFor="let item of contactInformation">

  <div class="contacts" fxLayout="column" fxLayoutGap="16px">
      <div>
        <img src="../../../../../assets/icons/call.svg" alt="phone" />
        <a href="tel:+33561400173">{{item.phoneOne}}</a>
      </div>
      <div>
        <img src="../../../../../assets/icons/email.svg" alt="email" />
        <a href="mailto:commercial@imtech-solutions.com ">{{item.mail}} </a>
      </div>
      <div>
        <img src="../../../../../assets/icons/location.svg" alt="address" />
        <a *ngIf="localisation" [href]="localisation" target="_blank">
          {{item.addressOne}}
        </a>
      </div>
    </div>
  </div>
  <ul class="links">
      <li routerLink="/blog/list" fxHide.gt-xs><a><img class="imgRed" src="assets/icons/iconRed.png" alt="Icone red"> Actualités</a></li>
      <li routerLink="about/legal">
        <img class="imgRed" src="assets/icons/iconRed.png" alt="Icone red">
        <a> Mentions légales</a></li>
      <li routerLink="about/general-condition"><a><img class="imgRed" src="assets/icons/iconRed.png" alt="Icone red">  Conditions générales</a></li>
      <li routerLink="about/cookies"><a><img class="imgRed" src="assets/icons/iconRed.png" alt="Icone red">  Les Cookies sur Imtech</a></li>
      <li routerLink="about/security"><a><img class="imgRed" src="assets/icons/iconRed.png" alt="Icone red">  Sécurité et confidentialité</a></li>
  </ul>
