<h1 class="title">NEWSLETTER</h1>
<p class="message">Abonnez-vous pour recevoir les dernières mises à jours</p>
<app-email-input [formControl]="mailControl"></app-email-input>
<button
  class="app-raised-button btn-submit"
  ngStyle.lt-md="margin: 0 auto;"
  (click)="onSubscribeToNewsletter()"
  [disabled]="mailControl.invalid || mailControl.value === '' || loading"
>
  ENVOYER
</button>
