<label
  class="label"
  [ngClass]="{
    'label-unchecked': !input.value,
    'label-checked': input.value
  }"
>
  {{ label }}
  <input
    #input="ngModel"
    [(ngModel)]="value"
    (blur)="onTouch()"
    class="checkbox-input"
    type="checkbox"
  />
</label>
