import { createReducer, on, Action } from '@ngrx/store';

import { CompanyState } from './company.state';
import {
  loadCompany,
  loadCompanySuccess,
  loadCompanyFailure,
  unloadCompany,
} from './company.actions';

export const initialState: CompanyState = {
  company: null,
  load: false,
  isLoading: false,
  error: false,
  errorMessage: null,
};

const reducer = createReducer(
  initialState,
  on(loadCompany, (state: CompanyState) => {
    return {
      company: state.company,
      load: false,
      isLoading: true,
      error: false,
      errorMessage: null,
    };
  }),
  on(loadCompanySuccess, (state: CompanyState, action) => {
    return {
      company: action.company,
      load: true,
      isLoading: false,
      error: false,
      errorMessage: null,
    };
  }),
  on(loadCompanyFailure, (state: CompanyState, action) => {
    let errorMessage: string;
    if (action.errorCode === 404) {
      errorMessage = 'Cette entreprise n\'éxiste pas';
    } else {
      errorMessage = 'Une erreur est survenue';
    }
    return {
      company: state.company,
      load: false,
      isLoading: false,
      error: true,
      errorMessage,
    };
  }),
  on(unloadCompany, () => {
    return {
      company: null,
      load: false,
      isLoading: false,
      error: false,
      errorMessage: null,
    };
  })
);

export function companyReducer(state: CompanyState, action: Action) {
  return reducer(state, action);
}
