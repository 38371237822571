import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../config/app.config';
import {OutstandingDto} from '../../DTO/outstanding.dto';

@Injectable({
  providedIn: 'root',
})
export class OutstandingService {
  private url: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/outstanding`;
  }
  getOutstandingByCompany(companyId: number) {
    const url = `${this.url}/company/${companyId}/find`;
    return this.http.get<OutstandingDto>(url);
  }
}
