import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyState } from 'store/company/company.state';

const getCompanyState = createFeatureSelector<CompanyState>('company');

export const selectCompanyPageLoading = createSelector(
  getCompanyState,
  (companyState) => companyState.company
);

export const selectCompanyId = createSelector(
  getCompanyState,
  (companyState) => {
    if (companyState?.company?.id !== null) {
      return companyState.company?.id;
    } else {
      return null; // or a default value that makes sense for your application
    }
  }
);

