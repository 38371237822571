import {Component, OnInit} from '@angular/core';
import {footerInfoDto} from '../../../../../../projects/tekkeys-common/src/lib/DTO/footer-info.dto';
import {FooterInfoService} from '../../../../../../projects/tekkeys-common/src/lib/DAO/footer/footer-info.service';

@Component({
  selector: 'app-footer-links',
  templateUrl: './footer-links.component.html',
  styleUrls: ['./footer-links.component.scss'],
})
export class FooterLinksComponent implements OnInit {
  contactInformation: footerInfoDto [];
  localisation: string;
  constructor(private serviceFooter: FooterInfoService) {}
  ngOnInit() {
    this.serviceFooter.getAllContactInformation().subscribe(
      res => {
        this.contactInformation = res;
        this.localisation = res[0].localisationOne;

      })
  }
}
