import { createAction, props } from '@ngrx/store';
import { ClientDTO, ClientUpdateRequestDTO } from 'tekkeys-common';

export const loadAccount = createAction('[Account] Load Account');

export const loadAccountSuccess = createAction(
  '[Account] Load Account Success',
  props<{ account: ClientDTO }>()
);

export const loadAccountFailure = createAction(
  '[Account] Load Account Failure',
  props<{ errorCode: number }>()
);

export const updateAccount = createAction(
  '[Account] Update Account',
  props<{ accountUpdateRequest: ClientUpdateRequestDTO }>()
);

export const updateAccountSuccess = createAction(
  '[Account] Update Account Success',
  props<{ account: ClientDTO }>()
);

export const updateAccountFailure = createAction(
  '[Account] Update Account Failure',
  props<{ errorCode: number }>()
);

export const unloadAccount = createAction('[Account] Unload Account');
