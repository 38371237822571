<div class="dialog">
    <div class="dialog-titlebar" [style.background-color]="toggle ? 'var(--green-700)' : 'var(--red-700)'">
        <span><img *ngIf="header==='Article ajouté avec succès'"
                src='assets/icons/check_circle_white.svg'  alt="Icone check-circle-white" />{{this.header}}</span>
        <button mat-icon-button (click)="close()">
            <img src="assets/icons/close.svg" alt="close" />
        </button>
    </div>

    <!-- row -->
    <div fxLayout="row wrap" fxLayoutGap="0px">

        <div class="carousel">
            <app-window-product-carousel [product]="product"></app-window-product-carousel>
        </div>
        <!-- the rest -->

        <div fxLayout="column" fxLayoutGap="5px" class="everythingElse">
            <div style="width: 100%;">
                <h1 class="designation" title="{{ data.product.designation }}">
                    {{ product.designation }}
                </h1>
            </div>
            <div fxLayout="row wrap" fxLayoutGap="24px" fxLayoutAlign="start center" style="width: 100%;">
                <div class="info">EAN : {{ product.eancode }}</div>
                <div class="info">FAB : {{product.manufacturerReference}}</div>
                <div class="info" *ngIf="data.product.code">Imtech : {{ data.product.code}}</div>
            </div>
            <div class="description" style="width: 90%;height: 30%;">
                <p>{{ product?.shortDescription }}</p>
                <a class="link" [routerLink]="['/catalog/product/', product.id, product.name]" (click)="close()">
                    Voir toutes les caractéristiques
                </a>
            </div>


            <!-- This is a spacer -->
            <div fxFlex="1 0 auto" style="width: 100%;">
                <!-------------------->
            </div>
            <div fxLayout="row" fxLayoutGap="14px" fxLayoutAlign="start" class="StatusAndPrice">

                <div style="height: 100%;" class="price-container" ngClass.lt-sm="lt-md">
                    <div class="price">
                        <span class="new-price">
                            {{ product?.price - product.discount | currency: "EUR":true }}
                        </span>
                        <span class="old-price" *ngIf="product.discount && (product?.discountEndDate | daysLeft) > 0">
                            {{ product.price | currency: "EUR":true }}
                        </span>
                    </div>
                    <span class="price-info"
                        *ngIf="product?.discount &&product?.discountEndDate &&(product?.discountEndDate | daysLeft) > 0">
                        Expire dans {{ product?.discountEndDate | daysLeft }} jrs</span>
                </div>

                <div class="status-container" style="width: 100px;height: 100%;">
                    <div class="status"
                        [ngClass]="{'in-stock__status': product?.status === 'IN_STOCK','on-command__status': product?.status === 'ON_COMMAND',obsolete__status: product?.status === 'OBSOLETE','out-of-stock__status': product?.status === 'OUT_OF_STOCK'}">
                        {{ product?.status === "IN_STOCK" ? "En stock" : "" }}
                        {{ product?.status === "OBSOLETE" ? "Produit obsolète" : "" }}
                        {{ product?.status === "OUT_OF_STOCK" ? "Rupture de stock" : "" }}
                        {{ product?.status === "ON_COMMAND" ? "Sur commande" : "" }}
                    </div>
                    <span class="status-info"
                        *ngIf="product?.status === 'OUT_OF_STOCK' &&(product?.replenishmentDate | daysLeft) > 0">
                        Réappro dans {{ product?.replenishmentDate | daysLeft }} jrs
                    </span>
                </div>

                <div *ngIf="product.status === 'OBSOLETE' && product.substitutionProduct?.id"
                    class="substitution-link-container" style="width: 100px;height: 100%;">
                    <img src="assets/icons/info.svg" alt="Icone info" />
                    <div class="substitution-link"
                        [routerLink]="['/catalog/product/',product.substitutionProduct?.id,product.substitutionProduct?.name]">
                        <span>Produit de remplacement</span>
                    </div>
                </div>



            </div>


            <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="5px" style="width: 100%;">

                <button mat-button class="add-wish-btn" [disabled]="product?.status === 'OBSOLETE'"
                    (click)="addToFavorites()">
                    <img [src]="'assets/icons/favorite' + (product?.status === 'OBSOLETE' ? '-muted' : '') + '.svg'"
                        alt="heart" />
                    <span>&nbsp;AJOUTER AU FAVORIS</span>
                </button>

                <button *ngIf="product?.status !== 'OBSOLETE' && product?.status !== 'OUT_OF_STOCK'" mat-button
                    class="app-raised-button add-cart-btn" [class.spinner]="addToCartLoading"
                    [disabled]="addToCartLoading || product?.status === 'OBSOLETE'" (click)="addToCart()">
                    <img src="assets/icons/shopping_cart-white.svg" alt="shopping cart" />
                    <span>&nbsp;AJOUTER AU PANIER</span>
                </button>

                <!-- Réaliser devi btn -->
                <button *ngIf="product?.status !== 'IN_STOCK'" (click)="addQuote()" mat-button
                    class="app-raised-button add-cart-btn" [disabled]="product?.status === 'OBSOLETE'">
                    <img [src]="'assets/icons/shopping_cart' +(product?.status === 'OBSOLETE' ? '-muted' : '-white') +'.svg'"
                        alt="shopping cart" /><span>&nbsp;RÉALISER UN DEVIS</span>
                </button>

            </div>

        </div>

    </div>


</div>
