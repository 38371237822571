import {
  AnimationTriggerMetadata,
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

const EXPANSION_ANIMATION_TIMING = '225ms cubic-bezier(0.4,0.0,0.2,1)';

export const expansionAnimation: AnimationTriggerMetadata = trigger(
  'expansion',
  [
    state('collapsed', style({ height: '0px', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition('expanded <=> collapsed', animate(EXPANSION_ANIMATION_TIMING)),
  ]
);
