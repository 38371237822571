<label
  [ngClass]="{
    'label-center': !input.value,
    'label-top': input.value
  }"
>
  {{ label }}
</label>
<span matAutocompleteOrigin #origin="matAutocompleteOrigin">
  <input
    #input="ngModel"
    #inputElement
    [(ngModel)]="value"
    (blur)="onTouch()"
    class="text-input"
    type="text"
    [matAutocompleteDisabled]="!matAutocomplete"
    [matAutocomplete]="matAutocomplete"
    [matAutocompleteConnectedTo]="origin"
    [ngStyle]="postIcon && { 'padding-right': '35px' }"
    [readonly]="readOnly"
  />

  <img *ngIf="postIcon" (click)="toggleSelect(inputElement)" [src]="postIcon" alt="" />
</span>
