<h1 class="title">PAIEMENT</h1>
<div class="payment-options">
  <img src="../../../../../assets/icons/payment-paypal.png" alt="paypal" />
  <img src="../../../../../assets/icons/payment-visa.png" alt="visa" />
  <img
    src="../../../../../assets/icons/payment-mastercard.png"
    alt="mastercard"
  />
  <img
    src="../../../../../assets/icons/payment-american_express.png"
    alt="american express"
  />
  <img
    src="../../../../../assets/icons/payment-carte_bleue.png"
    alt="carte bleue"
  />
  <img
    src="../../../../../assets/icons/payment-virement_bancaire.png"
    alt="virement bancaire"
  />
</div>
