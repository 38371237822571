import { Action, createReducer, on } from '@ngrx/store';
import { RemainingTimePipe } from 'shared/pipes/remaining-time/remaining-time.pipe';

import { fadeOut, login, loginFailure, loginSuccess, logout, signIn, signInSuccess } from './auth.actions';
import { AuthState } from './auth.state';

export const initialState: AuthState = {
  token: localStorage.getItem('authToken'),
  isTerminated: false,
  isLoading: false,
  error: false,
  errorMessage: null,
};

const reducer = createReducer(
  initialState,
  on(login, (state: AuthState) => {
    return {
      token: state.token,
      isTerminated: false,
      isLoading: true,
      error: false,
      errorMessage: null,
    };
  }),
  on(loginSuccess, (state: AuthState, action) => {
    return {
      token: action.AuthTokenDTO.token,
      isTerminated: false,
      isLoading: false,
      error: false,
      errorMessage: null,
    };
  }),
  on(loginFailure, (state: AuthState, action) => {
    let errorMessage: string;
    switch (action.errorCode) {
      case 401:
        errorMessage =
          'Mot de passe incorrect, ' +
          action.remainingAttempts +
          (action.remainingAttempts > 1
            ? ' tentatives restantes'
            : ' tentative restante');
        break;
      case 403:
        errorMessage =
          'Ce compte est bloqué, essayez dans: ' +
          new RemainingTimePipe().transform(action.remainingTime);
        break;
      case 404:
        errorMessage = 'Aucun compte ne correspond à cette adresse e-mail.';
        break;
      case 406:
        errorMessage = 'Ce compte n\'est pas encore activé';
        break;
      default:
        errorMessage = 'Une erreur est survenue lors de la connexion';
        break;
    }
    return {
      token: null,
      isTerminated: false,
      isLoading: false,
      error: true,
      errorMessage,
    };
  }),
  on(signIn, (state: AuthState) => {
    return {
      token: state.token,
      isTerminated: false,
      isLoading: true,
      error: false,
      errorMessage: null,
    };
  }),
  on(signInSuccess, (state: AuthState, action) => {
    return {
      token: action.AuthTokenDTO.token,
      isTerminated: true,
      isLoading: false,
      error: false,
      errorMessage: null,
    };
  }),
  on(fadeOut, (state: AuthState, action) => {
    return {
      token: state.token,
      isTerminated: true,
      isLoading: state.isLoading,
      error: false,
      errorMessage: null,
    };
  }),
  on(logout, () => {
    return {
      token: null,
      isTerminated: false,
      isLoading: false,
      error: false,
      errorMessage: null,
    };
  })
);

export function authReducer(state: AuthState, action: Action) {
  return reducer(state, action);
}
