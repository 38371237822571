import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CartItemDTO, CartAndFavoriteItemCountDTO } from 'tekkeys-common';

export interface CartState extends EntityState<CartItemDTO> {
  isLoading: boolean;
  error: Error;
  errorMessages: string;
  lastCartItemId: number;
  itemsCount: CartAndFavoriteItemCountDTO;
}

export const cartAdapter: EntityAdapter<CartItemDTO> = createEntityAdapter<CartItemDTO>();
