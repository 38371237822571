<div style="width: 220px">

  <button class="menu-button" mat-menu-item routerLink="/profile">
    <div style="
    display: flex;
    flex-direction: column;
    gap: 10px;
">
      <span>Mon Compte</span>
      <span
        *ngIf="accountNumber | async"
        style="line-height: 1em; padding-bottom: 12px"
      ><b>{{ accountNumber | async }}</b></span
      >
    </div>
  </button>

  <mat-divider></mat-divider>

  <button class="menu-button" mat-menu-item routerLink="/profile/personal-information">
    <img src="assets/icons/person_circle.svg" alt="account information"
         style="width: 24px; height: 24px; margin-right: 8px; vertical-align: middle;"/>
    <span style="vertical-align: middle;">Profil</span>
  </button>

  <button class="menu-button" mat-menu-item routerLink="/profile/company">
    <img src="assets/icons/business.svg" alt="company"
         style="width: 24px; height: 24px; margin-right: 8px; vertical-align: middle;"/>
    <span style="vertical-align: middle;">Entreprise</span>
  </button>

  <button class="menu-button" mat-menu-item routerLink="/profile/outstanding">
    <img src="assets/icons/euro.svg" alt="outstanding"
         style="width: 24px; height: 24px; margin-right: 8px; vertical-align: middle;"/>
    <span style="vertical-align: middle;">Encours</span>
  </button>

  <button class="menu-button" mat-menu-item routerLink="/profile/address-book">
    <img src="assets/icons/pin_drop.svg" alt="addresses"
         style="width: 24px; height: 24px; margin-right: 8px; vertical-align: middle;"/>
    <span style="vertical-align: middle;">Adresses</span>
  </button>

  <button class="menu-button" mat-menu-item routerLink="/profile/quotations">
    <img src="assets/icons/file_copy.svg" alt="order"
         style="width: 24px; height: 24px; margin-right: 8px; vertical-align: middle;"/>
    <span style="vertical-align: middle;">Devis</span>
  </button>

  <button class="menu-button" mat-menu-item routerLink="/profile/orders">
    <img src="assets/icons/shopping_cart-red.svg" alt="order"
         style="width: 24px; height: 24px; margin-right: 8px; vertical-align: middle;"/>
    <span style="vertical-align: middle;">Commandes</span>
  </button>

  <button class="menu-button" mat-menu-item routerLink="/profile/invoices">
    <img src="assets/icons/assignment.svg" alt="invoices"
         style="width: 24px; height: 24px; margin-right: 8px; vertical-align: middle;"/>
    <span style="vertical-align: middle;">Factures</span>
  </button>

  <mat-divider></mat-divider>

  <button class="menu-button" mat-menu-item (click)="onLogOut()">
    <span style="vertical-align: middle;">Se déconnecter</span>
  </button>
</div>
