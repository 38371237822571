import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../config/app.config';
import { CategoryDTO } from '../../DTO/category.dto';
import { ProductDTO } from '../../DTO/product.dto';
import {shareReplay} from 'rxjs/operators';
import {ProductCardDTO} from '../../DTO/product-card.dto';

@Injectable({
  providedIn: 'root',
})
export class HomepageService {
  private url: string;
  public currentUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/homepage`;
  }
  setCurrentUrl(url: string) {
    this.currentUrl = url;
  }

  getCurrentUrl(): string {
    return this.currentUrl;
  }
  public getBestCategories(clientId: number): Observable<CategoryDTO[]> {
    const url = `${this.url}/best_category${
      clientId ? '?clientId=' + clientId : ''
    }`;
    return this.http.get<CategoryDTO[]>(url).pipe(shareReplay());
  }

  public getBestSellers(): Observable<ProductCardDTO[]> {
    const url = `${this.url}/best_sales`;
    return this.http.get<ProductCardDTO[]>(url).pipe(shareReplay());
  }

  public getBestPromotions(): Observable<ProductCardDTO[]> {
    const url = `${this.url}/best_promotions`;
    return this.http.get<ProductCardDTO[]>(url).pipe(shareReplay());
  }

  public addCategoryVisitHistory(payload: any): Observable<any> {
    const url = `${this.url}/best_category`;
    return this.http.post<any>(url, payload).pipe(shareReplay());
  }

  updatelocalStorage(data) {
    let savedData = [];
    savedData = JSON.parse(localStorage.getItem('history')) || [];
    savedData.push(data);
    localStorage.setItem('history', JSON.stringify(savedData));
  }
}
