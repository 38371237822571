import { Action, createReducer, on } from '@ngrx/store';

import {
  loadAccount,
  loadAccountFailure,
  loadAccountSuccess,
  unloadAccount,
  updateAccount,
  updateAccountFailure,
  updateAccountSuccess,
} from './account.actions';
import { AccountState } from './account.state';

export const initialState: AccountState = {
  account: null,
  isConnected: false,
  isLoading: false,
  error: false,
  errorMessage: null,
};

const reducer = createReducer(
  initialState,
  on(loadAccount, (state: AccountState) => {
    return {
      account: state.account,
      isConnected: true,
      isLoading: true,
      error: false,
      errorMessage: null,
    };
  }),
  on(loadAccountSuccess, (state: AccountState, action) => {
    return {
      account: action.account,
      isConnected: true,
      isLoading: false,
      error: false,
      errorMessage: null,
    };
  }),
  on(loadAccountFailure, (state: AccountState, action) => {
    let errorMessage: string;
    localStorage.removeItem('authToken');
    if (action.errorCode === 404) {
      errorMessage = 'Ce compte n\'éxiste pas';
    } else {
      errorMessage = 'Une erreur est survenue';
    }
    return {
      account: state.account,
      isConnected: false,
      isLoading: false,
      error: true,
      errorMessage,
    };
  }),
  on(updateAccount, (state: AccountState) => {
    return {
      account: state.account,
      isConnected: false,
      isLoading: true,
      error: false,
      errorMessage: null,
    };
  }),
  on(updateAccountSuccess, (state: AccountState, action) => {
    return {
      account: action.account,
      isConnected: true,
      isLoading: false,
      error: false,
      errorMessage: null,
    };
  }),
  on(updateAccountFailure, (state: AccountState, action) => {
    let errorMessage: string;
    if (action.errorCode === 404) {
      errorMessage = 'Ce compte n\'éxiste pas';
    } else {
      errorMessage = 'Une erreur est survenue';
    }
    return {
      account: state.account,
      isConnected: false,
      isLoading: false,
      error: true,
      errorMessage,
    };
  }),
  on(unloadAccount, () => {
    return {
      account: null,
      isConnected: false,
      isLoading: false,
      error: false,
      errorMessage: null,
    };
  })
);

export function accountReducer(state: AccountState, action: Action) {
  return reducer(state, action);
}
