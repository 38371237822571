import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../config/app.config';
import { ManufacturerDTO } from '../../DTO/manufacturer.dto';

@Injectable({
  providedIn: 'root',
})
export class ManufacturerService {
  private url: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/manufacturer`;
  }

  public getAll(): Observable<ManufacturerDTO[]> {
    return this.http.get<ManufacturerDTO[]>(this.url);
  }

  public getById(id: number): Observable<ManufacturerDTO> {
    const url = `${this.url}/${id}`;
    return this.http.get<ManufacturerDTO>(url);
  }

  public getByFilter(
    categoryIds: number[],
    minPrice: number,
    maxPrice: number
  ): Observable<ManufacturerDTO[]> {
    const url = `${this.url}/category/`; // TODO remove trailing slash

    let params = new HttpParams().append('categories', categoryIds.toString());
    params = minPrice > 0 ? params.append('min', minPrice.toString()) : params;
    params = maxPrice > 0 ? params.append('max', maxPrice.toString()) : params;

    const options = { params };

    return this.http.get<ManufacturerDTO[]>(url, options);
  }
}
