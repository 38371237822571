import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../config/app.config';
import { NotificationDTO } from '../../DTO/notification.dto';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private url: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/notification`;
  }

  public get(): Observable<NotificationDTO[]> {
    return this.http.get<NotificationDTO[]>(`${this.url}/me`);
  }

  public updateSeen(
    notificationId: number,
    seen: boolean
  ): Observable<NotificationDTO> {
    return this.http.patch<NotificationDTO>(
      `${this.url}/${notificationId}/seen`,
      JSON.stringify(seen)
    );
  }
}
