import { createReducer, on, Action } from '@ngrx/store';

import { FavoritesState, favoritesAdapter } from './favorites.state';
import {
  loadFavoritesSuccess,
  addToFavoritesSuccess,
  removeFavoriteSuccess,
  clearFavorites,
} from './favorites.actions';

export const initialState: FavoritesState = favoritesAdapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(loadFavoritesSuccess, (state, action) => {
    return favoritesAdapter.setAll(action.favorites, state);
  }),
  on(addToFavoritesSuccess, (state, action) => {
    return favoritesAdapter.upsertOne(action.favorite, state);
  }),
  on(removeFavoriteSuccess, (state, action) => {
    return favoritesAdapter.removeOne(action.id, state);
  }),
  on(clearFavorites, (state) => {
    return favoritesAdapter.removeAll(state);
  })
);

export function favoritesReducer(state: FavoritesState, action: Action) {
  return reducer(state, action);
}
