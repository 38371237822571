import { createAction, props } from '@ngrx/store';

import { CompanyDTO } from 'tekkeys-common';

export const loadCompany = createAction('[Company] Load Company');

export const loadCompanySuccess = createAction(
  '[Company] Load Company Success',
  props<{ company: CompanyDTO }>()
);

export const loadCompanyFailure = createAction(
  '[Company] Load Company Failure',
  props<{ errorCode: number }>()
);

export const unloadCompany = createAction('[Company] Unload Company');
