import { createAction, props } from '@ngrx/store';
import { NotificationDTO } from 'tekkeys-common';
import { Update } from '@ngrx/entity';

export const loadNotifications = createAction(
  '[Notification] Load Notifications'
);

export const loadNotificationsSuccess = createAction(
  '[Notification] Load Notifications Success',
  props<{ notifications: NotificationDTO[] }>()
);

export const pushNotification = createAction(
  '[Notification] Push Notification',
  props<{ notification: NotificationDTO }>()
);

export const seenNotification = createAction(
  '[Notification] Seen Notification',
  props<{ notificationId: number; seen: boolean }>()
);

export const seenNotificationSuccess = createAction(
  '[Notification] Seen Notification Success',
  props<{ updatedNotification: Update<NotificationDTO> }>()
);
