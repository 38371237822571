import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { QuotationListDto } from '../../../../projects/tekkeys-common/src/lib/DTO/quotation-list.dto';

export interface QuotationListState extends EntityState<QuotationListDto> {
  listLoading: boolean;
  totalElements: number;
  totalPages: number;
}

export const quotationListAdapter: EntityAdapter<QuotationListDto> = createEntityAdapter<QuotationListDto>();
