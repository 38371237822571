import {
  notificationsAdapter,
  NotificationsState,
} from './notifications.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const adapterSelectors = notificationsAdapter.getSelectors();

const getNotificationsState = createFeatureSelector<NotificationsState>(
  'notifications'
);

export const selectNotifications = createSelector(
  getNotificationsState,
  adapterSelectors.selectAll
);

export const selectNotificationsCount = createSelector(
  selectNotifications,
  (notifications) => notifications.length
);

export const selectUnseenNotificationsCount = createSelector(
  selectNotifications,
  (notifications) =>
    notifications.filter((notification) => notification.seen === false).length
);
