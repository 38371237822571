import {Component, EventEmitter, Inject, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {CartItemDTO} from 'projects/tekkeys-common/src/lib/DTO/cart-item.dto';
import {Observable, Subscription} from 'rxjs';
import {NewQuotationDialogComponent} from 'shared/components/new-quotation-dialog/new-quotation-dialog.component';
import {LocalCartService} from 'shared/services/localCart.service';
import {LocalFavoritesService} from 'shared/services/localFavorites.service';
import {LoginOverlayService} from 'shared/services/overlay/login-overlay.service';
import {AddToCartDialogComponent} from 'src/app/catalog/components/add-to-cart-dialog/add-to-cart-dialog.component';
import {selectIsConnected} from 'store/account/account.selectors';
import {addToCart} from 'store/cart/cart.actions';
import {selectAddCartStatus, selectCart} from 'store/cart/cart.selectors';
import {addToFavorites} from 'store/favorites/favorites.actions';
import {ProductDTO} from 'tekkeys-common';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

export interface DATAPRODUCT {
  cartQuantity: number;
  product: ProductDTO;
  quantity: number;
  cartItemId: number;
  overQuantity: number;
  devisQuantity: number;
  localCartQuantity: number;

}

@Component({
  selector: 'app-custom-product-window',
  templateUrl: './custom-product-window.component.html',
  styleUrls: ['./custom-product-window.component.scss']
})
export class CustomProductWindowComponent implements OnInit, OnChanges, OnDestroy {

  @Output() goToProductSpecifications = new EventEmitter<boolean>();
  product: ProductDTO;
  isConnected: boolean;
  max: number;
  quantity: UntypedFormControl = new UntypedFormControl(1);
  addToCartLoading = false;
  quantityInCart = 0;
  quantityLeft = 0;
  overQuantity: boolean;
  devisQuanitity = 0;
  localCartQuantity = 0;
  subscriptions: Subscription[] = [];
  cartItems: Observable<CartItemDTO[]> = this.store.select(selectCart);
  header = 'Accessoire PRODUIT';
  toggle = false;

  constructor(
    private dialogRef: MatDialogRef<CustomProductWindowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DATAPRODUCT,
    private store: Store,
    private localFavoritesService: LocalFavoritesService,
    private route: ActivatedRoute,
    private localCartService: LocalCartService,
    private dialog: MatDialog,
    private loginOverlayService: LoginOverlayService,
  ) {
  }


  ngOnInit(): void {
    this.product = this.data.product;
    this.route.data.subscribe((data) => {
      this.initializeIsConnected();
      this.setMaxQuantity(data.product);
      this.updateQuantityInCartFromStore();
    });
    if (this.product) {
      this.updateQuantityInCartFromLocal();
    }
  }

  initializeIsConnected(): void {
    this.store
      .select(selectIsConnected)
      .subscribe((isConnected) => (this.isConnected = isConnected));
  }

  setMaxQuantity(product: any): void {
    this.max = product.quantity;
    if (product.status !== 'IN_STOCK') {
      this.max = Infinity;
    }
  }

  updateQuantityInCartFromStore(): void {
    this.cartItems.forEach((c) => {
      c.forEach((cart) => {
        if (cart.product.id === this.product.id) {
          this.quantityInCart = cart?.quantity;
        } else {
          this.quantityInCart = 0;
        }
      });
    });
  }

  updateQuantityInCartFromLocal(): void {
    this.localCartService.cartItems$.subscribe((c) => {
      if (c) {
        c.forEach((cartItem) => {
          if (cartItem.product.id === this.product.id) {
            this.quantityInCart = cartItem?.quantity;
          } else {
            this.quantityInCart = 0;
          }
        });
      }
    });
  }

  addToFavorites() {
    if (this.isConnected) {
      this.store.dispatch(
        addToFavorites({
          productId: this.product.id,
        })
      );
    } else {
      this.localFavoritesService.addFavorite(this.product);
    }
  }

  addToCart(): void {
    const total = this.quantity.value + this.quantityInCart;
    this.handleQuantityValidation(total);

    if (this.isConnected) {
      this.handleConnectedUser();
    } else if (this.product) {
      this.handleLocalCart();
    }
  }

  handleQuantityValidation(total: number): void {
    if (total > this.product.quantity) {
      this.overQuantity = true;
      if (this.quantity.value >= this.product?.quantity) {
        this.devisQuanitity = total - this.product?.quantity;
        this.quantity.setValue(this.product.quantity - this.quantityInCart);
      } else if (this.quantity.value < this.product.quantity) {
        this.quantityLeft = this.product.quantity - this.quantityInCart;
        this.devisQuanitity = total - this.product.quantity;
        this.quantity.setValue(this.quantityLeft);
      }
    } else {
      this.overQuantity = false;
    }
  }

  handleConnectedUser(): void {
    this.store.dispatch(
      addToCart({ productId: this.product.id, quantity: this.quantity.value })
    );

    const selectAddCartStateSub = this.store
      .select(selectAddCartStatus)
      .subscribe((state) => {
        this.handleCartState(state, selectAddCartStateSub);
      });

    this.subscriptions.push(selectAddCartStateSub);
  }

  handleCartState(state: any, subscription: any): void {
    this.addToCartLoading = state.isLoading;
    if (!state.isLoading) {
      this.header = 'Article ajouté avec succès';
      this.toggle = true;
      setTimeout(() => subscription.unsubscribe(), 500);
    }
  }

  handleLocalCart(): void {
    const cartItem = this.localCartService.getCartItem(this.product.id);
    this.localCartService.addCartItem(this.product, this.quantity.value);
    if (!cartItem || this.quantity.value <= cartItem.product.quantity) {
      this.toggle = true;
      this.header = 'Article ajouté avec succès';
    } else {
      this.localCartQuantity = 0;
    }
  }




  close() {
    this.dialogRef.close();
  }

  showDialogBox(cartItemId: number) {
    const data = {
      product: this.product,
      quantity: this.quantity.value,
      cartQuantity: this.quantityInCart,
      overQuantity: this.overQuantity,
      devisQuantity: this.devisQuanitity,
      localCartQuantity: this.quantityInCart,
      cartItemId,
    };
    this.dialog.open(AddToCartDialogComponent, {
      data,
      maxWidth: '100%',
    });
  }

  addQuote() {
    if (this.isConnected) {
      const products = [];
      products.push({
        product: this.product,
        quantity: this.quantity.value,
        price: this.product.price,
      });
      const data_ = {
        products,
        type: 'devis',
      };
      this.dialog.open(NewQuotationDialogComponent, {
        data: data_,
        maxWidth: '100%',
      });
    } else {
      this.loginOverlayService.openLogin();
    }
  }

  ngOnChanges() {
    this.quantity.setValue(1);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

}
