import { AbstractDTO } from './abstract.dto';
export enum AboutType {
  cookies = 'cookies',
  legal = 'legal',
  general_condition = 'general_condition',
  security = 'security',
}

export interface AboutDto extends AbstractDTO {
  id: number;
  title: string;
  body: string;
  type?: AboutType;
}
