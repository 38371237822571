<div
  cdkOverlayOrigin
  #overlayOrigin="cdkOverlayOrigin"
  class="category-menu"
  fxLayout="row nowrap"
  fxLayoutAlign="flex-start center"
  fxLayoutGap="70px"
>
  <div
    class="category"
    style="background-color: white"

    (click)="categoriesDropdownIsOpen = !categoriesDropdownIsOpen"
  >
    <button mat-button>
      Catégoriesjjjjjjjjjjj
      <img src="../../../../assets/icons/arrow_drop_down-white.svg"  alt="Icone arrow-drop-down-white" />
    </button>
  </div>
  <div class="category">
    <a routerLink="/blog/list">Actualités</a>
  </div>
  <div class="category">
    <a routerLink="/license">Licence web</a>
  </div>
  <div class="category">
    <a routerLink="/foire-aux-questions">Foire aux questions</a>
  </div>
  <div class="category">
    <a routerLink="/about">Qui sommes-nous?</a>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="overlayOrigin"
  [cdkConnectedOverlayOpen]="categoriesDropdownIsOpen"
  [cdkConnectedOverlayWidth]="
    overlayOrigin.elementRef.nativeElement.offsetWidth
  "
  [cdkConnectedOverlayOffsetY]="15"
>
  <app-category-dropdown></app-category-dropdown>
</ng-template>
