<form [formGroup]="passwordResetForm" (ngSubmit)="onPasswordReset()">
  <app-connection-dialog fxLayout="column">
    <span class="message" *ngIf="!success && !error">
      Entrer un nouveau mot de passe
    </span>
    <span class="hint-success" *ngIf="success">
      Réinitialisation du mot de passe réussie
    </span>
    <span class="message hint-error" *ngIf="error">
      {{ errorMessage }}
    </span>

    <app-password-input
      formControlName="password"
      visibilityToggle="true"
    ></app-password-input>
    <span
      class="hint"
      [ngClass]="{
        'hint-initial': !passwordControl.dirty,
        'hint-success': passwordControl.dirty && passwordControl.valid,
        'hint-error': passwordControl.dirty && passwordControl.invalid
      }"
    >
      8 caractères minimum, 1 majuscule, 1 chiffre, 1 caractère spécial
    </span>

    <button
      class="app-flat-button"
      type="submit"
      [disabled]="passwordResetForm.invalid || isLoading"
    >
      CONTINUER
    </button>
  </app-connection-dialog>
</form>
