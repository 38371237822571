import { CategoriesState } from './categories.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const getCategoriesState = createFeatureSelector<CategoriesState>('categories');

export const selectAllCategories = createSelector(
  getCategoriesState,
  (state: CategoriesState) => state.categories
);

export const selectParentCategories = createSelector(
  getCategoriesState,
  (state: CategoriesState) =>
   state.categories.filter((category) => !category.parentId && category.status === true)
);

export const selectChildCategories = createSelector(
  getCategoriesState,
  (state: CategoriesState, id: number) =>
    state.categories.filter((category) => category.parentId === id && category.status === true)
);

export const selectSubChildCategories = createSelector(
  getCategoriesState,
  (state: CategoriesState, id: number) =>
    state.categories.filter((category) => category.parentId === id && category.status === true)
);

export const selectCategoryById = createSelector(
  getCategoriesState,
  (state: CategoriesState, id: number) =>
    state.categories.find((category) => category.id === id && category.status === true)
);
export const selectCategoryByStatus = createSelector(
  getCategoriesState,
  (state: CategoriesState, id: number) =>
    state.categories.find((category) => category.id === id )
);
