import {Injectable, Injector} from '@angular/core';
import { Overlay, OverlayConfig, PositionStrategy } from '@angular/cdk/overlay';
import {ComponentPortal, PortalInjector} from '@angular/cdk/portal';
import { Subscription } from 'rxjs';
import {DIALOG_DATA} from '@angular/cdk/dialog';

@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  private subscription: Subscription;

  private overlayDialogPosition: PositionStrategy = this.overlay
    .position()
    .global()
    .centerHorizontally()
    .centerVertically();

  private overlayDialogConfig = new OverlayConfig({
    hasBackdrop: true,
    positionStrategy: this.overlayDialogPosition,
  });

  private overlayDialogRef = this.overlay.create(this.overlayDialogConfig);

  constructor(private overlay: Overlay, private injector: Injector) {
    this.subscription = this.overlayDialogRef
      .backdropClick()
      .subscribe(() => this.overlayDialogRef.detach());
  }

  public openDialog(
    componentPortal: ComponentPortal<any>,
    data?: any,
    disableBackDropClick?: boolean
  ) {
    const injector = this.createInjector(data, this.injector);
    const componentPortalWithInjector = new ComponentPortal(componentPortal.component, null, injector);

    this.overlayDialogRef.attach(componentPortalWithInjector);

    if (disableBackDropClick) {
      this.subscription.unsubscribe();
    } else {
      this.subscription = this.overlayDialogRef
        .backdropClick()
        .subscribe(() => this.overlayDialogRef.detach());
    }
  }

  private createInjector(data: any, injector: Injector): PortalInjector {
    const tokens = new WeakMap();
    tokens.set(DIALOG_DATA, data);
    return new PortalInjector(injector, tokens);
  }

  public closeDilog() {
    this.overlayDialogRef.detach();
  }
}
