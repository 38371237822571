import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../config/app.config';
import { BlogPostDto } from '../../DTO/blog-post.dto';
import { Page } from '../../DTO/page.dto';

@Injectable({
  providedIn: 'root',
})
export class BlogPostService {
  private url: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/blog-posts`;
  }

  public getAll(
    nbpage: number,
    pageSize: number
  ): Observable<Page<BlogPostDto>> {
    let params = new HttpParams()
      .set('status', 'true')
      .set('page', nbpage.toString())
      .set('size', pageSize.toString());
    return this.http.get<Page<BlogPostDto>>(this.url, { params: params });
  }

  public getArticle(id: number): Observable<BlogPostDto> {
    const url = `${this.url}/${id}`;
    return this.http.get<BlogPostDto>(url);
  }
}
