import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { ClientService } from 'tekkeys-common';
import { SnackbarService } from 'shared/services/snackbar/snackbar.service';

@Component({
  selector: 'app-footer-newsletter',
  templateUrl: './footer-newsletter.component.html',
  styleUrls: ['./footer-newsletter.component.scss'],
})
export class FooterNewsletterComponent {
  loading = false;
  mailControl = new UntypedFormControl(
    '',
    Validators.compose([
      Validators.pattern(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      ),
    ])
  );

  constructor(
    private clientService: ClientService,
    private snackbarService: SnackbarService
  ) {}

  onSubscribeToNewsletter() {
    this.loading = true;
    const mail = this.mailControl.value;
    this.clientService.anonymousSubscriptionToNewsletter(mail).subscribe(
      (res) => {
        this.snackbarService.openSnackBar(
          "L'adresse mail a été ajoutée",
          'success-snackbar'
        );
        setTimeout(() => {
          this.mailControl.reset();
          this.loading = false;
        }, 1000);
      },
      (error) => {
        if (error.status === 409) {
          this.snackbarService.openSnackBar(
            'Adresse mail déjà existante',
            'information-snackbar'
          );
        } else {
          this.snackbarService.openSnackBar(
            'Une erreur est survenue !',
            'error-snackbar'
          );
        }
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    );
  }
}
