import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as AccessoriesActions from './accessories.actions';
import { AccessoriesService } from 'tekkeys-common';

@Injectable()
export class AccessoriesEffects {
  constructor(
    private actions$: Actions,
    private accessorieService: AccessoriesService,
  ) {
  }

  // get list accessories product
  ListAccessories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccessoriesActions.AccessoriesPageRequested),
      switchMap((action) =>
        this.accessorieService
          .getListAccessories(
            action.page,
            action.size,
            action.categoryId
          ).pipe(
            map((accessoriesProduct) =>
              AccessoriesActions.AccessoriesPageLoadedSuccessfully({
                accessories : accessoriesProduct
              })
            ),
            catchError((error) =>
              of(AccessoriesActions.AccessoriesPageLoadFailed({ error }))
            )))));

}
