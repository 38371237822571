import {Directive, OnDestroy} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

/**
 * Symbol permet quel la variable ne soit jamais écrasé par la classe fille
 * Symbol est complétement unique et n'est égal que à ceux qui ont la même référence
 */
const ON_DESTROY_SUBJECT_KEY = Symbol('ON_DESTROY_SUBJECT_KEY');

/**
 * Permet de couper automatiquement toutes les subscriptions qui utilisent takeUntil(componentDestroyed(this))
 * On utilise l'annotation @Directive() pour éviter l'erreur suivante :
 *
 * Class is using Angular features but is not decorated. Please add an explicit Angular decorator.
 *
 * Angular refuse qu'une classe abstraite utilise une méthode ngOnDestroy même si elle n'est utilisée que par des
 * composants / directives / services annotés correctement
 */
@Directive()
export abstract class ObservableAutoCleanupBean implements OnDestroy {
  [ON_DESTROY_SUBJECT_KEY] = new ReplaySubject<void>();

  ngOnDestroy() {
    this[ON_DESTROY_SUBJECT_KEY].next();
  }
}

function componentDestroyed(target: ObservableAutoCleanupBean): Observable<void> {
  return target[ON_DESTROY_SUBJECT_KEY];
}

/**
 * Utilisé dans un pipe rxjs, permet de clore l'observable source une fois le composant passé en paramètre détruit
 */
export function untilComponentDestroyed<T>(component: ObservableAutoCleanupBean): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>) => source.pipe(takeUntil(componentDestroyed(component)));
}
