import { createAction, props } from '@ngrx/store';

import { ClientDTO } from 'tekkeys-common';

export const loadCollaborators = createAction(
  '[Collaborators] Load Collaborators'
);

export const loadCollaboratorsSuccess = createAction(
  '[Collaborators] Load Collaborators Success',
  props<{ collaborators: ClientDTO[] }>()
);

export const clearCollaborators = createAction(
  '[Collaborators] Clear Collaborators'
);
