import { createReducer, on } from '@ngrx/store';

import { DiscountCompanyDTO } from 'tekkeys-common';
import * as fromDiscountCompanyActions from './discount-company.actions';
import {  DiscountCompanyState } from './discount-company.state';


export const initialDiscountCompanyState: DiscountCompanyState = {
  discountCompany: null,
  applicable: false,
  error: null,
  errorMessage: ''
}
export const discountCompanyReducer = createReducer(
  initialDiscountCompanyState,

  on(fromDiscountCompanyActions.loadDiscountCompanySuccess, (state, action) => {
    return {
      ...state,
      discountCompany: action.discountCompany,
      applicable: checkDiscountCompanyUsability(action.discountCompany)
    };
  }
  ),

  on(fromDiscountCompanyActions.loadDiscountCompanyFailure, (state, action) => {
    const errorMessage = displayErrorMessage(action.error.status);
    return {
      ... state,
      error: action.error,
      errorMessage,
      applicable: false
    };
  }),

  on(fromDiscountCompanyActions.clearDiscountCompany, (state, action) => {
    return {
      ... state,
      discountCompany: null,
      applicable: false
    }
  })
);

// A function that checks if the current discount is applicable depending on it status and it time interval
function checkDiscountCompanyUsability(discountCompany: DiscountCompanyDTO): boolean {
  const date = new Date();
  return !(discountCompany.createdAt > date || discountCompany.endAt < date || !discountCompany.discountStatus);
}


// This function is used to generate the error message
function displayErrorMessage(errorStatus) {
  switch (errorStatus) {
    case 500:
      return 'Erreur Interne Du Serveur';
    case 403:
      return 'Authentification Non Autorisée';
    case 404:
      return 'Requête Demandée Est Introuvable';
    case 406:
      return 'Un Code Promo Est Activé déja';
    case 409:
      return 'Vous Pouvez Avoir Un Seule Remise Entreprise Pour Le Moment';
    default:
      return 'Erreur Non Reconnue';
  }
}
