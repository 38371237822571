<input
  [readOnly]="readOnly"
  [(ngModel)]="value"
  (blur)="onTouch()"
  (keypress)="onlyNumberKey($event)"
  class="phone-input"
  type="tel"
  maxlength="35"
  placeholder="+XXX XXX XX XX XX"
/>
