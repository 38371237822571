<div fxLayout="column" fxLayoutAlign=" center">
  <img alt="erreur" src="assets/images/new-request.svg" />
  <div *ngIf="!data.isCreated">
  <h1>Votre demande est en cours de validation</h1>
  <h2>
    Vous avez envoyé une demande d'adhésion à <b>{{ data.company }}</b> .
    Vous serez notifié une fois votre demande confirmée par son Administrateur <b>{{ data.email }}</b>.
  </h2>
</div>
  <div *ngIf="data.isCreated">
    <h1>Votre demande est en cours de validation</h1>
    <h2>
      Vous avez envoyer une demande de création de la société  <b>{{ data.company }}</b>
      . Vous serez notifié une fois votre demande confirmée par l'équipe Imtech.
    </h2>
  </div>
  <button mat-button class="app-flat-button" (click)="closeDialog()">
    continuer
  </button>
</div>
