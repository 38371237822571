import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ROOT_EFFECTS_INIT,
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, switchMapTo, map, catchError } from 'rxjs/operators';

import { CompanyService } from 'tekkeys-common';

import { loginSuccess, logout } from 'store/auth/auth.actions';
import {
  loadCompany,
  loadCompanySuccess,
  loadCompanyFailure,
  unloadCompany,
} from 'store/company/company.actions';

@Injectable()
export class CompanyEffects {
  init = createEffect(() =>
    this.actions.pipe(ofType(ROOT_EFFECTS_INIT), switchMapTo(of(loadCompany())))
  );

  loadCompany = createEffect(() =>
    this.actions.pipe(
      ofType(loadCompany),
      switchMap(() =>
        this.companyService.me().pipe(
          map((response) => loadCompanySuccess({ company: response })),
          catchError((error: HttpErrorResponse) =>
            of(loadCompanyFailure({ errorCode: error.status }))
          )
        )
      )
    )
  );

  loginSuccess = createEffect(() =>
    this.actions.pipe(ofType(loginSuccess), switchMapTo(of(loadCompany())))
  );

  logout = createEffect(() =>
    this.actions.pipe(ofType(logout), switchMapTo(of(unloadCompany())))
  );

  constructor(
    private companyService: CompanyService,
    private actions: Actions
  ) {}
}
