import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { NotificationDTO, NotificationService } from 'tekkeys-common';

import {
  loadNotifications,
  loadNotificationsSuccess,
  seenNotification,
  seenNotificationSuccess,
} from './notifications.actions';

@Injectable()
export class NotificationsEffects {
  loadNotifications = createEffect(() =>
    this.actions.pipe(
      ofType(loadNotifications),
      switchMap(() =>
        this.notificationService.get().pipe(
          map((response) =>
            loadNotificationsSuccess({ notifications: response })
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  seenNotification = createEffect(() =>
    this.actions.pipe(
      ofType(seenNotification),
      switchMap((action) =>
        this.notificationService
          .updateSeen(action.notificationId, action.seen)
          .pipe(
            map((response) => {
              const updatedNotification: Update<NotificationDTO> = {
                id: response.id,
                changes: response,
              };
              return seenNotificationSuccess({ updatedNotification });
            }),
            catchError(() => EMPTY)
          )
      )
    )
  );

  constructor(
    private notificationService: NotificationService,
    private actions: Actions
  ) {}
}
