<mat-card
  class="product-card wrapper"
  [ngClass]="{ 'list-card': cardType === 'list' }"
>
  <mat-card-content>
    <div class="product-image animate"></div>
    <div class="product-title animate"></div>
    <div class="product-description animate"></div>
    <div class="product-price">
      <div class="price animate"></div>
    </div>
  </mat-card-content>
</mat-card>
