<mat-toolbar class="navbar">
  <mat-toolbar-row>
    <div class="container">
      <div style="
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
        " fxLayoutGap="8px">
        <!--   avoid using FlexLayout bcz it takes time to load  -->
        <div class="logo-container" fxFlex.xs="30" fxFlex.sm="20" fxFlex.md="15" fxFlex.lg="13.5" fxFlex.xl="13.5">
          <app-navbar-logo></app-navbar-logo>
        </div>

        <div class="search-bar-container" fxFlex.sm="auto" fxFlex.xs="auto" fxFlex.md="45" fxFlex.lg="40"
          fxFlex.xl="40">
          <app-navbar-search></app-navbar-search>
        </div>

        <div class="navbar-controls-container" fxLayout="row nowrap" fxLayoutAlign="flex-start center" fxFlex.xs="66"
          fxFlex.sm="75" fxFlex.md="30" fxFlex.lg="20" fxFlex.xl="20">
          <app-navbar-controls ></app-navbar-controls>
        </div>
        <div fxHide.lt-md class="support-btn">
          <button mat-button class="" (click)="openSupportDialog()">
            {{'app.navBar.commercialService' | translate | uppercase}}
          </button>
        </div>

        <button class="menu-btn" (click)="openSidenav()" fxHide.gt-sm="true">
          <img src="assets/icons/menu.svg" alt="Icone menu"/>
        </button>
      </div>
    </div>
  </mat-toolbar-row>

  <mat-toolbar-row fxHide.lt-md style="padding-top: 15px">
    <div class="container"  style="height: 100%;">
      <app-category-menu2></app-category-menu2>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
