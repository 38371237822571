import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-social-links',
  templateUrl: './footer-social-links.component.html',
  styleUrls: ['./footer-social-links.component.scss']
})
export class FooterSocialLinksComponent {


}
