<label class="label">{{ label }}</label>
<select required [(ngModel)]="value" class="select-input" [disabled]="disable">
  <option
    *ngFor="let option of options"
    [value]="option.value"
    [disabled]="option.disabled"
  >
    {{ option.name }}
  </option>
</select>
