import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { SnackbarService } from 'shared/services/snackbar/snackbar.service';
import {
  getMyListQuotation,
  getMyListQuotationSuccess,
} from 'store/quotationList/quotation.actions';
import { QuotationService } from 'tekkeys-common';

@Injectable()
export class QuotationListEffects {
  // get list quotation
  loadMyQuotation$ = createEffect(() => {
    return this.actions.pipe(
      ofType(getMyListQuotation),
      mergeMap((action) =>
        this.quotationService.getMyListQuotations(
          action.page,
          action.size,
          action.id,
          action.search,
        ).pipe(
          map((quotation) =>
            getMyListQuotationSuccess({
              quotation: quotation.content,
              totalElements: quotation.totalElements,
              totalPages: quotation.totalPages,
            })
          ),
          catchError((e) => {
            this.snackbarService.openSnackBar(
              'Une erreur s\'est produite',
              'information-snackbar'
            );
            return EMPTY;
          })
        )
      )
    );
  });

  constructor(
    private quotationService: QuotationService,
    private snackbarService: SnackbarService,
    private actions: Actions
  ) {}
}
