import { createFeatureSelector, createSelector } from '@ngrx/store';
import { quotationAdapter, QuotationState } from 'store/quotation/quotation.state';
import { QuotationItemDto } from 'tekkeys-common';

const adapterSelectors = quotationAdapter.getSelectors();

const getQuotationState = createFeatureSelector<QuotationState>('quotation');

export const selectQuotation = createSelector(
  getQuotationState,
  adapterSelectors.selectAll
);

export const selectQuotationItemsCount = createSelector(
  getQuotationState,
  adapterSelectors.selectTotal
);

export const selectTotalQuotationItemsCount = createSelector(
  selectQuotation,
  (state: QuotationItemDto[]) =>
    state.reduce(
      (acc: number, cur: QuotationItemDto) => acc + cur.price * cur.quantity,
      0
    )
);
