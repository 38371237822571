<footer
  class="footer container dont-print"
  gdColumns="25% 25% 36%"
  gdAreas="info links payment | info links newsletter"
  gdAlignRows="space-between"
  ngStyle.gt-xs="margin: 90px auto;"
  gdColumns.lt-sm="100%"
  gdGap="32px"
  gdGap.lt-md="64px"
  gdColumns.sm="45% 45%"
  gdAreas.sm="info links| info newsletter | payment payment"
  gdAreas.lt-sm="info | newsletter | links  | payment"
  ngStyle.lt-sm="margin: 0 auto 64px auto;"
>
  <div gdArea="info" class="footer-info">
    <app-footer-info></app-footer-info>
  </div>
  <div gdArea="links">
    <app-footer-links></app-footer-links>
  </div>
  <div gdArea="payment">
    <app-footer-payment></app-footer-payment>
  </div>
</footer>

