<div class="dialog">
  <div class="dialog-titlebar">
    &nbsp;<h3>{{ title | uppercase }}</h3>
    <button mat-icon-button (click)="close()">
      <img src="assets/icons/close.svg" alt="close" />
    </button>
  </div>
  <div class="dialog-content">
    <ng-content></ng-content>
  </div>
</div>
