import { Injectable } from '@angular/core';
import { ProductFavOptDto } from 'dist/tekkeys-common/lib/DTO/productFavOpt.dto';
import { QuotationItemOptDto } from 'projects/tekkeys-common/src/public-api';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ProductDTO, QuotationItemDto, QuotationService } from 'tekkeys-common';

@Injectable({
  providedIn: 'root',
})
export class LocalQuotationService {
  quotationItems: QuotationItemDto[] = [];
  quotationItemsOpt: QuotationItemOptDto[] = [];
  quotationSubject = new BehaviorSubject<QuotationItemDto[]>([]);
  public readonly quotationItems$ = this.quotationSubject.asObservable();

  constructor(private quotationService: QuotationService) {
    this.quotationItems = localStorage.getItem('quotation')
      ? JSON.parse(localStorage.getItem('quotation'))
      : [];
    this.quotationSubject.next(this.quotationItems);
  }

  getQuotationItems() {
    this.quotationItems = localStorage.getItem('quotation')
      ? JSON.parse(localStorage.getItem('quotation'))
      : this.quotationItems;
    this.quotationSubject.next(this.quotationItems);
    return this.quotationItems;
  }

  addQuotationItem(product: ProductDTO, quantity: number) {
    let quotationItem = this.quotationItems.find(
      (q) => q.product.id === product.id
    );
    quotationItem = quotationItem
      ? {
          ...quotationItem,
          quantity: quantity + quotationItem.quantity,
          itemPrice: (quantity + quotationItem.quantity) * quotationItem.price,
        }
      : {
          quotationId: 0,
          quantity,
          price: product.price,
          itemPrice: product.price,
          product,
        };
    this.quotationItems = [
      ...this.quotationItems.filter((c) => c.product.id !== product.id),
      quotationItem,
    ];
    localStorage.setItem('quotation', JSON.stringify(this.quotationItems));
    this.getQuotationItems();
  }

  addQuotationItemOpt(product: ProductFavOptDto, quantity: number) {
    let quotationItem = this.quotationItemsOpt.find(
      (q) => q.product.id === product.id
    );
    quotationItem = quotationItem
      ? {
          ...quotationItem,
          quantity: quantity + quotationItem.quantity,
          itemPrice: (quantity + quotationItem.quantity) * quotationItem.price,
        }
      : {
          quotationId: 0,
          quantity,
          price: product.price,
          itemPrice: product.price,
          product,
        };
    this.quotationItemsOpt = [
      ...this.quotationItemsOpt.filter((c) => c.product.id !== product.id),
      quotationItem,
    ];
    localStorage.setItem('quotation', JSON.stringify(this.quotationItems));
    this.getQuotationItems();
  }

  updateQuotationItem(quotationItem: QuotationItemDto) {
    localStorage.setItem(
      'quotation',
      JSON.stringify([
        ...this.quotationItems.filter(
          (c) => c.product.id !== quotationItem.product.id
        ),
        quotationItem,
      ])
    );
    this.getQuotationItems();
  }

  deleteQuotationItem(productId: number) {
    localStorage.setItem(
      'quotation',
      JSON.stringify(
        this.quotationItems.filter((f) => f.product.id !== productId)
      )
    );
    this.getQuotationItems();
  }

  addToQuotations() {
    if (this.getQuotationItems().length) {
      this.quotationService
        .addItems(this.getQuotationItems())
        .pipe(take(1))
        .subscribe();
      localStorage.setItem('route', '"/checkout"');
    }
    this.clearQuotations();
  }

  clearQuotations() {
    if (JSON.parse(localStorage.getItem('quotation'))) {
      localStorage.removeItem('quotation');
      this.quotationItems = [];
      this.quotationSubject.next(null);
      this.getQuotationItems();
    }
  }
}
