import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../config/app.config';
import { AboutDto } from '../../DTO/about.dto';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AboutService {
  private url: string;
  private url2: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/user-conditions`;
    this.url2 = `${this.appConfig.apiEndpoint}/user_question`;
  }
  public GetByType(type: string): Observable<any> {
    const url = `${this.url}/type/${type}`;
    return this.http.get<AboutDto[]>(url).pipe(
      map(data => {
        // Trier les données par title
        return data.sort((a, b) => a.title.localeCompare(b.title));
      })
    );
  }
  public GetCondition(type:string): Observable<any>{
    const url = `${this.url2}/faq?size=50`;
    return this.http.get<AboutDto[]>(url);
  }
}
