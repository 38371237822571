<div class="dialog">
  <div
    class="dialog-header"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <h2>Mes notifications</h2>
    <button mat-icon-button (click)="closeDialog()">
      <img src="assets/icons/close.svg" alt="close" />
    </button>
  </div>

  <ng-scrollbar class="dialog-content" fxLayout="column">
    <app-notifications></app-notifications>
  </ng-scrollbar>
</div>
