<app-edit-dialog title="demande d'ajout d'une adresse de livraison" [dialogRef]="dialogRef">
  <form [formGroup]="addAddressForm" (ngSubmit)="onAdd()">
    <div fxLayout="column">
      <label>Contact</label>
      <app-text-input
        class="small-input"
        formControlName="contactName"
        (keypress)="onKeyPress($event)"
        label="Société ou Nom et Prénom"
      ></app-text-input>
      <app-phone-input
        formControlName="contactPhone"
        label="Téléphone"
      ></app-phone-input>

      <label>Adresse</label>

      <app-text-input
        class="small-input"
        formControlName="addressTitle"
        (keypress)="onKeyPress($event)"
        label="Intitulé de l'addresse"
      ></app-text-input>
      <app-text-input
        class="small-input"
        formControlName="streetName"
        label="Adresse"
        (keypress)="onKeyPress($event)"
      ></app-text-input>

      <div fxLayout fxLayoutGap="16px">
        <app-text-input
          class="small-input"
          formControlName="city"
          label="Ville"
          (keypress)="onKeyPress($event)"
          fxFlex
        ></app-text-input>
        <app-text-input
          class="small-input"
          formControlName="postalCode"
          label="Code postal"
          fxFlex
        ></app-text-input>
      </div>

      <div fxLayout fxLayoutGap="16px">
        <app-text-input
          class="small-input"
          formControlName="cedex"
          label="Cedex"
          fxFlex
        ></app-text-input>
       <app-text-input
          class="small-input"
          formControlName="countryID"
          label="Pays"
          fxFlex
          [matAutocomplete]="country"
          [postIcon]="'assets/icons/arrow_drop_down.svg'"
        ></app-text-input>
      </div>
      <mat-autocomplete #country="matAutocomplete">
        <mat-option
          *ngFor="let country of countries$ | async"
          [value]="country.name"
        >
          {{ country.name }}
        </mat-option>
      </mat-autocomplete>
      <div fxLayout fxLayoutGap="16px">
        <input
          class="small-input"
          type="checkbox"
          formControlName="isDefault"
        />
        <label>Adresse par défaut</label>
      </div>

      <div class="buttons" fxLayout fxLayoutGap="16px">
        <button
          class="app-stroked-button"
          type="button"
          (click)="dialogRef.close()"
        >
          ANULLER
        </button>
        <button
          class="app-flat-button"
          type="submit"
          [disabled]="
            addAddressForm.invalid ||
            isLoading ||
            !addAddressForm.dirty ||
            !isCountry
          "
        >
          Envoyer
        </button>
      </div>
    </div>
  </form>
</app-edit-dialog>
