import { Component } from '@angular/core';
import { OverlayService } from 'shared/services/overlay/overlay.service';

@Component({
  selector: 'app-notifications-dialog',
  templateUrl: './notifications-dialog.component.html',
  styleUrls: ['./notifications-dialog.component.scss'],
})
export class NotificationsDialogComponent  {

  constructor(private overlayService: OverlayService) {}


  closeDialog() {
    this.overlayService.closeDilog();
  }
}
