import { createAction, props } from '@ngrx/store';
import { OrderOptDto } from 'projects/tekkeys-common/src/public-api';


export const loadOrders = createAction(
  '[Order List Page] Orders Page Requested ',
   props<{pageSize : number, pageIndex: number }>()
);

export const loadOrdersByStatus = createAction(
  '[Order List Page] Orders Page By Status Requested ',
   props<{status: string, pageSize: number, pageIndex: number }>()
);
export const loadOrdersSuccess = createAction(
  '[Order API] Orders Page Loaded Successfully ',
  props<{ orders: OrderOptDto[], totalPages: number, totalElements: number}>()
);
export const loadOrdersFailure = createAction(
  '[Order API] Orders Page Request Failed ',
  props<{ error: any }>()
);

// use new api TO FILTRE
export const loadOrdersOpt = createAction(
  '[Order List Page Opt] Orders Page Requested Opt',
   props<{size : number, page: number }>()
);
export const loadOrdersByStatusOpt = createAction(
  '[Order List Page opt] Orders Page By Status Requested opt',
   props<{status: string, size: number, page: number, startingDate:string, endingDate:string, search:string }>()
);
export const loadOrdersSuccessOpt = createAction(
  '[Order API opt] Orders Page Loaded Successfully opt',
  props<{ orders: any[], totalPages: number, totalElements: number}>()
);
export const loadOrdersFailureOpt = createAction(
  '[Order API opt] Orders Page Request Failed opt ',
  props<{ error: any }>()
);
