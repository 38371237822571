import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-card-shimmer',
  templateUrl: './product-card-shimmer.component.html',
  styleUrls: ['./product-card-shimmer.component.scss'],
})
export class ProductCardShimmerComponent  {
  @Input() cardType = null;




}
