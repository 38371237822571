import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectChildCategories, selectParentCategories } from 'store/categories/categories.selectors';
import { CategoryDTO } from 'tekkeys-common';

@Component({
  selector: 'app-category-dropdown',
  templateUrl: './category-dropdown.component.html',
  styleUrls: ['./category-dropdown.component.scss'],
})
export class CategoryDropdownComponent {
  selectedCategory: CategoryDTO;
  showDropDown = false;

  parentCategories: Observable<CategoryDTO[]> = this.store.select(
    selectParentCategories
  );
  childCategories: Observable<CategoryDTO[]> = this.store.select((state) =>
    selectChildCategories(state, null)
  );

  constructor(private store: Store) {}

  selectCategory(category: CategoryDTO) {
    this.selectedCategory = category;
    this.childCategories = this.store.select((state) =>
      selectChildCategories(state, category.id)
    );
  }
}
