import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AccountState } from './account.state';

const getAccountState = createFeatureSelector<AccountState>('account');

export const selectIsConnected = createSelector(
  getAccountState,
  (state: AccountState) => {
    return   !!state.isConnected
  }
);

export const selectAccount = createSelector(
  getAccountState,
  (state: AccountState) => state.account
);

