
<div class="" fxLayout fxLayoutGap="16px" fxLayout.xs=" wrap">
  <span *ngIf="overQuantity===true" class="product-designation" fxFlex="70">{{
    designation    }}</span>
  <span class="product-designation" fxFlex="70">{{
    quotationItem?.product?.designation
  }}</span>
  <app-number-input fxFlex="" [formControl]="quantity"></app-number-input>
</div>

