import { AppConfig } from 'tekkeys-common';

const TEKKEYS_DEV_CONFIG: AppConfig = {
  name: 'Tekkeys front office development configuration',
  apiEndpoint: 'https://api.imtech.preprod.imtech-solutions.com/api/v1'
};

export const environment = {
  production: true,
  appConfig: TEKKEYS_DEV_CONFIG,
  apiKeyStripe:
    'pk_test_51HFgSgLDNcQS7uB4qvOWfsWLlTMzoLkh6399Nwt0rl4Ao6FYd4FEG6lCppr6NCcHjrVkZCaHGkvZmJvdWWA9kVoo00bccHlyel',
  apiWebSocket: 'api.imtech.dev.wissal-group.com/api/v1/ws/notifications',
};
