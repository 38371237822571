import { createAction, props } from '@ngrx/store';
import { Page } from 'tekkeys-common';
import { AccessoriesDto } from '../../../../projects/tekkeys-common/src/lib/DTO/accessories.dto';

export const AccessoriesPageRequested = createAction(
  '[Accessories List Page] Accessories Page Requested ',
  props<{ page: number; size: number; categoryId: number, }>()
);
export const AccessoriesPageLoadedSuccessfully = createAction(
  '[Accessories API] Accessories Page Loaded Successfully ',
  props<{ accessories: Page<AccessoriesDto[]>}>()
);
export const AccessoriesPageLoadFailed = createAction(
  '[Accessories API] Accessories Page Request Failed ',
  props<{ error: any }>()
);
