import {
  trigger,
  sequence,
  animate,
  transition,
  style,
} from '@angular/animations';

export const floatInAnimation = trigger('floatInAnimation', [
  transition(
    'void => *',
    [
      style({ opacity: 0, transform: 'translateY({{translateY}}px)' }),
      sequence([
        animate(
          '{{animateTime}}s ease',
          style({
            height: '*',
            opacity: 1,
            transform: 'translateY(0)',
          })
        ),
      ]),
    ],
    { params: { translateY: '100', animateTime: '1.65' } }
  ),
]);
