import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { OrderOptDto } from 'projects/tekkeys-common/src/public-api';


import * as ordersActions from './orders.actions';


export interface OrdersState extends EntityState<OrderOptDto> {
  listLoading: boolean;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<OrderOptDto> =
  createEntityAdapter<OrderOptDto>();

  export const initialOrderState: OrdersState = adapter.getInitialState({
    listLoading: false,
    totalElements: 0,
    totalPages: 0,
  });



export const ordersReducer = createReducer<OrdersState>(
  initialOrderState,
  on(ordersActions.loadOrdersSuccess, (state, action) =>
  adapter.setAll(action.orders, {
    ...state,
    listLoading: false,
    totalElements: action.totalElements,
    totalPages: action.totalPages
  })
),
// use new api TO FILTRE
on(ordersActions.loadOrdersSuccessOpt, (state, action) =>
adapter.setAll(action.orders, {
  ...state,
  listLoading: false,
  totalElements: action.totalElements,
  totalPages: action.totalPages
})
)
)

export const {selectAll} = adapter.getSelectors();
