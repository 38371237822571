import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { updateItem } from 'store/quotation/quotation.actions';
import { QuotationItemDto } from 'tekkeys-common';

@Component({
  selector: 'app-new-quote-dialog-item',
  templateUrl: './new-quote-dialog-item.component.html',
  styleUrls: ['./new-quote-dialog-item.component.scss'],
})
export class NewQuoteDialogItemComponent implements OnInit {
  @Input() quotationItem: QuotationItemDto;
  @Input() designation: string;
  @Input() devisQuantity: number;
  @Input() overQuantity: boolean;
  quantity: UntypedFormControl = new UntypedFormControl();
  @Output() changedQuantity: EventEmitter<number> = new EventEmitter<number>();

  constructor(private store: Store, private router: Router) {}

  ngOnInit(): void {
    if (this.overQuantity === true) {
      this.quantity.setValue(this.devisQuantity);
    }else {
    this.quantity.setValue(this.quotationItem?.quantity);}
    this.quantity.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((quantity: number) => {
        if (this.router.url.includes('/checkout')) {
          this.store.dispatch(
            updateItem({ quotationItem: { ...this.quotationItem, quantity } })
          );
        } else {
          this.changedQuantity.emit(this.quantity.value);
        }
      });
  }
}
