import {Component, Inject, OnInit} from '@angular/core';
import {CartItemDTO} from 'tekkeys-common';
import {UntypedFormControl, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AccountState} from 'store/account/account.state';
import {addToQuotationCartItem} from 'store/quotation/quotation.actions';
import { pluck, shareReplay} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {QuotationDto} from '../../../../../projects/tekkeys-common/src/lib/DTO/quotation.dto';
import {ClientDTO} from '../../../../../projects/tekkeys-common/src/lib/DTO/client.dto';
import {loadCart, setQuantity} from 'store/cart/cart.actions';
import {selectCart} from 'store/cart/cart.selectors';
import {selectIsConnected} from 'store/account/account.selectors';
import {LocalQuotationService} from 'shared/services/localQuotations.service';
import {QuotationCartDto} from '../../../../../projects/tekkeys-common/src/lib/DTO/quotation-cart.dto';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DATAPRODUCT {
  cartItemId: number;
  quantity: number;
}

@Component({
  selector: 'app-new-quotation-dialog-from-quantity',
  templateUrl: './new-quotation-dialog-from-quantity.component.html',
  styleUrls: ['./new-quotation-dialog-from-quantity.component.scss'],
})
export class NewQuotationDialogFromQuantityComponent implements OnInit {

  account = this.store.select('account');
  message = new UntypedFormControl('', [Validators.required]);
  quantity: number;
  clientId: number;
  isVirtual: boolean;
  client: ClientDTO;
  quotation: QuotationDto;
  isDefault = false;
  cartStore: Observable<CartItemDTO[]>;
  diff: number[] = [];
  isConnected: boolean;
  cartItem: CartItemDTO[] = [];
  quotationCart: QuotationCartDto[] = [];
  cart = [{quantity: 0 , price: 0, productId: 0}];

  constructor(
    private dialogRef: MatDialogRef<NewQuotationDialogFromQuantityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DATAPRODUCT,
    private store: Store<{ account: AccountState }>,
    private localQuotationService: LocalQuotationService,
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(loadCart());
    this.cartStore = this.store.select(selectCart);
    this.store.select(selectIsConnected).subscribe((isConnected) => {
      if (isConnected) {
        this.isConnected = true;
      }
    });
    let q;
    this.cartStore.subscribe((value) => {
      value.forEach((cart, index) => {
        if (cart.quantity > cart.product.quantity && !cart.product.remainder) {
          q = cart.quantity - cart.product.quantity;
          this.cartItem[index] = cart;
          this.diff[index] = q;
          if (this.cartItem[index]?.id === this.cartItem[index - 1]?.id && index !== 0) {
          this.cartItem.splice(index, 1);
          this.diff.splice(index, 1);
          }}
      });
    });
  }

  close() {
    this.dialogRef.close();
  }

  next() {
    this.store
      .select('account')
      .pipe(shareReplay(), pluck('account'))
      .subscribe((client) => (this.clientId = client?.id));
    this.addToQuote();
    this.close();
  }

  addToQuote() {
    this.cartItem.forEach((cartItem, index) => {
      this.cart[index] = {quantity: this.diff[index], price: cartItem.product.price, productId: cartItem.product.id};
      this.store.dispatch(
          setQuantity({
            cartItemId: cartItem.id,
            quantity: cartItem.product.quantity,
          }));
      this.quotationCart[index] = this.cart[index];
      if (!this.isConnected) {
        this.localQuotationService.addQuotationItem(
          cartItem.product,
          this.diff[index]
        );
        return;
      }
      if (!this.isConnected) {
        this.localQuotationService.addQuotationItem(
          cartItem.product,
          this.diff[index]
        );
      }
    });
    this.store.dispatch(
      addToQuotationCartItem({
        cartQuotation : this.quotationCart
      })
    );
  }
}
