import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import {
  ResendConfirmationMailComponent,
} from 'shared/components/resend-confirmation-mail/resend-confirmation-mail.component';
import { LoginOverlayService } from 'shared/services/overlay/login-overlay.service';
import { login, loginFailure, loginSuccess } from 'store/auth/auth.actions';
import { AuthState } from 'store/auth/auth.state';
import {OverlayService} from 'shared/services/overlay/overlay.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loginFailure: Actions;
  loginFailureCode;
  headerMessage: string;
  authStore: Observable<AuthState> = this.store
    .select('auth')
    .pipe(shareReplay());
  @Output() goToSignup = new EventEmitter<void>();
  @Output() goToRequestPasswordReset = new EventEmitter<void>();
  @Output() loginSuccess = new EventEmitter<void>();


  constructor(
    public dialogRef: MatDialog,
    private store: Store<{ auth: AuthState }>,
    private actions: Actions,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public  overlayService: OverlayService,
    private loginOverlayService: LoginOverlayService
  ) {

  }

  get mailControl(): AbstractControl {
    return this.loginForm.get('mail');
  }

  get passwordControl(): AbstractControl {
    return this.loginForm.get('password');
  }
  ngOnInit(): void {
    this.loginForm = this.fb.group({
      mail: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          ),
        ]),
      ],
      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(8)]),
      ],
    });
    this.actions.pipe(ofType(loginSuccess)).subscribe(() => {
      this.loginSuccess.emit();
      this.route.queryParams.subscribe((params) => {
        if (params['redirectUrl']) {
          this.router.navigateByUrl(params['redirectUrl']);
        }
      });
    });
    this.actions.pipe(ofType(loginFailure)).subscribe((err) => {
      this.loginFailureCode = err.errorCode;
    });
  }
  handleContainerClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (target.classList.contains('app-stroked-button') || target.classList.contains('app-flat-button')) {
      // Ignore clicks on the buttons themselves
      return;
    }

    // Handle click event for the container
    // Add your logic here
  }

  onLogin() {
    const payload = this.loginForm.value;
    this.store.dispatch(login(payload));
  }
  close() {
    this.loginOverlayService.closeLogin();
  }
  openDialog(): void {
    const payload = this.loginForm.value;
    this.dialog.open(ResendConfirmationMailComponent, {
      data: {email: payload.mail.toLowerCase()},
    });

    this.loginOverlayService.closeLogin();
  }
}
