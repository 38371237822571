import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { CartItemDTO, CartAndFavoriteItemCountDTO } from 'tekkeys-common';


export const loadCart = createAction('[Cart] Load Cart');

export const loadCartSuccess = createAction(
  '[Cart] Load Cart Success',
  props<{ cart: CartItemDTO[] }>()
);

export const addToCart = createAction(
  '[Cart] Add To Cart',
  props<{ productId: number; quantity: number }>()
);

export const addToCartSuccess = createAction(
  '[Cart] Add To Cart Success',
  props<{ cartItem: CartItemDTO }>()
);

export const addToCartFailed = createAction(
  '[Cart] Add To Cart Failed',
  props<{ error: any }>()
);

export const addFromFavoriteToCart = createAction(
  '[Cart] Add To Cart From Favorites',
  props<{ favoriteId: number }>()
);

export const addFromQuotationToCart = createAction(
  '[Cart] Add To Cart From Quotation',
  props<{ productId: number; quantity: number }>()
);

export const setQuantity = createAction(
  '[Cart] Set Item Quantity',
  props<{ cartItemId: number; quantity: number }>()
);

export const setQuantitySuccess = createAction(
  '[Cart] Set Item Quantity Success',
  props<{ update: Update<CartItemDTO> }>()
);
export const setQuantityFailed = createAction(
  '[Cart] Set Item Quantity Failed',
  props<{ error: any }>()
);

export const removeCartItem = createAction(
  '[Cart] Remove Item',
  props<{ id: number }>()
);

export const removeCartItemSuccess = createAction(
  '[Cart] Remove Item Success',
  props<{ id: number }>()
);
export const ApplyPromoCode = createAction(
  '[PromoCode] applyPromoCode',
  props<{ promoCodeValue: string; }>()
);

export const ApplyPromoCodeSuccessfully = createAction(
  '[PromoCode] applyPromoCode Success',
  props<{ promoCode: any}>()
);

export const ApplyPromoCodeFailed = createAction(
  '[PromoCode] applyPromoCode Failed',
  props<{ error: any }>()
);

export const getCartAndFavoriteItemCount = createAction(
  '[Cart] Get Cart And Favorite Item Count'
);

export const getCartAndFavoriteItemCountSuccess = createAction(
  '[Cart] Get Cart And Favorite Item Count Success',
  props<{ itemsCount: CartAndFavoriteItemCountDTO}>()
);

export const getCartAndFavoriteItemCountFailure = createAction(
  '[Cart] Get Cart And Favorite Item Count Failed',
  props<{ error: any }>()
);



export const clearCart = createAction('[Cart] Clear Cart');
