<div>
  <button
    mat-button
    class="decrement-btn"
    (click)="decrement()"
    [disabled]="isDisabled || valueControl.value <= min"
  >
    <img
      [src]="
        isDisabled || valueControl.value <= min
          ? 'assets/icons/remove-disabled.svg'
          : 'assets/icons/remove.svg'
      "
      alt="decrement"
    />
  </button>
  <input
    [formControl]="valueControl"
    type="number"
    [min]="min"
    [max]="context === 'panier' ? quantityAffiche : null"
    class="number-input"
    (input)="handleInput($event)"
  />
  <button
    mat-button
    class="increment-btn"
    (click)="increment()"
    [disabled]="(context === 'panier' ||context==='devis') && valueControl.value === quantityAffiche ||  ( valueControl.value == max ) "
  >
    <img
      [src]="
     ( (context === 'panier'|| context==='devis') &&
      (isDisabled || valueControl.value === quantityAffiche) || (valueControl.value == max )) ?
      'assets/icons/add-disabled.svg':
      'assets/icons/add.svg'
      "
      alt="increment"
    />
  </button>
</div>
<span class="invalid-quantity">
  Quantité dispo: {{ quantityAffiche !== undefined ? quantityAffiche : quantityP }}
</span>
<span class="invalid-quantity" *ngIf="valueControl.value > maxForQuot && !remainder">
  Quantité dispo: {{ quantityAffiche }}
</span>
