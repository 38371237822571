import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {footerInfoDto} from '../../DTO/footer-info.dto';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FooterInfoService {
  private url: string;

  constructor(    private http: HttpClient,
  ) {       this.url = 'https://api.imtech.preprod.imtech-solutions.com/api/v1/contactInformation';
  }

  public  getAllContactInformation(): Observable<any[]> {
return     this.http.get<footerInfoDto[]>(this.url);

  }

}
