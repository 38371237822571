<form [formGroup]="requestPasswordResetForm" (keydown.enter)="checkSubmit($event)">
  <div class="app-connection-dialog-container" cdkTrapFocus>

    <app-connection-dialog fxLayout="column">
      <div class="container">
        <span class="message">
          Réinitialiser le mot de passe
          <button class="closeButton" (click)="close()">
            <img src="assets/icons/close-gray.svg" alt="close"/>
          </button>
        </span>
      </div>

      <app-email-input formControlName="mail"></app-email-input>

      <span *ngIf="mailControl.touched && mailControl.hasError('pattern')" class="validation-error-message">
        Veuillez entrer une adresse mail professionnelle valide
      </span>
      <span *ngIf="mailControl.touched && mailControl.hasError('required')" class="validation-error-message">
        Ce champ est obligatoire
      </span>
      <span *ngIf="error" class="error-message">
        {{ errorMessage }}
      </span>

      <button class="app-flat-button" [disabled]="!this.requestPasswordResetForm.get('mail').value" type="submit"
              (click)="onRequestPasswordReset()">
        ENVOYEZ MOI LE LIEN
      </button>
    </app-connection-dialog>
  </div>
</form>
