import { NgModule, APP_INITIALIZER, Injector, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';
import { APP_CONFIG } from 'tekkeys-common';
import { HttpErrorInterceptor } from 'shared/http/http-error/http-error.interceptor';
import { AuthInterceptor } from 'shared/http/auth/auth.interceptor';
import { OverlayModule } from '@angular/cdk/overlay';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from 'shared/components/components.module';

import { NgxStripeModule } from 'ngx-stripe';
import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import { MatStepperModule } from '@angular/material/stepper';

import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { authReducer } from 'store/auth/auth.reducers';
import { accountReducer } from 'store/account/account.reducers';
import { companyReducer } from 'store/company/company.reducers';
import { categoriesReducer } from 'store/categories/categories.reducers';
import { cartReducer } from 'store/cart/cart.reducers';
import { favoritesReducer } from 'store/favorites/favorites.reducers';
import { collaboratorsReducer } from 'store/collaborators/collaborators.reducers';
import { addressesReducer } from 'store/addresses/addresses.reducers';
import { quotationReducer } from 'store/quotation/quotation.reducers';
import { quotationListReducer } from 'store/quotationList/quotation.reducers';
import { notificationsReducer } from 'store/notifications/notifications.reducer';
import { ordersReducer } from 'store/orders/orders.reducer';
import { discountCompanyReducer } from 'store/discount-company/discount-company.reducers';
import { accessoriesReducer } from 'store/accessories/accessories.reducer';

import { AuthEffects } from 'store/auth/auth.effects';
import { AccountEffects } from 'store/account/account.effects';
import { CompanyEffects } from 'store/company/company.effects';
import { CategoriesEffects } from 'store/categories/categories.effects';
import { CartEffects } from 'store/cart/cart.effects';
import { FavoritesEffects } from 'store/favorites/favorites.effects';
import { CollaboratorsEffects } from 'store/collaborators/collaborators.effects';
import { AddressesEffects } from 'store/addresses/addresses.effects';
import { QuotationEffects } from 'store/quotation/quotation.effects';
import { QuotationListEffects } from 'store/quotationList/quotation.effects';
import { NotificationsEffects } from 'store/notifications/notifications.effects';
import { OrdersEffects } from 'store/orders/orders.effects';
import { DiscountCompanyEffects } from 'store/discount-company/discount-company.effects';
import { AccessoriesEffects } from 'store/accessories/accessories.effects';
import { FooterInfoEffects } from 'store/footer-info/footer-info-effects.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {DEFAULT_LANGUAGE} from './utils/lang';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {NgxImageZoomModule} from 'ngx-image-zoom';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {NgxSpinnerModule} from "ngx-spinner";

export function translateLoader(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      translate.setDefaultLang(DEFAULT_LANGUAGE);
      translate.use(DEFAULT_LANGUAGE).subscribe(
        () => {
          // tslint:disable-next-line:no-console
          console.info(`Successfully initialized '${DEFAULT_LANGUAGE}' language.'`);
        }, () => {
          console.error(`Problem with '${DEFAULT_LANGUAGE}' language initialization.'`);
        }, () => {
          resolve(null);
        });
    });
  });
}

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    BrowserModule,
    MatProgressSpinnerModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    HttpClientModule,
    MatStepperModule,
    NgxSpinnerModule,
    OverlayModule,
    CarouselModule,
    NgScrollbarModule,
    NgxSliderModule,
    NgxImageZoomModule,
    RouterModule,
    FormsModule,
    FlexModule,
    FlexLayoutModule,
    MatDialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoader,
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot({
      auth: authReducer,
      account: accountReducer,
      company: companyReducer,
      categories: categoriesReducer,
      cart: cartReducer,
      favorites: favoritesReducer,
      collaborators: collaboratorsReducer,
      addresses: addressesReducer,
      quotation: quotationReducer,
      quotationList: quotationListReducer,
      notifications: notificationsReducer,
      orders: ordersReducer,
      discount_company: discountCompanyReducer,
      accessories: accessoriesReducer
    }),
    EffectsModule.forRoot([
      AuthEffects,
      FooterInfoEffects,
      AccountEffects,
      CompanyEffects,
      CategoriesEffects,
      CartEffects,
      FavoritesEffects,
      CollaboratorsEffects,
      AddressesEffects,
      QuotationEffects,
      QuotationListEffects,
      NotificationsEffects,
      OrdersEffects,
      DiscountCompanyEffects,
      AccessoriesEffects
    ]),
    ComponentsModule,
    NgxStripeModule.forRoot(environment.apiKeyStripe)
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true } },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: APP_CONFIG, useValue: environment.appConfig },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
