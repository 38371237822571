import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ListItemUi } from 'shared/Model/list-item-ui';
import {MAT_RADIO_DEFAULT_OPTIONS} from '@angular/material/radio';

@Component({
  selector: 'app-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  styleUrls: ['./radio-button-group.component.scss'],
  providers: [
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonGroupComponent),
      multi: true,
    },
  ],
})
export class RadioButtonGroupComponent implements ControlValueAccessor {
  @Input() items: ListItemUi[];
  private innerValue: string;

  get value(): string {
    return this.innerValue;
  }

  set value(v: string) {
    this.innerValue = v;
    this.onChange(v);
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: string): void {
    this.innerValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
