<!-- Start Fixed Header for home page-->
<div
  class="fixed-header"
  [ngClass]="{ 'show-home-fixed-header': showHomeFixedHeader }"
>
  <mat-toolbar class="navbar">
    <mat-toolbar-row>
      <div class="container">
        <div fxLayout="row wrap" fxLayoutAlign=" center" fxLayoutGap="8px">
          <div
            class="logo-container"
            fxFlex.xs="32"
            fxFlex.sm="20"
            fxFlex.md="15"
            fxFlex.lg="13.5"
            fxFlex.xl="13.5"
          >
            <app-navbar-logo></app-navbar-logo>
          </div>

          <div
            class="search-bar-container"
            fxFlex.sm="auto"
            fxFlex.xs="auto"
            fxFlex.md="45"
            fxFlex.lg="40"
            fxFlex.xl="40"
          >
            <app-navbar-search></app-navbar-search>
          </div>

          <div
            class="navbar-controls-container"
            fxLayout="row nowrap"
            fxLayoutAlign="flex-start center"
            fxFlex.xs="65"
            fxFlex.sm="75"
            fxFlex.md="30"
            fxFlex.lg="20"
            fxFlex.xl="20"
          >
            <app-navbar-controls
              *ngIf="showNavCrl"
              [fixedHeader]="true"
            ></app-navbar-controls>
          </div>
          <div fxHide.lt-md class="support-btn">
            <button mat-button class="" (click)="openSupportDialog()">
              {{'app.navBar.commercialService' | translate | uppercase}}
            </button>
          </div>

        </div>
      </div>
    </mat-toolbar-row>

    <mat-toolbar-row
      fxHide.lt-md
      [@floatInAnimation]="{
        value: 'void => *',
        params: { translateY: '-100', animateTime: '0.65' }
      }"
      *ngIf="showHomeFixedHeaderMenu"
    >
      <div class="container">
        <app-category-menu2></app-category-menu2>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div
  class="fixed-header"
  [ngClass]="{
    'show-product-detail-fixed-header': showProductDetailFixedHeader
  }"
>
  <mat-toolbar class="navbar">
    <!---->
    <mat-toolbar-row fxHide.gt-sm="true">
      <div class="container">
        <div fxLayout="row nowrap" fxLayoutAlign=" center" fxLayoutGap="8px">
          <button mat-icon-button (click)="openSidenav()">
            <img src="assets/icons/menu.svg" alt="Icone menu"/>
          </button>
          <div class="search-bar-container" fxFlex.sm="auto" fxFlex.xs="auto">
            <app-navbar-search></app-navbar-search>
          </div>
        </div>
      </div>
    </mat-toolbar-row>

    <!---->
    <mat-toolbar-row>
      <div
        class="container"
        fxLayout="row nowrap"
        fxLayoutAlign="space-between center"
      >
        <div class="product-info">
          <div class="product-image">
            <img style="width: 95px; height: 56px" [src]="product?.productResources[0]?.link" alt="image product-link"/>
          </div>
          <div class="product-name" title="{{ product?.designation }}">
            {{ product?.designation }}
          </div>
        </div>

        <span fxFlex=""></span>
        <div *ngIf="myBoolean$ | async" class="product-price">
          <ng-container
            *ngIf="(product?.discountEndDate | daysLeft) > 0; else nodiscount"
          >
            <span class="price">{{
              product?.price - product.discount | currency: "EUR":true
              }}</span>
            <span class="old-price">{{
              product?.price | currency: "EUR":true
              }}</span>
          </ng-container>
          <ng-template #nodiscount>
            <span class="price">{{
              product?.price | currency: "EUR":true
              }}</span>
          </ng-template>
        </div>

        <div class="product-actions" *ngIf="!mobile; else mobileDiv">
          <button
            *ngIf="product?.status === 'IN_STOCK'"
            mat-button
            class="app-raised-button add-to-cart-btn"
            [class.spinner]="loadingAddCart"
            [disabled]="loadingAddCart || this.quantityInCart === this.product.quantity"
            (click)="addToCart()"
          >
            <div style="display: flex">
              <img style="padding-right: 6px"
                src="assets/icons/shopping_cart-white.svg"
                alt="shopping cart"
              />
              <span style="margin-top: 2px" class="btn-name"> AJOUTER AU PANIER</span>
            </div>
          </button>
        <!--  <button
            *ngIf="product?.status !== 'IN_STOCK'"
            mat-button
            class="app-raised-button add-to-quotation-btn"
            [class.spinner]="loadingAddCart"
            [disabled]="product?.status === 'OBSOLETE'"
            (click)="addToQuotation()"
          >
            <div style="display: flex">
              <img
                [src]="
                'assets/icons/add-quotation_icon' +
                (product?.status === 'OBSOLETE' ? '-muted' : '-white') +
                '.svg'
              "
                alt="shopping cart"
              />
              <span class="btn-name">AJOUTER AU DEVIS</span>
            </div>
          </button>-->
          <button
            mat-button
            class="add-to-favorites-btn"
            [class.spinner]="loadingAddFavorite"
            [disabled]="loadingAddFavorite || product?.status === 'OBSOLETE'"
            (click)="addQuote()"
          >
            <div style="display:flex;">
              <img
                [src]="
                'assets/icons/add-quotation_icon' +
                (product?.status === 'OBSOLETE' ? '-muted' : '') +
                '.svg'
              "
                alt="heart"
              />
              <span style="margin-top:12px" class="btn-name">&nbsp; AJOUTER AUX DEVIS</span>
            </div>
          </button>
        </div>
      </div>
      <ng-template #mobileDiv>
        <button
          *ngIf="product?.status === 'IN_STOCK'"
          mat-icon-button
          class="add-to-cart-btn "
          style="margin-left: 15px"
          [class.spinner]="loadingAddCart"
          [disabled]="loadingAddCart || this.quantityInCart === this.product.quantity "
          (click)="addToCart()"
        >
          <img
            [src]="(loadingAddCart || this.quantityInCart === this.product.quantity)
             ? 'assets/icons/shopping_cart-muted.svg'
             : 'assets/icons/shopping_cart-red.svg'"
            alt="cart"
          />
          <span class="plus"></span>
        </button>
        <button
          mat-icon-button
          class="add-to-favorites-btn"
          [class.spinner]="loadingAddFavorite"
          [disabled]="loadingAddFavorite || product?.status === 'OBSOLETE'"
          (click)="addToFavorites()"
        >
          <img
            [src]="
              'assets/icons/file_copy' +
              (product?.status === 'OBSOLETE' ? '-muted' : '') +
              '.svg'
            "
            alt="heart"
          />
          <span class="plus"></span>
        </button>




      </ng-template>
    </mat-toolbar-row>

    <!---->
    <mat-toolbar-row
      class="switch-tab"
      *ngIf="showFullProductDetailFixedHeader && !mobile"
    >
      <div class="header-container"></div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
