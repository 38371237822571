import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ClientService, CompanyService, OrderDto, OrderService, ProductService } from 'tekkeys-common';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-order-invoice',
  templateUrl: './order-invoice.component.html',
  styleUrls: ['./order-invoice.component.scss']
})
export class OrderInvoiceComponent implements OnInit {
  order: OrderDto;
  companyName: string;
  companyNumber: string;
  companyPhone: string;
  display = true;
  isExporting = false;
  isVirtual: boolean;



  constructor(
    public dialogRef: MatDialogRef<OrderInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public orderId: number,
    private orderService: OrderService,
    private productService: ProductService,
    private clientService: ClientService,
    private companyService: CompanyService,
  ) {
  }

  ngOnInit(): void {
    this.scroll();
    this.orderService
      .getOrderById(this.orderId)
      .subscribe((order) => {
        this.order = order;
        order.items.map((orderItem) => {
          // get product
          this.productService.getById(orderItem.product.id).subscribe((p) => {
            orderItem.product = p;
          });
        });
        this.clientService.getClient(order.client.id).subscribe(
          (c) => {
            this.order.companyId = c.companyId;
            this.companyService.getById(c.companyId).subscribe(
              (cp) => {
                this.companyName = cp.name;
                this.companyPhone = cp.phone;
                this.companyNumber = cp.accountNumber;
              });
          });
      });
  }
  scroll() {
    const el = document.getElementById('heading');
    el.scrollIntoView();
  }
  downloadPDF(number){
    this.isExporting = true;
    this.orderService.downloadFacture(number).pipe(finalize(() => this.isExporting = false)).subscribe((invoice) => {
      const blob = new Blob([invoice], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      if (window.navigator?.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      link.download =
        'Facture' +
        new DatePipe('FR').transform(Date.now(), 'yyyy-MM-dd_HH-mm-ss') +
        '.pdf';
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
  }
  downloadOrderPDF(number){
    this.isExporting = true;
    this.orderService.downloadFacture(number).pipe(finalize(() => this.isExporting = false)).subscribe((invoice) => {
      const blob = new Blob([invoice], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      if (window.navigator?.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      link.download =
        'Commande' +
        new DatePipe('FR').transform(Date.now(), 'yyyy-MM-dd_HH-mm-ss') +
        '.pdf';
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
  }
}
