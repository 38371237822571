import {ProductDTO} from './product.dto';
import {EventDTO} from './event.dto';

export class PromoCodeDTO {
  id: number;
  name: string;
  startingDate: string;
  endingDate: string;
  value: number;
  updater: string;
  state: boolean;
  clientUseOneTime:	boolean;
  numberUtilisation: number;
  haveMaxUseNumber: boolean;
  minValue:	number;
  promoCodeType:	string;
  appliedTo: string;
  minValueToHave: boolean;
  event: EventDTO;
  products: ProductDTO[];
}
