<form [formGroup]="loginForm" (ngSubmit)="onLogin()" (keydown.enter)="onLogin()">
  <div class="app-connection-dialog-container" cdkTrapFocus>

    <app-connection-dialog fxLayout="column">
      <div class="container">
        <span *ngIf="!(authStore | async).error" class="message">Bonjour
          <button class="close-btn" (click)="close()">
            <img src="assets/icons/close-gray.svg" alt="close" />
          </button>
        </span>
      </div>
      <span *ngIf="(authStore | async).error" class="error-message">
        {{ (authStore | async).errorMessage }}
      </span>

      <app-email-input formControlName="mail"></app-email-input>
      <span
        *ngIf="mailControl.touched && mailControl.hasError('pattern')"
        class="validation-error-message"
      >
        Veuillez entrer une adresse mail valide
      </span>
      <span
        *ngIf="mailControl.touched && mailControl.hasError('required')"
        class="validation-error-message"
      >
        Ce champ est obligatoire
      </span>

      <app-password-input
        formControlName="password"
        visibilityToggle="true"
      ></app-password-input>
      <span
        *ngIf="passwordControl.touched && passwordControl.hasError('required')"
        class="validation-error-message"
      >
        Veuillez entrer votre mot de passe
      </span>
      <span
        *ngIf="passwordControl.touched && passwordControl.hasError('minlength')"
        class="validation-error-message"
      >
        Veuillez entrer au minimum 8 caractères
      </span>

      <a
        *ngIf="
          (authStore | async).errorMessage !==
          'Ce compte n\'est pas encore activé'
        "
        class="link"
        fxFlexAlign="end"
        (click)="goToRequestPasswordReset.emit()"
      >
        Mot de passe oublié?
      </a>

      <a
        *ngIf="loginFailureCode === 406"
        class="link"
        fxFlexAlign="end"
        (click)="openDialog()"
      >
        Activer votre compte
      </a>

      <div fxLayout fxLayoutGap="16px">
        <button
          class="app-stroked-button"
          type="button"
          (click)="goToSignup.emit()"
        >
          S’INSCRIRE
        </button>
        <button
          class="app-flat-button"
          type="submit"
          [disabled]="loginForm.invalid || (authStore | async).isLoading"
        >
          SE CONNECTER
        </button>
      </div>
    </app-connection-dialog>
  </div>
</form>
