import { Injectable, Inject } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../config/app.config';
import { CartItemDTO } from '../../DTO/cart-item.dto';
import { CartAndFavoriteItemCountDTO } from '../../DTO/cart_and_fav_item_count.dto';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private url: string;
  private url2: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/cart`;
    this.url2 = `${this.appConfig.apiEndpoint}/promo_code`;
  }

  public get(): Observable<CartItemDTO[]> {
    return this.http.get<CartItemDTO[]>(this.url);
  }

  public getTva(): Observable<any> {
    const url = `${this.url}/tva`;
    return this.http.get<any>(url);
  }

  public add(productId: number, quantity: number): Observable<CartItemDTO> {
    return this.http.post<CartItemDTO>(this.url, { productId, quantity });
  }

  public addMultiple(cartItems: CartItemDTO[]): Observable<CartItemDTO[]> {
    const url = `${this.url}/multiple`;
    return this.http.post<CartItemDTO[]>(url, cartItems);
  }

  public addFromFavorite(favoriteID: number): Observable<CartItemDTO> {
    const url = `${this.url}/${favoriteID}`;
    return this.http.post<CartItemDTO>(url, favoriteID);
  }
  public changeQuantity(
    cartItemId: number,
    quantity: number
  ): Observable<CartItemDTO> {
    return this.http.patch<CartItemDTO>(this.url, { id: cartItemId, quantity });
  }

  public remove(id: number): Observable<void> {
    const url = `${this.url}/${id}`;
    return this.http.delete<void>(url);
  }
  public  applyPromoCode(promoCodeValue: string): Observable<any>{
    const url = `${this.url2}`;
    let params = new HttpParams();
    params = params.append('promoCode', promoCodeValue);
    const options = { params };
    return this.http.get<any>(url, options);
  }

  public getCartItemAndFavoriteItemCount(): Observable<CartAndFavoriteItemCountDTO> {
    const url = `${this.url}/cartAndFavoriteItemCount`;
    const params = new HttpParams();
    const options = { params };
    return this.http.get<CartAndFavoriteItemCountDTO>(url, options);
  }

}
