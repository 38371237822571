<mat-card class="product-card" [ngClass]="{ 'list-card': cardType === 'list' }">
  <mat-card-content>
    <div class="product-image">
      <a (click)="showDialogBox(0)">
        <img [src]="image ? image : 'assets/imtech-logo.png'" alt="{{ product.name }}"
          (error)="image = null" />
      </a>
    </div>
    <div class="product-title">
      <a (click)="showDialogBox(0)">
        <h2 title="{{ product.designation }}">
          {{ product.designation }}
        </h2>
      </a>
    </div>
    <div class="product-description">
      <p>{{ product.shortDescription }}</p>
      <div class="product-description-ref" fxLayout="row nowrap" fxLayoutGap="8px">
        <span>EAN : {{ product.eancode }}</span>
        <span>FAB : {{ product.manufacturerReference }}</span>
        <span *ngIf="product.code">Imtech : {{ product.code }}</span>
        <span [ngClass]="{
            'in-stock__status': product?.status === 'IN_STOCK',
            'on-command__status': product?.status === 'ON_COMMAND',
            obsolete__status: product?.status === 'OBSOLETE',
            'out-of-stock__status': product?.status === 'OUT_OF_STOCK'
          }">
          {{ product?.status === "IN_STOCK" ? "En stock" : "" }}
          {{ product?.status === "OBSOLETE" ? "Produit obsolète" : "" }}
          {{ product?.status === "OUT_OF_STOCK" ? "Rupture de stock" : "" }}
          {{ product?.status === "ON_COMMAND" ? "Sur commande" : "" }}
        </span>
      </div>
    </div>
    <div class="product-price">
      <span class="price">{{
        product.price - product.discount | currency: "EUR":'symbol'
        }}</span>
      <span class="old-price" *ngIf="discountDaysLeft > 0">
        {{ product.price | currency: "EUR":'symbol' }}
      </span>
    </div>
  </mat-card-content>

  <!--    <mat-card-actions>-->
  <!--      show only on list-mode;  on hover-->
  <!--    </mat-card-actions>-->

  <!--  Promo Labels  -->
  <ng-container *ngIf="discountDaysLeft > 0">
    <div class="promo-label">
      <span>PROMO -{{
        (product.discount * 100) / product.price | number: "1.0-0"
        }}%</span>
    </div>
    <div class="end-promo-label">
      <span>Expire dans {{ discountDaysLeft }} jrs</span>
    </div>
  </ng-container>
</mat-card>
