import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { logout } from 'store/auth/auth.actions';
import { ClientStatus, HomepageService } from 'tekkeys-common';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router,
              private store: Store<{ client: ClientStatus }>,
              private homeService: HomepageService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      // If a request fails retry up to 2 times
      // retry(2),
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          // Local thrown error
        } else if (error.status === 0){
          // Error returned by the backend
              this.store.dispatch(logout());
              if (JSON.parse(localStorage.getItem('history'))) {
                this.homeService.addCategoryVisitHistory(JSON.parse(localStorage.getItem('history')))
                  .subscribe(() => {localStorage.removeItem('history'); } ,
                    () => {localStorage.removeItem('history'); }); }
              if (JSON.parse(localStorage.getItem('favorites'))) {localStorage.removeItem('favorites'); }
              if (JSON.parse(localStorage.getItem('quotation'))) {localStorage.removeItem('quotation'); }
              //window.location.reload();
              this.router.navigate(['/']);
        }
        // rethrowing the error to be handeled by the subscriber or the error handler
        return throwError(error);
      }),
    );
  }
}
