import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NotFoundComponent } from './not-found/not-found.component';
import { DaysLeftPipe } from 'shared/pipes/days-left/days-left.pipe';
import { RemainingTimePipe } from 'shared/pipes/remaining-time/remaining-time.pipe';
import { NavbarComponent } from './navbar/navbar.component';
import { MinimalNavbarComponent } from './minimal-navbar/minimal-navbar.component';
import { NavbarLogoComponent } from './navbar-logo/navbar-logo.component';
import { NavbarSearchComponent } from './navbar-search/navbar-search.component';
import { NavbarControlsComponent } from './navbar-controls/navbar-controls.component';
import { CategoryMenuComponent } from './category-menu/category-menu.component';
import { CategoryDropdownComponent } from './category-dropdown/category-dropdown.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { FooterComponent } from './footer/footer.component';
import { FooterInfoComponent } from './footer/footer-info/footer-info.component';
import { FooterLinksComponent } from './footer/footer-links/footer-links.component';
import { FooterPaymentComponent } from './footer/footer-payment/footer-payment.component';
import { FooterNewsletterComponent } from './footer/footer-newsletter/footer-newsletter.component';
import { CopyrightNoticeComponent } from './copyright-notice/copyright-notice.component';

import { MinimalLayoutComponent } from './minimal-layout/minimal-layout.component';
import { FullLayoutComponent } from './full-layout/full-layout.component';

import { EmailInputComponent } from './email-input/email-input.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { TextInputComponent } from './text-input/text-input.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { CheckboxInputComponent } from './checkbox-input/checkbox-input.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { TextAreaInputComponent } from './text-area-input/text-area-input.component';

import { ConnectionDialogComponent } from './connection-dialog/connection-dialog.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { RequestPasswordResetComponent } from './request-password-reset/request-password-reset.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';

import { ProductCardComponent } from './product-card/product-card.component';
import { RadioButtonGroupComponent } from './radio-button-group/radio-button-group.component';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { A11yModule } from '@angular/cdk/a11y';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatRippleModule } from '@angular/material/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { FixedHeaderComponent } from './fixed-header/fixed-header.component';
import { TitleComponent } from 'shared/components/title/title.component';
import { ProductCardShimmerComponent } from './product-card-shimmer/product-card-shimmer.component';
import { LogoutDropDownMenuComponent } from './logout-drop-down-menu/logout-drop-down-menu.component';
import { SupportDialogComponent } from './support-dialog/support-dialog.component';
import { NewQuotationDialogComponent } from './new-quotation-dialog/new-quotation-dialog.component';
import { NewQuoteDialogItemComponent } from './new-quotation-dialog-item/new-quote-dialog-item.component';
import { CategoryMenu2Component } from './category-menu2/category-menu2.component';
import { AddressTransPipe } from 'shared/pipes/address-trans/address-trans.pipe';
import { FooterSocialLinksComponent } from './footer/footer-social-links/footer-social-links.component';
import { NotificationsDropDownMenuComponent } from './notifications-drop-down-menu/notifications-drop-down-menu.component';
import { NotificationsDialogComponent } from './notifications-dialog/notifications-dialog.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ResendConfirmationMailComponent } from './resend-confirmation-mail/resend-confirmation-mail.component';
import { RequestPasswordResetPopupComponent } from './request-password-reset-popup/request-password-reset-popup.component';
import { ProductAccessoryCardComponent } from './product-accessory-card/product-accessory-card.component';
import { CustomProductWindowComponent } from './custom-product-window/custom-product-window.component';
import { WindowProductCarouselComponent } from './window-product-carousel/window-product-carousel.component';
import { QuotationSuccessComponent } from './quotation-success/quotation-success.component';
import {
  NewQuotationDialogFromQuantityComponent
} from 'shared/components/quotation-dialog-from-quantity/new-quotation-dialog-from-quantity.component';
import {TranslateModule} from '@ngx-translate/core';
import { SnackbarDialogComponent } from './product-card/snackbar-dialog/snackbar-dialog.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatRadioModule} from '@angular/material/radio';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatMenuModule} from '@angular/material/menu';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgScrollbarModule, NG_SCROLLBAR_OPTIONS} from 'ngx-scrollbar';

// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {};

@NgModule({
  imports: [

    CommonModule,
    RouterModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    MatSidenavModule,
    MatRadioModule,
    MatAutocompleteModule,
    A11yModule,
    MatToolbarModule,
    MatButtonModule,
    MatRippleModule,
    MatBadgeModule,
    MatDividerModule,
    MatCardModule,
    MatDialogModule,
    MatProgressBarModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    NgScrollbarModule
  ],
  declarations: [
    NotFoundComponent,
    DaysLeftPipe,
    RemainingTimePipe,
    NavbarComponent,
    MinimalNavbarComponent,
    NavbarLogoComponent,
    NavbarSearchComponent,
    NavbarControlsComponent,
    CategoryMenuComponent,
    CategoryDropdownComponent,
    SidenavComponent,
    FooterComponent,
    FooterInfoComponent,
    FooterLinksComponent,
    FooterPaymentComponent,
    FooterNewsletterComponent,
    CopyrightNoticeComponent,
    MinimalLayoutComponent,
    FullLayoutComponent,
    EmailInputComponent,
    PhoneInputComponent,
    PasswordInputComponent,
    TextInputComponent,
    SelectInputComponent,
    CheckboxInputComponent,
    NumberInputComponent,
    ConnectionDialogComponent,
    LoginComponent,
    SignupComponent,
    RequestPasswordResetComponent,
    PasswordResetComponent,
    ProductCardComponent,
    TextAreaInputComponent,
    RadioButtonGroupComponent,
    SnackbarComponent,
    FixedHeaderComponent,
    TitleComponent,
    ProductCardShimmerComponent,
    LogoutDropDownMenuComponent,
    SupportDialogComponent,
    NewQuotationDialogComponent,
    NewQuoteDialogItemComponent,
    CategoryMenu2Component,
    AddressTransPipe,
    FooterSocialLinksComponent,
    NotificationsDropDownMenuComponent,
    NotificationsDialogComponent,
    NotificationsComponent,
    ResendConfirmationMailComponent,
    RequestPasswordResetPopupComponent,
    ProductAccessoryCardComponent,
    CustomProductWindowComponent,
    WindowProductCarouselComponent,
    QuotationSuccessComponent,
    NewQuotationDialogFromQuantityComponent,
    SnackbarDialogComponent
  ],
  exports: [
    ReactiveFormsModule,
    DaysLeftPipe,
    RemainingTimePipe,
    MinimalLayoutComponent,
    FullLayoutComponent,
    EmailInputComponent,
    PhoneInputComponent,
    PasswordInputComponent,
    TextInputComponent,
    SelectInputComponent,
    CheckboxInputComponent,
    NumberInputComponent,
    LoginComponent,
    SignupComponent,
    RequestPasswordResetComponent,
    PasswordResetComponent,
    ProductCardComponent,
    TextAreaInputComponent,
    RadioButtonGroupComponent,
    FixedHeaderComponent,
    TitleComponent,
    ProductCardShimmerComponent,
    FooterComponent,
    NavbarComponent,
    CopyrightNoticeComponent,
    AddressTransPipe,
    ConnectionDialogComponent,
    ProductAccessoryCardComponent,
    NewQuotationDialogFromQuantityComponent
  ],
  providers: [
    {
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: {
        scrollAuditTime: 20,
      },
    },
  ],
})
export class ComponentsModule { }
