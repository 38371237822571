import { Action, createReducer, on } from '@ngrx/store';

import { loadNotificationsSuccess, pushNotification, seenNotificationSuccess } from './notifications.actions';
import { notificationsAdapter, NotificationsState } from './notifications.state';

export const initialState: NotificationsState = notificationsAdapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(loadNotificationsSuccess, (state, action) => {
    if (action.notifications) {
    return notificationsAdapter.setAll(action.notifications, state);
    }
  }),
  on(pushNotification, (state, action) => {
    return notificationsAdapter.setOne(action.notification, state);
  }),
  on(seenNotificationSuccess, (state, action) => {
    return notificationsAdapter.updateOne(action.updatedNotification, state);
  })
);

export function notificationsReducer(
  state: NotificationsState,
  action: Action
) {
  return reducer(state, action);
}
