import { createAction, props } from '@ngrx/store'
import { DiscountCompanyDTO } from 'tekkeys-common';

export const loadDiscountCompany = createAction(
  '[Discount Company  Page] Load Company Discount',
  props<{companyId: number}>()
  );

export const loadDiscountCompanySuccess = createAction(
  '[Discount Company  API] Load Company Discount Success',
  props<{ discountCompany: DiscountCompanyDTO }>()
);

export const loadDiscountCompanyFailure = createAction(
  '[Discount Company  API] Load Company Discount Failure',
  props<{ error: any }>()
);


export const clearDiscountCompany = createAction(
  '[Discount Company  PAGE] Clear Company Discount'
);
