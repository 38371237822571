import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, switchMapTo } from 'rxjs/operators';
import { SnackbarService } from 'shared/services/snackbar/snackbar.service';
import { loginSuccess, logout } from 'store/auth/auth.actions';
import {
  addFromFavoriteToQuotation, addToQuotationCartItem, addToQuotationCartSuccess,
  addToQuotationItem,
  addToQuotationSuccess,
  clearQuotation,
  loadQuotation,
  loadQuotationSuccess,
  removeQuotationItem,
  removeQuotationItemSuccess,
  updateItem,
  updateItemSuccess,
} from 'store/quotation/quotation.actions';
import { QuotationItemDto, QuotationService } from 'tekkeys-common';

@Injectable()
export class QuotationEffects {
  init = createEffect(() =>
    this.actions.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMapTo(of(loadQuotation()))
    )
  );

  loadQuotation = createEffect(() =>
    this.actions.pipe(
      ofType(loadQuotation),
      switchMap(() =>
        this.quotationService.getCurrent().pipe(
          map((response) =>
            loadQuotationSuccess({ quotation: response.items })
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  loginSuccess = createEffect(() =>
    this.actions.pipe(ofType(loginSuccess), switchMapTo(of(loadQuotation())))
  );

  logout = createEffect(() =>
    this.actions.pipe(ofType(logout), switchMapTo(of(clearQuotation())))
  );

  addToQuotation = createEffect(() =>
    this.actions.pipe(
      ofType(addToQuotationItem),
      switchMap((action) =>
        this.quotationService
          .add(action.productId, action.quantity, action.price)
          .pipe(
            map((response) => addToQuotationSuccess({ quotation: response })),
            catchError((e) => {
              if (e.status === 409) {
                this.snackbarService.openSnackBar(
                  'Une erreur s\'est produite',
                  'information-snackbar'
                );
              }
              return EMPTY;
            })
          )
      )
    )
  );
  addToQuotationCart = createEffect(() =>
    this.actions.pipe(
      ofType(addToQuotationCartItem),
      switchMap((action) =>
        this.quotationService
          .addCartItems(action.cartQuotation)
          .pipe(
            map((response) => addToQuotationCartSuccess({quotationCart : response}) ),
            catchError((e) => {
                this.snackbarService.openSnackBar(
                  'Une erreur s\'est produite',
                  'information-snackbar'
                );
                return EMPTY;
            })
          )
      )
    )
  );

  setQuantity = createEffect(() =>
    this.actions.pipe(
      ofType(updateItem),
      switchMap((action) =>
        this.quotationService.updateItem(action.quotationItem).pipe(
          map((response) => {
            const update: Update<QuotationItemDto> = {
              id: response.id,
              changes: response,
            };
            return updateItemSuccess({ update });
          }),
          catchError((e) => {
            this.snackbarService.openSnackBar(
              'Une erreur s\'est produite',
              'error-snackbar'
            );
            return EMPTY;
          })
        )
      )
    )
  );

  deleteQuotationItem = createEffect(() =>
    this.actions.pipe(
      ofType(removeQuotationItem),
      switchMap((action) =>
        this.quotationService.remove(action.id).pipe(
          map((response) => removeQuotationItemSuccess({ id: action.id })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  addFromFavoriteToQuotation = createEffect(() =>
    this.actions.pipe(
      ofType(addFromFavoriteToQuotation),
      switchMap((action) =>
        this.quotationService.add(action.productId, 1, action.price).pipe(
          map((response) => addToQuotationSuccess({ quotation: response })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private quotationService: QuotationService,
    private snackbarService: SnackbarService,
    private actions: Actions
  ) {}
}
