<div [ngClass]="isDefault ? 'dialog' : 'mini_dialog'">
  <div class="dialog-titlebar">
    <span> ENVOYER LE DEVIS</span>
    <button mat-icon-button (click)="close()">
      <img src="assets/icons/close.svg" alt="close" />
    </button>
  </div>

  <div class="dialog-content">
    <div class="note" fxLayout="row wrap">
      <span class="text">
        Veuillez décrire votre besoin : désignation, Références, quantité
        demandée ou prix souhaité
      </span>
      <app-text-area-input
        [formControl]="message"
        row="5"
        label="Message"
      ></app-text-area-input>
    </div>

    <div *ngIf="!isVirtual" #address>
      <div [ngClass]="isDefault ? 'address blink_me' : 'mini_address blink_me'"  class="" fxLayout="column" fxLayoutGap="32px">
        <div class="text" fxLayout fxLayoutGap="16px">
          <span fxFlex="70">Adresse</span>
        </div>

        <mat-form-field appearance="outline" class="setPadding">
          <mat-select
            [(value)]="selectedAddress"
            (selectionChange)="initForm()"
            placeholder="Séléctionner une adresse"
          >
            <mat-option
              *ngFor="let address of clientAddresses"
              [value]="address"
              >{{
                address.addressTitle
                  ? address.addressTitle
                  : (address.contactName | slice: 0:7) +
                    ".." +
                    address.streetName
              }}</mat-option
            >
            <mat-option
              (click)="onAddAddressDialog()"
              fxLayout
              fxLayoutAlign="flex-start center"
            >
              <span>Ajouter une nouvelle adresse</span><span fxFlex></span
              ><img width="20" src="assets/icons/add-grey.svg" alt="Icone add-grey"/>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <form
          [formGroup]="addAddressForm"
          (ngSubmit)="next()"
          *ngIf="selectedAddress"
          style="padding-top: 0 !important;"
        >
          <div fxLayout="column">
            <app-text-input
              [readOnly]="true"
              class=""
              formControlName="contactName"
              label="Société ou Nom et Prénom"
            ></app-text-input>
            <app-phone-input
              [readOnly]="true"
              formControlName="contactPhone"
              label="Téléphone"
            ></app-phone-input>
            <app-text-input
              [readOnly]="true"
              class=""
              formControlName="streetName"
              label="Adresse"
            ></app-text-input>

            <div fxLayout fxLayoutGap="16px">
              <app-text-input
                [readOnly]="true"
                [disabled]="true"
                class=""
                formControlName="city"
                label="Ville"
                fxFlex=""
              ></app-text-input>
              <app-text-input
                [readOnly]="true"
                class=""
                formControlName="postalCode"
                label="Code postal"
                fxFlex=""
              ></app-text-input>
            </div>

            <div fxLayout fxLayoutGap="16px">
              <app-text-input
                [readOnly]="true"
                class=""
                formControlName="cedex"
                label="Cedex"
                fxFlex=""
              ></app-text-input>
              <app-text-input
                [readOnly]="true"
                class=""
                formControlName="country"
                label="Pays"
                fxFlex=""
                [matAutocomplete]="country"
                [postIcon]="'assets/icons/arrow_drop_down.svg'"
              ></app-text-input>
              <mat-autocomplete #country="matAutocomplete">
                <mat-option
                  *ngFor="let country of countries$ | async"
                  [value]="country.name"
                >
                  {{ country.name }}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>
          <br />
        </form>
      </div>
    </div>

    <div gdArea="controls" fxLayout fxLayoutGap="16px" fxLayoutGap.xs="8px" style="margin-top: 10px">
      <button mat-button class="strocked-btn" (click)="close()" fxFlex="">
        ANNULER
      </button>

      <button
        *ngIf="!isVirtual"
        mat-button
        class="next-btn"
        (click)="next() ;"
        fxFlex=""
        [ngClass]="{
          'btn-disabled':
            message.value === '' ||
            ( addAddressForm.invalid) ||
            !isCountry
        }"
      >
       ENVOYER
      </button>
      <button
        *ngIf="isVirtual"
        mat-button
        class="next-btn"
        (click)="next()"
        fxFlex=""
        [ngClass]="{
          'btn-disabled':
            message.value === ''
        }"
      >
        ENVOYER
      </button>
    </div>
  </div>
</div>
