import {Component, forwardRef, Input} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true,
    },
  ],
})
export class PhoneInputComponent implements ControlValueAccessor {
  private innerValue: string;
  @Input() readOnly = false;
  get value(): string {
    return this.innerValue;
  }

  set value(v: string) {
    if (!this.readOnly){
      this.innerValue = v;
      this.onChange(v);
    }
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: string): void {
    this.innerValue = value;
    this.innerValue = value !=='false'? value : '';

  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  onlyNumberKey(event){
    const charCode = (event.query) ? event.query : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57) &&
      charCode !== 43);
  }
}
