import { createAction, props } from '@ngrx/store';

import { CategoryDTO } from 'tekkeys-common';

export const loadCategories = createAction('[Categories] Load Categories');

export const loadCategoriesSuccess = createAction(
  '[Categories] Load Categories Success',
  props<{ categories: CategoryDTO[] }>()
);

export const loadCategoriesFailure = createAction(
  '[Categories] Load Categories Failure',
  props<{ errorCode: number }>()
);
