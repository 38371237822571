import {Component} from '@angular/core';
import {SidenavService} from 'shared/services/sidenav/sidenav.service';
import {OverlayService} from 'shared/services/overlay/overlay.service';
import {ComponentPortal} from '@angular/cdk/portal';
import {SupportDialogComponent} from 'shared/components/support-dialog/support-dialog.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  languages: Language[] = [{key: 'fr', label: 'FR'}, {key: 'en', label: 'EN'}];

  constructor(
    private sidenavService: SidenavService,
    private overlayService: OverlayService,
    public readonly translateService: TranslateService
  ) {
    const browserLang = translateService.getBrowserLang();
    const match = /en|fr/.exec(browserLang);
    translateService.use(match ? browserLang : 'en');

  }

  openSidenav() {
    this.sidenavService.openSidenav();
  }

  openSupportDialog() {
    const supportDialog = new ComponentPortal(SupportDialogComponent);
    this.overlayService.openDialog(supportDialog);
  }
}
