import {AfterViewInit, Component, Inject, Injectable, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
  ClientDTO,
  ClientRole,
  ClientService,
  CompanyDTO,
  CompanyService,
  PageableDTO,
  CountryService,
  CountryDTO,
} from 'tekkeys-common';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccountState } from 'store/account/account.state';

import {
  auditTime,
  distinctUntilChanged,
  map,
  pluck,
  shareReplay,
  startWith,
  switchMap,
  take,
} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { RequestSentDialogComponent } from '../request-sent-dialog/request-sent-dialog.component';
import { OverlayService } from 'shared/services/overlay/overlay.service';
import { SnackbarService } from 'shared/services/snackbar/snackbar.service';
import { loadCompany } from 'store/company/company.actions';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {LoginOverlayService} from "shared/services/overlay/login-overlay.service";

@Component({
  selector: 'app-complete-registration',
  templateUrl: './complete-registration.component.html',
  styleUrls: ['./complete-registration.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
  }],
})
//@Injectable()

export class CompleteRegistrationComponent implements OnInit, AfterViewInit {
  step = 1;
  currentPage: 'confirm' | 'company';
  isPopupOpen = true;
  @Input() msg = '';
  @Input() error = false;
  @Input() steps = 1;
  @Input() isVirtual;
  client: ClientDTO;
  selectedCountry = 'France';
  companyForm: UntypedFormGroup;
  company: CompanyDTO;
  companyList: CompanyDTO[]; // suggested companies
  fileList: File[] = [];
  listOfFiles: any;
  loading = false;
  file: File;
  situation: any;
  selectName: string ;
  selectName1: string ;
  selectName2: string ;
  isLinear = false;
  uploadedFiles: File[] = [];
  countries: CountryDTO[];
  countries$: Observable<CountryDTO[]> = this.countryService
    .countries()
    .pipe(take(1));
  isCountry = true;
  isCreated: boolean;
  isFrance: boolean;
 // private PHONE_REGEX = /^(0|\+33|0033)[1-9](\s*?[0-9]\s*?){8}$/;
  companyOptions: any [] ;
  situationControl = new UntypedFormControl('');
  images: File[] = [];
  imageSources = [];
  @ViewChild('stepper') stepper: MatStepper;
  constructor(
    private clientService: ClientService,
   // public  dialogRef: MatDialog,
    private snackBar: MatSnackBar,
    @Optional() public dialogRef: MatDialogRef<CompleteRegistrationComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private companyService: CompanyService,
    private countryService: CountryService,
    public  overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private accountStore: Store<{ account: AccountState }>,
    private store: Store,
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private loginOverlayService: LoginOverlayService,
  ) {    dialogRef.disableClose = true;}

  ngOnInit(): void {
    switch (this.router.url.split('?')[0]) {
      case '/account/confirm':
        this.currentPage = 'confirm';
        break;
      case '/profile/company':
        this.currentPage = 'company';
        break;
    }


    this.countries$.subscribe((countries) => {
      this.countries = countries;
      this.countries$ = this.companyForm
        .get('billing_address')
        .get('country')
        .valueChanges.pipe(
          startWith(''),
          map((value) => {
            this.isCountry = !!this.countries.find(
              (country) =>
                country.name ===
                this.companyForm.get('billing_address').get('country').value
            );
            return this.countries.filter((country) => {
              return (
                country.name.toLowerCase().includes(value.toLowerCase()) &&
                country.name !== value
              );
            });
          })
        );
    });

    this.accountStore
      .select('account')
      .pipe(shareReplay(), pluck('account'))
      .subscribe((client) => (this.client = client));
    this.companyService.getLegalSituation().subscribe(
      (data: any) => {
        this.companyOptions = data;
      },
    );
    this.companyForm = this.fb.group({
      name: ['', Validators.compose([Validators.required])],
      company: this.fb.group({
          fileUpload1: new UntypedFormControl('', Validators.required),
          fileUpload2: new UntypedFormControl('', Validators.required),
          fileUpload: new UntypedFormControl('', Validators.required),
        siret: [
          '', Validators.required,
          Validators.compose([
            Validators.minLength(14),
            Validators.maxLength(14),
          ]),
        ],
      legalSituations: new UntypedFormControl ('', Validators.compose([Validators.required])),

        siren: [
          '',
          Validators.required,
          Validators.compose([
            Validators.minLength(9),
            Validators.maxLength(9),
          ]),
        ],
        tva: new UntypedFormControl('', Validators.compose([Validators.required, Validators.pattern('^[A-Z]{2,3}[a-zA-Z0-9]*$'),

          ])),
        naf: [
          '',
          Validators.compose([
            Validators.minLength(6),
            Validators.maxLength(6),
            Validators.pattern('((?=(.*\\d){4})[a-zA-Z0-9]{5,})'),
          ]),
        ],
        phone: [
          '',
          Validators.required,
          Validators.compose([
            Validators.pattern(/^\+/)
          ]),
        ],
      }),
     billing_address: this.fb.group({
        addressLine1: ['', Validators.compose([Validators.required])],
        addressLine2: ['', Validators.compose([])],
        city: [
          '',
          Validators.compose([
            Validators.required,
           // Validators.pattern('[a-zA-ZÀ-ÿ-. ]*$'),
            Validators.pattern('[a-zA-ZÀ-ÿ\'-. ]*$')

          ]),
        ],
        zipCode: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$'),
          ]),
        ],
        cedex: ['', Validators.compose([Validators.pattern('^[0-9]{2}$')])],
        country: new UntypedFormControl ('France', Validators.compose([Validators.required])),
      }),
    });
    this.markAllFieldsAsTouched(); }

  markAllFieldsAsTouched() {
    this.companyForm.get('company.tva').markAllAsTouched();
    this.companyForm.get('company.phone').markAllAsTouched();
    this.companyForm.get('company.siren').markAllAsTouched();
    this.companyForm.get('company.fileUpload1').markAllAsTouched();
    this.companyForm.get('company.fileUpload2').markAllAsTouched();
    this.companyForm.get('company.fileUpload').markAllAsTouched();
    this.companyForm.get('company.siret').markAllAsTouched();
    this.companyForm.get('billing_address.addressLine1').markAllAsTouched();
    this.companyForm.get('billing_address.city').markAllAsTouched();
    this.companyForm.get('billing_address.zipCode').markAllAsTouched();

  }
  displayFn(value: any) {
    return value ? `${value.description} (${value.code})` : undefined;
  }

  conditionToShowStep(): boolean {
    return true;
  }
  goBack(toStep: number) {
    if (toStep < this.steps) {
      this.router.navigate([], { queryParams: { step: toStep } });
    }
  }

  onCountrySelected(event: any) {
    this.selectedCountry = event.option.value;
  }
  ngAfterViewInit() {
    if (this.step === 1) {
      this.companyForm
        .get('name')
        .valueChanges.pipe(
          auditTime(300),
          distinctUntilChanged(),
          switchMap((query: string) => {
            if (query !== '') {
              this.msg = '';
              return this.companyService.getCompaniesGuichet(query);
            } else {
              return of([]);
            }
          })
        )
        .subscribe(
          (response: PageableDTO<CompanyDTO>) =>
            (this.companyList = response.content)
        );
    }
    this.stepper._getIndicatorType = () => 'number';
  }


  onContinue() {
    this.isCreated = false;
    if (!this.company) {
     this.step = 2;
    } else {
      this.msg = '';
      this.error = false;
      this.companyService.getAccountManager(this.company?.id).subscribe(
        (manager) => {
          if (this.currentPage === 'confirm') {
            this.companyService
              .joinRequest(
                this.company?.id,
                this.route.snapshot.queryParams.token
              )
              .subscribe((value) => {
                this.showDialogRequestSent(manager.mail, this.company.name);
              });
          } else {
            this.companyService
              .joinRequest(
                this.company?.id)
              .subscribe((value) => {
                this.showDialogRequestSent(manager.mail,  this.company.name);
              });
          }
        },
        () =>
          this.snackbarService.openSnackBar(
            'Une erreur est survenue !',
            'error-snackbar'
          ),
      );
      this.clientService.refreshToken();
     // this.openRegistrationDialog();

    }}

  selectCompany(company: CompanyDTO) {
    this.company = company;
  }
  onFileChange(event: any, fileNumber: number) {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      if (!this.images.map(({ name }) => name).includes(file.name)) {
        this.images.push(file);
      }
      // Vérifier si le fichier est de type PDF
      if (file.type === 'application/pdf') {
        const fileName = file.name;
        switch (fileNumber) {
          case 1:
            this.selectName = fileName;
            break;
          case 2:
            this.selectName1 = fileName;
            break;
          case 3:
            this.selectName2 = fileName;
            break;
          default:
            break;
        }
      } else {
        alert('Veuillez sélectionner un fichier PDF.');
        event.target.value = '';
      }
      for (const image of this.images) {
        const reader = new FileReader();

        reader.onload = () => {
          if (!this.imageSources.includes(reader.result)) {
            this.imageSources.push(reader.result);
          }
          this.companyForm.patchValue({
            fileSource: this.imageSources,
          });
        };
        reader.readAsDataURL(image);
      }
    }
  }

  getSituationCode(){
    this.situation = this.companyForm.get('company.legalSituations').value;
  }
  createNewCompany() {
    this.isCreated = true;
    this.loading = true;
    const filesArray = this.images;

    const company: CompanyDTO = {
      name: this.companyForm.get('name').value,
      accountNumber: 'null',
      siret: this.companyForm.get('company.siret').value,
      phone: this.companyForm.get('company.phone').value,
      siren: this.companyForm.get('company.siren').value,
      tvanumber: this.companyForm.get('company.tva').value,
      nafcode: this.companyForm.get('company.naf').value,
      legalSituations: this.situation.code,
      globalTurnover: 'null',
      commercials: [],
      addresses: [
        {
          city: this.companyForm.get('billing_address.city').value,
          country: this.countries.find(
            (c) =>
              c.name === this.companyForm.get('billing_address.country').value
          ),
          streetName: this.companyForm.get('billing_address.addressLine1')
            .value,
          additionalInfo: this.companyForm.get('billing_address.addressLine2')
            .value,
          postalCode: this.companyForm.get('billing_address.zipCode').value,
        cedex: this.companyForm.get('billing_address.cedex').value,
          type: 'billingAddress',
          code: '',
        },
      ],
      globalDiscount: 0,
      outstanding: null,
      status: 'prospect',
    };

    this.companyService
      .addCompanyRequest(company, filesArray ).subscribe(
        (response) => {
          this.showDialogRequestSent(null , company.name  );
          this.loading = false;
          this.close();
          if (this.currentPage === 'confirm') {
            this.clientService
              .updateClient(
                {
                  ...this.client,
                  companyId: response.id,
                  role: ClientRole.ROLE_OWNER,
                },
                this.route.snapshot.queryParams.token
              ).subscribe(() => this.store.dispatch(loadCompany()));
            this.router.navigate(['profile/company']);
            this.dialogRef.close();

          } else {
            this.clientService.getClient(this.client.id)
              .subscribe(() => {
                this.store.next(loadCompany()); }
              );
            this.dialogRef.close();
          }
          },
        (error) => {
          if (error.status === 409){
            this.loading = false;
            const message = 'Une\n' +
              'entreprise avec le numéro de TVA'  + company.tvanumber + ' existe déjà, ' +
              'veuillez vérifier votre TVA ou rejoindre une entreprise qui possède ce numéro';
            this.snackBar.open(
              message ,
              '',
              {
              duration: 8000,
              panelClass: ['my-snack-bar'],
            });
          }
          else {
          this.snackbarService.openSnackBar(
            'Une erreur est survenue !',
            'error-snackbar'
          );
            console.error('Erreur détectée : ', error);

          }}
      );
    this.clientService.refreshToken()
  }
  showDialogRequestSent(email: string, company: string) {
    this.dialog.open(RequestSentDialogComponent, {
      data: {email, company , isCreated: this.isCreated},
      disableClose: true
    });
  }
  downloadFormulairePdf(lang: string) {
    this.companyService.downloadFormulaire(lang).subscribe((response: any) => {
      const blob = new Blob([response ], {type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      if (window.navigator?.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      link.download =
        'formulaire.pdf';
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }, );
  }
  goToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
  close() {
    this.isPopupOpen = !this.isPopupOpen;

    this.loginOverlayService.closeLogin();
  }
  openRegistrationDialog(): void {
    this.dialogRef = this.dialog.open(CompleteRegistrationComponent, {
      disableClose: true, // This ensures that clicking outside the dialog won't close it
      hasBackdrop: true,  // This adds a backdrop behind the dialog

    });
  }

}
