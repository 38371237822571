import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../config/app.config';
import { DiscountCompanyDTO } from '../../DTO/discount-company.dto';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DiscountCompanyService {
  private url: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/discount_company`;
  }

  getDiscountByCompanyId(companyId: number): Observable<DiscountCompanyDTO | {} > {
    const url = `${this.url}/${companyId}`;
    return this.http.get<DiscountCompanyDTO>(url);
  }

}
