import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { LocalCartService } from 'shared/services/localCart.service';
import { LocalFavoritesService } from 'shared/services/localFavorites.service';
import { LocalQuotationService } from 'shared/services/localQuotations.service';
import { loadAccount } from 'store/account/account.actions';
import { login, loginFailure, loginSuccess, logout, signIn, signInFailure, signInSuccess } from 'store/auth/auth.actions';
import { ClientService } from 'tekkeys-common';


@Injectable()
export class AuthEffects {
  login = createEffect(() =>
    this.actions.pipe(
      ofType(login),
      switchMap((action) =>
        this.clientService.login(action.mail.toLowerCase(), action.password).pipe(
          map((response) => loginSuccess({AuthTokenDTO: response, mail: action.mail})
            ),
          catchError((error: HttpErrorResponse) =>
            of(
              loginFailure({
                errorCode: error.status,
                remainingTime: error.error.remainingTime,
                remainingAttempts: error.error.remainingAttempts,
              })
            )
          )
        )
      )
    )
  );

  loginSuccess = createEffect(
    () =>
      this.actions.pipe(
        ofType(loginSuccess),
        tap((action) => {
          localStorage.setItem('authToken', action.AuthTokenDTO.token);
          this.store.dispatch(loadAccount());
        }),
      ),
    {dispatch: false}
  );

  signIn = createEffect(() =>
    this.actions.pipe(
      ofType(signIn),
      switchMap((action) =>
        this.clientService.signIn(action.registrationToken).pipe(
          map((response) => signInSuccess({AuthTokenDTO: response})),
          catchError((error: HttpErrorResponse) =>
            of(
              signInFailure({
                errorCode: error.status,
              })
            )
          )
        )
      )
    )
  );

  signInSuccess = createEffect(
    () =>
      this.actions.pipe(
        ofType(signInSuccess),
        tap((action) => {
          localStorage.setItem('authToken', action.AuthTokenDTO.token);
          this.store.dispatch(loadAccount());
        }),
        tap((action) => {
          this.localCartService.addToCart();
          this.localQuotationService.addToQuotations();
          this.localFavoritesService.addToFavorites();
        })
      ),
    {dispatch: false}
  );

  logout = createEffect(
    () =>
      this.actions.pipe(
        ofType(logout),
        tap(() => {
            localStorage.removeItem('authToken');
            this.localCartService.clearCart();
            this.localQuotationService.clearQuotations();
          }
        )
      ),
    {dispatch: false}
  );

  constructor(
    private store: Store,
    private clientService: ClientService,
    private actions: Actions,
    private localCartService: LocalCartService,
    private localQuotationService: LocalQuotationService,
    private localFavoritesService: LocalFavoritesService
  ) {
  }
}
