import { Action, createReducer, on } from '@ngrx/store';
import {getMyListQuotationSuccess} from 'store/quotationList/quotation.actions';
import { quotationListAdapter, QuotationListState } from 'store/quotationList/quotation.state';
export const initialState: QuotationListState = quotationListAdapter.getInitialState({
  listLoading: false,
  totalElements: 0,
  totalPages: 0,
});

const reducer = createReducer(
  initialState,
// get list quotation
  on(getMyListQuotationSuccess, (state, action) => {
    return quotationListAdapter.setAll(action.quotation, {
      ...state,
      listLoading: false,
      totalElements: action.totalElements,
      totalPages: action.totalPages
    });
  }),
);

export function quotationListReducer(state: QuotationListState, action: Action) {
  return reducer(state, action);
}
