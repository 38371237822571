<mat-card
  class="notification-card"
  [ngClass]="{ active: notification.seen }"
  *ngFor="let notification of notificationsStore$ | async"
>
  <mat-card-content (click)="seenNotification(notification)">
    <div fxLayout="row" fxLayoutAlign="none none">
      <div
        [classList]="
          notification.objectAction !== 'DEFAULT'
            ? ('notification-side status--' + notification.objectAction
              | lowercase)
            : 'notification-side'
        "
      ></div>
      <div class="notification-icon">
        <img
          [src]="
            'assets/icons/' +
              notification.objectType +
              (notification.objectAction !== 'DEFAULT'
                ? '-' + notification.objectAction
                : '') +
              '.svg' | lowercase
          "
          alt=""
        />
      </div>
      <div class="notification-description">
        <h2 [innerHTML]="getNotificationTitle(notification)"></h2>
        <p>{{ getNotificationSubTitle(notification) }}</p>

        <div class="notification-images" *ngIf="notification.files">
          <div
            fxLayout="row nowrap"
            fxLayoutAlign="space-between stretch"
            *ngFor="let image of notification.files.split('#')"
          >
            <div
              class="image-container"
              fxLayout="row nowrap"
              fxLayoutAlign="center center"
            >
              <img [src]="image" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="notification-footer">
      <div fxLayout="row nowrap" fxLayoutAlign="end center">
        <span> {{ notification.sentAt | date: "HH:mm" }} </span>
      </div>
    </div>
  </mat-card-content>
</mat-card>
