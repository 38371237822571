import { createAction, props } from '@ngrx/store';
import {footerInfoDto} from '../../../../projects/tekkeys-common/src/lib/DTO/footer-info.dto';

export const GetFooterInfo = createAction('[footerInfo] Load footer');

export const loadFooterInfoSuccess = createAction(
  '[footerInfo] Load footerInfo Success',
  props<{ footerInfo: footerInfoDto[] }>()
);

export const loadFooterInfoFailure = createAction(
  '[footerInfo] Load footer Failure',
  props<{ errorCode: number }>()
);


