import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../config/app.config';
import { CarouselItemDTO } from '../../DTO/carousel-item.dto';

@Injectable({
  providedIn: 'root',
})
export class CarouselService {
  private url: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/carousel`;
  }

  public getAll(): Observable<CarouselItemDTO[]> {
    return this.http.get<CarouselItemDTO[]>(this.url);
  }

  public getActivateCarousels(): Observable<CarouselItemDTO[]> {
    const url = `${this.url}/activateCarousel`;
    return this.http.get<CarouselItemDTO[]>(url);
  }
}
