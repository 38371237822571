<div
  fxLayout="column"
  fxLayoutAlign="start start"
  fxLayoutAlign.lt-md="start center"
>

  <img
    src="../../../../../assets/imtech-new-logo.svg"
    src.lt-md="../../../../../assets/imtech-new-logo.svg "
    class="logo-f"
    alt="logo tekkeys"
    routerLink="/"
  />
  <ul>

    <div *ngFor="let item of contactInformation">

      <p class="description">

      {{ item.description }}
      </p>
    </div>
  </ul>
  <app-footer-social-links fxHide.gt-md></app-footer-social-links>
</div>
