import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, switchMapTo } from 'rxjs/operators';
import { CategoryService } from 'tekkeys-common';

import { loadCategories, loadCategoriesFailure, loadCategoriesSuccess } from './categories.actions';

@Injectable()
export class CategoriesEffects {
  init = createEffect(() =>
    this.actions.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMapTo(of(loadCategories()))
    )
  );

  loadCategories = createEffect(() =>
    this.actions.pipe(
      ofType(loadCategories),
      switchMap(() =>
        this.categoryService.getAll().pipe(
          map((response) => loadCategoriesSuccess({ categories: response })),
          catchError((error: HttpErrorResponse) =>
            of(loadCategoriesFailure({ errorCode: error.status }))
          )
        )
      )
    )
  );

  constructor(
    private categoryService: CategoryService,
    private actions: Actions
  ) {}
}
