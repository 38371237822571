import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'remainingTime',
})
export class RemainingTimePipe implements PipeTransform {
  transform(seconds: number): string {
    seconds = seconds + 1;
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = (seconds % 3600) % 60;
    const time = [];
    if (h > 0) {
      time.push(
        h.toString().padStart(2, '0') + 'h',
        m.toString().padStart(2, '0') + 'm',
        s.toString().padStart(2, '0') + 's'
      );
    } else if (m > 0) {
        time.push(
          m.toString().padStart(2, '0') + 'm',
          s.toString().padStart(2, '0') + 's'
        );
    } else {
        time.push(s.toString().padStart(2, '0') + 's');
    }
    return time.join(' ');
  }
}
