import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../config/app.config';
import { QuotationDto } from '../../DTO/quotation.dto';
import { QuotationItemDto } from '../../DTO/quotationItem.dto';
import { QuotationListDto } from '../../DTO/quotation-list.dto';
import { QuotationListItemDto } from '../../DTO/quotationListItem.dto';
import { PageableDTO } from '../../DTO/pageable.dto';
import { OrderDto } from '../../DTO/order.dto';
import {QuotationCartDto} from '../../DTO/quotation-cart.dto';
import {QuotationProjectionDto} from '../../DTO/quotation-projection.dto';

@Injectable({
  providedIn: 'root',
})
export class QuotationService {
  private url: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/quotation`;
  }

  public get(): Observable<QuotationListDto[]> {
    const url = `${this.url}/me`;
    return this.http.get<QuotationListDto[]>(url);
  }


  public getMyListQuotations(page: number, size: number, id: number , search?: string) {
    const url = `${this.url}/page`;
    let params = new HttpParams()
      .append('page', page != null ? page.toString() : '')
      .append('size', size != null ? size.toString() : '')
      .append('id', id != null ? id.toString() : '');
    if (search) {
      params = params.append('search', search);
    }
    return this.http.get<PageableDTO<QuotationListDto>>(url, { params });
  }
  public getListQuotations(page: number, size: number, id: number , search?: string) {
    const url = `${this.url}/page/${id}`;
    let params = new HttpParams()
      .append('page', page != null ? page.toString() : '')
      .append('size', size != null ? size.toString() : '')
      .append('id', id != null ? id.toString() : '');
    if (search) {
      params = params.append('search', search);
    }
    return this.http.get<PageableDTO<QuotationListDto>>(url, { params });
  }

  public getMyQuotations(page: number, size: number, id: number) {
    const url = `${this.url}/page/${id}`;
    const params = new HttpParams()
      .append('page', page.toString())
      .append('size', size.toString())
      .append('id', id.toString());
    return this.http.get<PageableDTO<QuotationDto>>(url, { params });
  }
  public downloadDevis(id: number): Observable<Blob> {
    const url = `${this.url}/download/pdf/${id}`;
    return this.http.get(url, { responseType: 'blob'});
  }

  public getCurrent(): Observable<QuotationDto> {
    const url = `${this.url}/me/open`;
    return this.http.get<QuotationDto>(url);
  }

  public add(
    productId: number,
    quantity: number,
    price: number
  ): Observable<QuotationDto> {
    const url = `${this.url}/item`;
    return this.http.post<QuotationDto>(url, { productId, quantity, price });
  }

  public addItems(
    quotationItems: QuotationItemDto[]
  ): Observable<QuotationDto[]> {
    const url = `${this.url}/items`;
    return this.http.post<QuotationDto[]>(url, quotationItems);
  }
  public addCartItems(
    quotationItems: QuotationCartDto[]
  ): Observable<any> {
    const url = `${this.url}/items`;
    return this.http.post<any>(url, quotationItems);
  }

  public updateItem(item: QuotationItemDto): Observable<QuotationItemDto> {
    const url = `${this.url}/item`;
    const quotationItem = {
      id: item.id,
      productId: item.product.id,
      quotationId: item.quotationId,
      quantity: item.quantity,
      price: item.price,
      itemPrice: item.itemPrice,
    };
    return this.http.patch<QuotationItemDto>(url, quotationItem);
  }

  public remove(id: number): Observable<void> {
    const url = `${this.url}/item/${id}`;
    return this.http.delete<void>(url);
  }

  public submit(
    quatationDto: Partial<QuotationDto>
  ): Observable<QuotationDto> {
    const url = `${this.url}/submit`;
    return this.http.post<QuotationDto>(url, quatationDto);
  }
  public getQuotationById(id: number): Observable<QuotationProjectionDto> {
    const url = `${this.url}/${id}`;
    return this.http.get<QuotationProjectionDto>(url);
  }
  public cancelQuotation(id: number): Observable<QuotationDto> {
    const url = `${this.url}/${id}/cancel`;
    return this.http.post<QuotationDto>(url, null);
  }
  public sendViaEmail(id: number, mail: string): Observable<any> {
    const url = `${this.url}/pdf/${id}?mail=${mail}`;
    return this.http.get<any>(url);
  }
  public addNewQuotation(quotationDto: any): Observable<QuotationDto> {
    const quotation = {
      address: quotationDto.address,
      clientId: quotationDto.clientId,
      items: [
        {
          product: { id: quotationDto.productId },
          quantity: quotationDto.quantity,
          price: quotationDto.price,
        },
      ],
      status: 'SUBMITTED',
      shippingCost: 0,
      message: quotationDto.message,
    };
    const url = `${this.url}/add`;
    return this.http.post<QuotationDto>(url, quotation);
  }
  public createOrderFromQuotation(
    id: number,
    chackoutRequest: any
  ): Observable<OrderDto> {
    const url = `${this.url}/${id}/accept`;
    return this.http.post<OrderDto>(url, chackoutRequest);
  }

}
