<mat-card (click)="sendProduct()" class="product-card" [ngClass]="{ 'list-card': cardType === 'list' }">
  <mat-card-content>
    <div class="product-image">
      <a [routerLink]="['/catalog/product/',this.product.id,modifyProductName(this.product.name)]">
        <img
          [src]="image ? image : 'assets/imtech-new-logo.svg'"
          alt="{{ product.name }}"
          (error)="image = null"
        />
      </a>
    </div>

    <div class="product-title">
      <a [routerLink]="['/catalog/product/',this.product.id,modifyProductName(this.product.name)]">
        <h2 title="{{ product.name }}">
          {{ product.name }}
        </h2>
      </a>
    </div>
    <div class="product-description">
      <p [routerLink]="['/catalog/product/',this.product.id,modifyProductName(this.product.name)]"
         [innerHTML]="product?.shortDescription !== 'False' ? product?.shortDescription : ''">
      </p>
      <div
        class="product-description-ref"
        fxLayout="row nowrap"
        fxLayoutGap="8px"
      >

        <span
          [ngClass]="{
            'in-stock__status': product?.productStatus === 'IN_STOCK',
            'on-command__status': product?.productStatus === 'ON_COMMAND',
            'obsolete__status': product?.productStatus === 'OBSOLETE',
            'out-of-stock__status': product?.productStatus === 'OUT_OF_STOCK'
          }"
        >
          {{ product?.productStatus === "IN_STOCK" ? "En stock" : "" }}
          {{ product?.productStatus === "OBSOLETE" ? "Produit obsolète" : "" }}
          {{ product?.productStatus === "OUT_OF_STOCK" ? "Rupture de stock" : "" }}
          {{ product?.productStatus === "ON_COMMAND" ? "Sur commande" : "" }}
        </span>
      </div>
    </div>
    <div *ngIf="isConnected===true" class="product-price">
      <span class="price">{{
        product?.price - (product?.price * product.discount) | currency: "EUR":'symbol'
        }}</span>
      <span class="old-price" *ngIf="discountDaysLeft > 0">
        {{ product.price | currency: "EUR":'symbol' }}
      </span>
    </div>

    <div *ngIf="isConnected===true" class="card-add-btn">
      <button
        *ngIf="(product?.productStatus === 'IN_STOCK' || product?.productStatus === 'ON_COMMAND') "
        (click)="addToCart()"
        mat-button
        class="app-raised-button add-cart-btn"
      >
       <div style="display: flex">
         <img style="width: 1.5em;  display: inline;" src="assets/icons/shopping_cart-white.svg" alt="shopping cart"/>

        <span style="color: white">&nbsp;AJOUTER AU PANIER</span>
       </div>
      </button>
    </div>
    <div *ngIf="isConnected===true" class="card-add-btn">
      <button
        *ngIf="product?.productStatus === 'OBSOLETE' || ( product?.productStatus === 'OUT_OF_STOCK')"
        (click)="addQuote()"
        mat-button
        class="app-raised-button add-cart-btn"
      >
        <div style="display: flex; align-items: center;">
          <img style="width: 1.5em;  display: inline;"  src="assets/icons/shopping_cart-white.svg" alt="shopping cart"/>
          <span style="color: white">          &nbsp;AJOUTER AU DEVIS
            </span>
        </div>
      </button>
    </div>
  </mat-card-content>

  <!--  Promo Labels  -->
  <ng-container *ngIf="discountDaysLeft > 0">
    <div class="promo-label">
      <span
      >PROMO -{{
        product.discount * 100 | number: "1.0-0"
        }}%</span
      >
    </div>
    <div class="end-promo-label">
      <span>Expire dans {{ discountDaysLeft }} jrs</span>
    </div>
  </ng-container>
</mat-card>
