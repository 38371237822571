import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CategoryDTO } from 'tekkeys-common';
import {
  selectParentCategories,
  selectChildCategories,
  selectSubChildCategories,
} from 'store/categories/categories.selectors';
import { SidenavService } from 'shared/services/sidenav/sidenav.service';
import { expansionAnimation } from 'shared/animations/expansion.animation';
import { ComponentPortal } from '@angular/cdk/portal';
import { SupportDialogComponent } from 'shared/components/support-dialog/support-dialog.component';
import { OverlayService } from 'shared/services/overlay/overlay.service';
import { map } from 'rxjs/operators';
import { AccountState } from 'store/account/account.state';
import { Router } from '@angular/router';
import { selectIsConnected } from 'store/account/account.selectors';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [expansionAnimation],
})
export class SidenavComponent implements OnInit {
  selectedCategoryId: number;
  selectedChildCategoryId: number;
  parentCategories: Observable<CategoryDTO[]>;

  username$: Observable<string> = this.store
    .select('account')
    .pipe(
      map((state: AccountState) =>
        state.account ? state.account.name : 'Se connecter'
      )
    );

  constructor(
    private store: Store<{ account: AccountState }>,
    private router: Router,
    private sidenavService: SidenavService,
    private overlayService: OverlayService
  ) { }
  ngOnInit(): void {
     this.parentCategories = this.store.select(
      selectParentCategories
    );
  }

  selectCategory(categoryId: number) {
    if (categoryId === this.selectedCategoryId) {
      this.selectedCategoryId = null;
    } else {
      this.selectedCategoryId = categoryId;
    }
  }
  // Second Level Categories
  selectChildCategory(childCategoryId: number) {
    if (childCategoryId === this.selectedChildCategoryId) {
      this.selectedChildCategoryId = null;
    } else {
      this.selectedChildCategoryId = childCategoryId;
    }
  }

  childCategories(categoryId: number): Observable<CategoryDTO[]> {
    return this.store.select((state) =>
      selectChildCategories(state, categoryId)
    );
  }

  subChildCategories(subCategoryId: number): Observable<CategoryDTO[]> {
    return this.store.select((state) =>
    selectSubChildCategories(state, subCategoryId)
    );
  }

  closeSidenav() {
    this.sidenavService.closeSidenav();
  }

  openSupportDialog() {
    this.closeSidenav();
    const supportDialog = new ComponentPortal(SupportDialogComponent);
    this.overlayService.openDialog(supportDialog);
  }

  onLogin() {
    this.store.select(selectIsConnected).subscribe((e) => {
      if (!e) {
        this.closeSidenav();
        this.router.navigate(['account', 'login'], {
          queryParams: { redirectUrl: this.router.url },
        });
      } else {
        this.closeSidenav();
        this.router.navigate(['profile'], {
          queryParams: { redirectUrl: this.router.url },
        });
      }
    });
  }

  onNews() {
    this.closeSidenav();
    this.router.navigate(['blog', 'list'], {
      queryParams: { redirectUrl: this.router.url },
    });
  }

  onLicence() {
    this.closeSidenav();
    this.router.navigate(['catalog', 'browse', '5', 'Logiciels-et-licenses', 'true'], {
      queryParams: { redirectUrl: this.router.url },
    });
  }

  onFAQ() {
    this.closeSidenav();
    this.router.navigate(['about', 'foire-aux-questions'], {
      queryParams: { redirectUrl: this.router.url },
    });
  }
}

