import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { SnackbarService } from 'shared/services/snackbar/snackbar.service';
import { OrderService } from 'tekkeys-common';

import * as ordersActions from './orders.actions';

@Injectable({ providedIn: 'root' })
export class OrdersEffects {
  loadMyOrders$ = createEffect(() => {
    return this.actions.pipe(
      ofType(ordersActions.loadOrders),
      mergeMap((action) =>
        this.orderService
          .getMyOrders(action.pageIndex, action.pageSize)
          .pipe(
            map((orders) =>
              ordersActions.loadOrdersSuccess({
                orders: orders.content,
                totalElements: orders.totalElements,
                totalPages: orders.totalPages,
              })
            ),
            catchError((e) => {
              this.snackbarService.openSnackBar(
                'Une erreur s\'est produite',
                'information-snackbar'
              );
              return EMPTY;
          })

          )
      )
    );
  });
  loadOrdersByStatus$ = createEffect(() => {
    return this.actions.pipe(
      ofType(ordersActions.loadOrdersByStatus),
      mergeMap((action) =>
        this.orderService
          .getOrdersByStatus(action.status, action.pageIndex, action.pageSize)
          .pipe(
            map((orders) =>
              ordersActions.loadOrdersSuccess({
                orders: orders.content,
                totalElements: orders.totalElements,
                totalPages: orders.totalPages,
              })
            ),
            catchError((e) => {
              this.snackbarService.openSnackBar(
                'Une erreur s\'est produite',
                'information-snackbar'
              );
              return EMPTY;
          })
          )
      )
    );
  });

// use new api TO FILTRE

loadMyOrdersOpt$ = createEffect(() => {
  return this.actions.pipe(
    ofType(ordersActions.loadOrdersOpt),
    mergeMap((action) =>
      this.orderService
        .getMyOrders(action.page, action.size)
        .pipe(
          map((orders) =>
            ordersActions.loadOrdersSuccess({
              orders: orders.content,
              totalElements: orders.totalElements,
              totalPages: orders.totalPages,
            })
          ),
          catchError((e) => {
            this.snackbarService.openSnackBar(
              'Une erreur s\'est produite',
              'information-snackbar'
            );
            return EMPTY;
        })

        )
    )
  );
});

  loadOrdersByFiltreOpt$ = createEffect(() => {
    return this.actions.pipe(
      ofType(ordersActions.loadOrdersByStatusOpt),
      mergeMap((action) =>
        this.orderService
          .getOrdersByFiltresOpt(action.status, action.page, action.size, action.startingDate, action.endingDate, action.search)
          .pipe(
            map((orders) =>
              ordersActions.loadOrdersSuccessOpt({
                orders: orders.content,
                totalElements: orders.totalElements,
                totalPages: orders.totalPages,
              })
            ),
            catchError((e) => {
              this.snackbarService.openSnackBar(
                'Une erreur s\'est produite',
                'information-snackbar'
              );
              return EMPTY;
          })
          )
      )
    );
  });

  constructor(private snackbarService:SnackbarService, private actions: Actions, private orderService: OrderService) {}
}
