<div id="htmlData" #htmlData>
  <div class="heading" id="heading">
    <div class="heading-left">
      <img alt=""
        src="assets/imtech-new-logo.svg"
        class="heading_left__logo"
        id="heading_left__logo"
        #heading_left__logo
      />
      <div class="heading-left__address">
        IMTECH Solutions : 34 Chemin de l'Armurié  <br />
        - 31770  Colomiers - 31100 Toulouse <br />
        Tel: +33 5 61 40 01 73 <br />
        N° IBAN: FR76 1751 5900 0008 0108 3775 432 <br />
        N° BIC: C E P A F R P P 7 5 1 <br />
        TVA: FR02828310383 <br />
        www.imtech.com <br />
        APE N°: 4690Z <br />
      </div>
    </div>
    <div style="display: flex; flex-direction: column; justify-content: center">
      <span class="heading-right" style="color: #404040">Commande N°</span
        ><br />
        <span class="heading-right" style="color: #18181B; font-weight: bold">{{
          order?.number
        }}</span>
    </div>
  </div>
  <br />
  <br />
  <div class="quotation-info">
    <div class="quotation-info__left">
      <table>
        <caption></caption>
        <tr>
          <td>N° Client:</td>
          <td>{{ companyNumber }}</td>
        </tr>
        <tr>
          <td>Société:</td>
          <td>{{ companyName }}</td>
        </tr>
        <tr>
          <td>Date de création:</td>
          <td>{{ order?.createdAt | date }}</td>
        </tr>
        <tr>
          <td>Téléphone:</td>
          <td>{{ companyPhone }}</td>
        </tr>
      </table>
    </div>
    <div class="quotation-info__right" *ngIf="order?.deliveryAddress">
      Vos informations de livraison:
      <table>
        <caption></caption>
        <tr>
          <td>Contact:</td>
          <td>{{ order?.deliveryAddress?.split("#")[0] }}</td>
        </tr>
        <tr>
          <td>Adresse:</td>
          <td>
            {{ order?.deliveryAddress?.split("#")[2] }}
            {{ order?.deliveryAddress?.split("#")[4] }}
            {{ order?.deliveryAddress?.split("#")[5] }}
          </td>
        </tr>
        <tr>
          <td>Code postal:</td>
          <td>{{ order?.deliveryAddress?.split("#")[3] }}</td>
        </tr>
        <tr>
          <td>Téléphone:</td>
          <td>{{ order?.deliveryAddress?.split("#")[1] }}</td>
        </tr>
      </table>
    </div>
  </div>
  <br />
  <br />
  <div class="">
    <table class="products-table">
      <caption></caption>
      <tr>
        <th>Code</th>
        <th>Désignation</th>
        <th>Quantité</th>
        <th>Prix Unitaire HT</th>
        <th>THT</th>
      </tr>
      <tr *ngFor="let item of order?.items">
        <td>{{ item.product?.manufacturerReference }}</td>
        <td class="designation">{{ item.product?.designation }}</td>
        <td>{{ item?.quantity }}</td>
        <td>{{ item?.price | number: ".2-2" }}</td>
        <td>{{ item?.price * item?.quantity | number: ".2-2" }}</td>
      </tr>
    </table>
    <br />
    <br />
    <table class="resume-table">
      <caption></caption>
      <tr>
        <th>Montant Net</th>
        <th>Frais Logistique</th>
        <th>TVA</th>
        <th>Montant Total</th>
      </tr>
      <tr>
        <td>{{ order?.initialPrice | number: ".2-2" }}</td>
        <td>{{ order?.shippingCost | number: ".2-2" }}</td>
        <td>
          {{ (order?.initialPrice + order?.shippingCost) * 0.2 | number: ".2-2" }}
        </td>
        <td
        style="background: #D50000;
      color: white;"
        >
          {{
            (order?.initialPrice + order?.shippingCost) * 1.2
              | currency: "EUR":true
          }}
        </td>
      </tr>
    </table>
  </div>
  <br />
  <br />
  <br />
  <br />
  <br />
  <div style="font-weight: bold">Important :</div>
  <div class="quotation-detail_footer">
    Toute réclamation concernant une livraison (Manquant, produit abimé, Erreur
    de référence, Erreur de packaging) doit nous parvenir par écrit, endéamns
    les 24 heures après réception de la marchandise. Au-delà, nous ne pourrons
    plus accepter de réclamation.<br />
    Nos conditions générales de vente sont applicables sur toutes nos
    transactions et vous ont été communiqués lors de la création de votre compte
    client.<br />
    Une compie est disponible sur demande.<br />
    Les conditions sont également disponibles sur  www.imtech.com
    <button *ngIf="order?.status === 'DELIVERED'" (click)="downloadPDF(order?.number)">
      <span>Télécharger</span>
      <mat-spinner [diameter]="20" *ngIf="isExporting"></mat-spinner>
    </button>
    <button *ngIf="order?.status === 'PAID'" (click)="downloadOrderPDF(order?.number)">
      <span>Télécharger</span>
      <mat-spinner [diameter]="20" *ngIf="isExporting"></mat-spinner>
    </button>
  </div>
</div>
