import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { QuotationDto, QuotationItemDto } from 'tekkeys-common';
import {QuotationCartDto} from '../../../../projects/tekkeys-common/src/lib/DTO/quotation-cart.dto';

export const loadQuotation = createAction('[Quotation] Load Quotation');

export const loadQuotationSuccess = createAction(
  '[Quotation] Load Quotation Success',
  props<{ quotation: QuotationItemDto[] }>()
);

export const addToQuotationItem = createAction(
  '[Quotation] Add Item To Quotation',
  props<{ productId: number; quantity: number; price: number }>()
);

export const addToQuotationSuccess = createAction(
  '[Quotation] Add Item To Quotation Success',
  props<{ quotation: QuotationDto }>()
);
export const addToQuotationCartItem = createAction(
  '[Quotation] Add Items To Quotation',
  props<{ cartQuotation: QuotationCartDto[] }>()
);

export const addToQuotationCartSuccess = createAction(
  '[Quotation] Add Items To Quotation Success',
  props<{ quotationCart: any }>()
);

export const addFromFavoriteToQuotation = createAction(
  '[Quotation] Add To Quotation From Favorites',
  props<{ productId: number; price: number }>()
);

export const updateItem = createAction(
  '[Quotation] Set Item Quantity',
  props<{ quotationItem: QuotationItemDto }>()
);

export const updateItemSuccess = createAction(
  '[Quotation] Set Item Quantity Success',
  props<{ update: Update<QuotationItemDto> }>()
);

export const removeQuotationItem = createAction(
  '[Quotation] Remove Item',
  props<{ id: number }>()
);

export const removeQuotationItemSuccess = createAction(
  '[Quotation] Remove Item Success',
  props<{ id: number }>()
);

export const clearQuotation = createAction('[Quotation] Clear Quotation');
