import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';


import  { DiscountCompanyDTO, DiscountCompanyService } from 'tekkeys-common';
import * as fromDiscountCompanyActions from "./discount-company.actions";


@Injectable()
export class DiscountCompanyEffects {

  constructor(
    private actions$: Actions,
    private discountCompanyService: DiscountCompanyService,
  ) {}

  /*loadDiscountCompany$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromDiscountCompanyActions.loadDiscountCompany),
        mergeMap((action) => this.discountCompanyService.getDiscountByCompanyId(action.companyId)
          .pipe(
            map((response) => {
              if (isCompanyDiscount(response))
                return fromDiscountCompanyActions.loadDiscountCompanySuccess({discountCompany: response})
            }),
            catchError(error => of(fromDiscountCompanyActions.loadDiscountCompanyFailure({ error })))
          )
        )
      )
  })*/

}

function isCompanyDiscount(obj: any): obj is DiscountCompanyDTO {
  return 'id' in obj && 'type' in obj && 'value' in obj && 'createdAt' in obj
    && 'endAt' in obj && 'discountStatus' in obj && 'companyId' in obj
}
