import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daysLeft',
})
export class DaysLeftPipe implements PipeTransform {
  private now = new Date();

  transform(date: Date): number {
    const targetDate = new Date(date);
    const timeToTargetDate = targetDate.getTime() - this.now.getTime();
    return Math.ceil(timeToTargetDate / (1000 * 3600 * 24));
  }
}
