import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {Store} from '@ngrx/store';
import {Observable, of, Subscription} from 'rxjs';
import {map, pluck, shareReplay} from 'rxjs/operators';
import {LocalCartService} from 'shared/services/localCart.service';
import {LoginOverlayService} from 'shared/services/overlay/login-overlay.service';
import {selectAccount, selectIsConnected} from 'store/account/account.selectors';
import {AccountState} from 'store/account/account.state';
import {fadeOut} from 'store/auth/auth.actions';
import {loadCart} from 'store/cart/cart.actions';
import {selectCartItemsCount, selectTotalCartItemsCount} from 'store/cart/cart.selectors';
import {CompanyState} from 'store/company/company.state';
import {NotificationsState} from 'store/notifications/notifications.state';
import {CartItemDTO} from 'tekkeys-common';

import {CompanyDTO} from '../../../../../projects/tekkeys-common/src/lib/DTO/company.dto';
import {selectCompanyId} from 'store/company/company.selectors';
import {ObservableAutoCleanupBean, untilComponentDestroyed} from '../../../utils/ngrx-destroy-observable';
import {Router} from '@angular/router';


@Component({
  selector: 'app-navbar-controls',
  templateUrl: './navbar-controls.component.html',
  styleUrls: ['./navbar-controls.component.scss'],
})
export class NavbarControlsComponent extends ObservableAutoCleanupBean implements OnInit {
  @ViewChild('logoutMenuTrigger') logoutMenuTrigger: MatMenuTrigger;
  @ViewChild('notificationsMenuTrigger')
  notificationsMenuTrigger: MatMenuTrigger;
  @Input() fixedHeader = false; // set to true in the fixed-header
  // in order to not get 2 superimposed login-dialog :

  overlayOpened = false;
  openedPane: 'login' | 'signup' | 'request-password-reset' = 'login';

  username: Observable<string> = this.store
    .select('account')
    .pipe(
      map((state: AccountState) =>
        state.account ? state.account.name : 'Se connecter'
      ),
    );
  companyStore: Observable<CompanyDTO> = this.store
    .select('company')
    .pipe(shareReplay(2), pluck('company'));

  isConnected: boolean;
  totalCartItemsCount: Observable<number>;
  favoritesCount: Observable<number>;
  clientRole: string;
  total: Observable<number>;
  subscriptions: Subscription[] = [];
  cartCount: CartItemDTO[] = [];
  companyName: string;
  companyNumber: string;
  companyId: number;

  constructor(
    private store: Store<{
      account: AccountState;
      company: CompanyState;
      notifications: NotificationsState;
    }>,
    private loginOverlayService: LoginOverlayService,
    private localCartService: LocalCartService,
    private rcf: ChangeDetectorRef,
    private router: Router

  ) {
    super();
    this.subscriptions.push(this.store.select(selectIsConnected).pipe(untilComponentDestroyed(this)).subscribe((isConnected) => {

      if (isConnected) {
        this.connected();
      } else {
        this.notConnected();
      }
      this.isConnected = isConnected;
    }));
  }

  ngOnInit() {
    this.store.select(selectAccount).subscribe(me => {
      if (me) {
        this.clientRole = me.role;
      }
    });
    this.store.select(selectCompanyId).subscribe(companyId => {
      this.companyId = companyId;

    });
    this.loginOverlayService.overlayOpened$.subscribe((e) => {
      if (!this.fixedHeader) {
        this.overlayOpened = e;
      }
    });
  }

  connected(){
    this.cartCount = localStorage.getItem('cart')
      ? JSON.parse(localStorage.getItem('cart'))
      : [];
    this.store.dispatch(loadCart());
    this.store.select(selectCartItemsCount).pipe().subscribe(value => {
      const b = value + this.cartCount.length;
      this.totalCartItemsCount = of(b);
      this.localCartService.clearCart();
    });
    this.store.select(selectTotalCartItemsCount).pipe().subscribe(value => {
      const a = this.cartCount.reduce(
        (acc: number, cur: CartItemDTO) =>
          acc +
          Number((cur.product.price - cur.product.discount).toFixed(2)) *
          cur.quantity,
        0
      );
      this.total = of(value + a);
    });
    this.rcf.markForCheck();
    this.companyStore.subscribe((data) => {
        if (data) {
          this.companyName = data.name ? data.name : '';
          this.companyNumber = data.accountNumber ? data.accountNumber : '';
        } else {
          this.companyName = '';
        }
      },
      () => {
        this.companyName = '';
      });
  }

  notConnected(){
    this.localCartService.cartItems$.pipe(untilComponentDestroyed(this)).subscribe((cartItems) => {
      if (cartItems) {
        this.totalCartItemsCount = of(cartItems.length);
        this.total = of(this.localCartService.getCartItemsPrice());
      }
    });
  }

  onAccountClick() {
    if (this.isConnected) {
      this.logoutMenuTrigger.openMenu();
    } else {
      this.logoutMenuTrigger.closeMenu();
      this.overlayOpened = true;
      this.openedPane = 'login';
    }
  }

  closeOverlay() {
    this.overlayOpened = false;
    this.store.dispatch(fadeOut());
    this.openedPane = 'login';
    this.loginOverlayService.closeLogin();
  }
  goToCartCheckout(){
    this.router.navigate(['/checkout']);
  }

}
