<!--section 404 start-->
<div class="bg-blue">
  <div class="section-404">
    <div
      class="stars"
      [ngStyle]="{
        'background-image': 'url(assets/images/404/overlay_stars.svg)'
      }"
    >
      <div class="">
        <div class="">
          <div class="">
            <div class="central-body">
              <h1 class="text-inner">404</h1>
              <h3 class="sub-text">page non trouvée</h3>
              <p class="text-404">
                La page à laquelle vous essayez d'accéder n'est pas disponible ! <br> Il se peut que cette page n'existe pas ou bien elle a été déplacée
              </p>
              <button
                mat-stroked-button
                [routerLink]="['/']"
                class="btn btn-default primary-btn transparent m-t-20"
              >
                Aller à la page d'accueil
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="objects">
      <img class="object_rocket" src="assets/images/404/rocket.svg" alt="Image 404-rocket"/>
      <div class="earth-moon">
        <img class="object_earth" src="assets/images/404/earth.svg" alt="Image 404-earth"/>
        <img class="object_moon" src="assets/images/404/moon.svg" alt="Image 404-moon"/>
      </div>
      <div class="box_astronaut">
        <img class="object_astronaut" src="assets/images/404/astronaut.svg" alt="Image 404-astronaut" />
      </div>
    </div>
    <div class="glowing_stars">
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
    </div>
  </div>
</div>
<!--section 404 end-->
