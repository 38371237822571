import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {MatAutocomplete} from '@angular/material/autocomplete';

@Component({
  selector: 'app-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['./email-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailInputComponent),
      multi: true,
    },
  ],
})
export class EmailInputComponent implements ControlValueAccessor {
  private innerValue: string;
  @Input()
  disable = false;
  @Input() matAutocomplete: MatAutocomplete;

  get value(): string {
    return this.innerValue;
  }

  set value(v: string) {
    this.innerValue = v;
    this.onChange(v);
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: string): void {
    this.innerValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
