<app-connection-dialog fxLayout="column">
  <button class="closeButton" (click)="close()">
    <img style="width: auto" src="assets/icons/close-gray.svg" alt="close"/>
  </button>
  <img  src="assets/icons/resend-code.svg" alt="img" />

  <h2 class="first-part">Lien de récupération envoyé

  </h2>

  <span class="second-part">
    Nous venons d'envoyé un e-mail à
    <strong> {{ data.email }} ,</strong>
     pour récupérer votre compte et changer votre mot de passe.<br />

    <strong
    >Si vous n'avez pas reçu l'émail cliquer sur <br> " Renvoyer " pour recevoir
      un nouveau lien de récupération.</strong
    >
  </span>
  <div>
    <button
      class="btn-resend"
      mat-button
      id="btnCounter"
      (click)="countDown(59); passwordMail()"
      [disabled]="(this.timer$ | async) === 0 ? !isCount : isCount"
    >
      {{
      (this.timer$ | async) === 0
        ? "RENVOYER"
        : "00" + ":" + ((this.timer$ | async) < 10 ? "0" + (this.timer$ | async) : (this.timer$ | async))
      }}
    </button>
  </div>
</app-connection-dialog>

