import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../config/app.config';
import { CategoryDTO } from '../../DTO/category.dto';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  private url: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/category`;
  }

  public getAll(): Observable<CategoryDTO[]> {
    return this.http.get<CategoryDTO[]>(this.url);
  }
  public getById(id: number): Observable<CategoryDTO> {
    const url = `${this.url}/${id}`;
    return this.http.get<CategoryDTO>(url);
  }
}
