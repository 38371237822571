import { Action, createReducer, on } from '@ngrx/store';
import {
  addToQuotationCartSuccess,
  addToQuotationSuccess,
  clearQuotation,
  loadQuotationSuccess,
  removeQuotationItemSuccess,
  updateItemSuccess,
} from 'store/quotation/quotation.actions';
import { quotationAdapter, QuotationState } from 'store/quotation/quotation.state';

export const initialState: QuotationState = quotationAdapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(loadQuotationSuccess, (state, action) => {
    return quotationAdapter.setAll(action.quotation, state);
  }),
  on(addToQuotationSuccess, (state, action) => {
    return quotationAdapter.setAll(action.quotation.items, state);
  }),
  on(addToQuotationCartSuccess, (state, action) => {
     return quotationAdapter.setAll(action.quotationCart.items, state);
   }),
  on(updateItemSuccess, (state, action) => {
    return quotationAdapter.updateOne(action.update, state);
  }),
  on(removeQuotationItemSuccess, (state, action) => {
    return quotationAdapter.removeOne(action.id, state);
  }),
  on(clearQuotation, (state) => {
    return quotationAdapter.removeAll(state);
  })
);

export function quotationReducer(state: QuotationState, action: Action) {
  return reducer(state, action);
}
