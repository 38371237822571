import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {SidenavService} from 'shared/services/sidenav/sidenav.service';

@Component({
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss'],
})
export class FullLayoutComponent implements OnInit, AfterViewInit {
  @ViewChild('sidenav') sidenav: MatSidenav;

  loading = true;

  constructor(
    private sidenavService: SidenavService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      } else if (event instanceof NavigationEnd) {
        this.loading = false;
      }
      this.cdr.detectChanges();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.sidenavService.setSidenav(this.sidenav);
    }, 20);
  }
}
