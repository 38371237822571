import {Component, Inject, OnInit} from '@angular/core';
import { ProductDTO} from 'tekkeys-common';
import { selectCartItem} from 'store/cart/cart.selectors';
import {UntypedFormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {LocalCartService} from 'shared/services/localCart.service';
import {LocalQuotationService} from 'shared/services/localQuotations.service';
import {selectIsConnected} from 'store/account/account.selectors';
import {debounceTime, distinctUntilChanged, take} from 'rxjs/operators';
import {setQuantity} from 'store/cart/cart.actions';
import {addToQuotationItem} from 'store/quotation/quotation.actions';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
export interface DATAPRODUCT {
  cartQuantity: number;
  product: ProductDTO;
  quantity: number;
  cartItemId: number;
  overQuantity: number;
  devisQuantity: number;
  localCartQuantity: number;
  remainder: boolean;
  status: string;
  image: string;
}
@Component({
  selector: 'app-add-to-card-popup',
  templateUrl: './add-to-card-popup.component.html',
  styleUrls: ['./add-to-card-popup.component.scss']
})
export class AddToCardPopupComponent implements OnInit {

  MESSAGE =
    'Livraison estimée dans 24-48 heures après la validation de la commande.';
  imageUrl = null;
  cartItem = this.store.select(selectCartItem(this.data.cartItemId));
  quantity: UntypedFormControl = new UntypedFormControl();
  max: number;
  quotation: boolean;
  finalQuantity = 0;
  constructor(
    private dialogRef: MatDialogRef<AddToCardPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DATAPRODUCT,
    private router: Router,
    private store: Store,
    private localCartService: LocalCartService,
    private localQuotationService: LocalQuotationService

  ) {}

  ngOnInit(): void {
    this.finalQuantity = 0;
    this.quotation = this.data?.status !== 'IN_STOCK' && !this.data.remainder;
    this.quantity.setValue(this.data.quantity);

    this.store.select(selectIsConnected).subscribe((isConnected) => {
      if (isConnected) {
        this.finalQuantity = this.data?.cartQuantity + this.data.quantity - this.quantity.value ;
      }
      else if (!isConnected){
        this.finalQuantity = this.data.cartQuantity + this.data.quantity - this.quantity.value ;
      }
    });
    this.imageUrl = this.data.image;
    if (!this.imageUrl) {
      this.imageUrl = 'assets/imtech-new-logo.svg';
    }
    let quantity = this.data.quantity;
    this.max = this.data.product.quantity;
    this.quantity.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((payload: number) => {
        this.store.select(selectIsConnected).subscribe((isConnected) => {
          if (!isConnected) {
            const cartItem = this.localCartService.getCartItem(
              this.data.product.id
            );
            this.localCartService.addCartItem(
              cartItem.product,
              payload - quantity
            );
            quantity = payload;
          }
        });
        this.cartItem.pipe(take(1)).subscribe((carTItem) => {
          this.store.dispatch(
            setQuantity({
              cartItemId: this.data.cartItemId,
              quantity: carTItem.quantity + payload - quantity,
            })
          );
        });

        quantity = payload;
      });
  }

  close() {
    this.dialogRef.close();
  }

  goToCart() {
    this.close();
    this.router.navigate(['/checkout']);
  }
  OpenQuotation(){
    this.store.select(selectIsConnected).subscribe((isConnected) => {
      if (!isConnected) {
        this.localQuotationService.addQuotationItem(
          this.data.product,
          this.data.devisQuantity
        );
        return;
      }
      const prices = (this.data.product.price - this.data.product.discount) * this.finalQuantity;
      this.store.dispatch(
        addToQuotationItem({
          productId: this.data.product.id,
          quantity: this.data.devisQuantity,
          price: prices,
        })
      );
    });
    this.router.navigate(['/checkout']);

  }

}
