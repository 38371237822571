<nav
  class="navbar"
  ngClass.lt-md="lt-md"
  fxLayout
  fxLayoutAlign="space-between center"
>
  <div fxFlex="153.7px" fxFlex.lt-md="109.8px">
    <app-navbar-logo></app-navbar-logo>
  </div>
  <div fxFlex.lt-md="145px">
    <div fxLayout fxLayoutAlign="start center" fxLayoutGap="8px">
      <img fxFlex="28px" src="assets/icons/check_circle.svg" alt="" />
      <span class="text">Votre partenaire de confiance</span>
    </div>
  </div>
</nav>
