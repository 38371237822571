import { Component } from '@angular/core';

@Component({
  selector: 'app-copyright-notice',
  templateUrl: './copyright-notice.component.html',
  styleUrls: ['./copyright-notice.component.scss'],
})
export class CopyrightNoticeComponent {
  year = new Date().getFullYear();
}
