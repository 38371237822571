import { Component, Input, OnInit } from '@angular/core';
import { DaysLeftPipe } from 'shared/pipes/days-left/days-left.pipe';
import { ProductDTO } from 'tekkeys-common';
import { CustomProductWindowComponent } from '../custom-product-window/custom-product-window.component';
import {MatDialog} from '@angular/material/dialog';


@Component({
  selector: 'app-product-accessory-card',
  templateUrl: './product-accessory-card.component.html',
  styleUrls: ['./product-accessory-card.component.scss']
})
export class ProductAccessoryCardComponent implements OnInit {
  @Input() product: ProductDTO;
  @Input() cardType = null;

  image: string = null;

  discountDaysLeft: number = null;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    const defaultImage = this.product.productResources.find((r) => r.isDefault);
    if (defaultImage) {
      this.image = defaultImage.link;
    } else {
      this.image = this.product?.productResources[0]?.link;
    }

    const daysLeftPipe = new DaysLeftPipe();
    if (this.product.discount) {
      this.discountDaysLeft = daysLeftPipe.transform(
        new Date(this.product?.discountEndDate)
      );
    }
  }



  showDialogBox(productId: number) {
    const data = {
      product: this.product,
      productId,
    };
    this.dialog.open(CustomProductWindowComponent, {
      data,
      maxWidth: '100%',
    });
  }

}
