import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators,} from '@angular/forms';
import {ClientService} from 'tekkeys-common';
import {RemainingTimePipe} from 'shared/pipes/remaining-time/remaining-time.pipe';
import {
  RequestPasswordResetPopupComponent
} from 'shared/components/request-password-reset-popup/request-password-reset-popup.component';
import {LoginOverlayService} from 'shared/services/overlay/login-overlay.service';
import {ObservableAutoCleanupBean, untilComponentDestroyed} from '../../../utils/ngrx-destroy-observable';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss'],
})
export class RequestPasswordResetComponent extends ObservableAutoCleanupBean implements OnInit {
  isLoading = false;
  success = false;
  error = false;
  errorMessage: string;

  @Output() closeOverlay = new EventEmitter<void>();


  requestPasswordResetForm: UntypedFormGroup;

  get mailControl(): AbstractControl {
    return this.requestPasswordResetForm.get('mail');
  }
  checkSubmit(event: KeyboardEvent): void {
    if (!this.requestPasswordResetForm.valid) {
      event.preventDefault(); // Prevent form submission
    } else {
      this.onRequestPasswordReset(event); // Call function if valid
    }
  }

  constructor(private clientService: ClientService,
              private fb: UntypedFormBuilder,
              public dialog: MatDialog,
              private loginOverlayService: LoginOverlayService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.requestPasswordResetForm = this.fb.group({
      mail: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})$/
          ),
        ]),
      ],
    });
  }

  close() {
    this.loginOverlayService.closeLogin();
  }

  onRequestPasswordReset(event?: KeyboardEvent) {
    if (event) {
      event.preventDefault(); // Prevent form submission
    }
    const payload = this.mailControl.value;
    this.isLoading = true;
    this.clientService.requestPasswordReset(payload).pipe(untilComponentDestroyed(this)).subscribe(
      () => {
        this.isLoading = false;
        this.success = true;
        this.error = false;
        setTimeout(() => {
          this.closeOverlay.emit();
          this.openDialog();
        }, 500);
      },
      (error) => {
        this.isLoading = false;
        this.success = false;
        this.error = true;
        switch (error.status) {
          case 403:
            this.errorMessage =
              'Ce compte est bloqué, essayez dans: ' +
              new RemainingTimePipe().transform(error.error.remainingTime);
            break;
          case 404:
            this.errorMessage = 'Aucun compte éxiste avec cette adresse mail';
            break;
          default:
            this.errorMessage = 'une erreur est survenue';
        }
      }
    );
  }

  openDialog() {
    this.dialog.open(RequestPasswordResetPopupComponent, {
      data: {
        email: this.mailControl.value.toLowerCase(),
      },
    });
  }
}
