import { Action, createReducer, on } from '@ngrx/store';

import {
  addFromFavoriteToCart,
  addToCart,
  addToCartSuccess,
  ApplyPromoCodeFailed,
  clearCart,
  getCartAndFavoriteItemCountFailure,
  getCartAndFavoriteItemCountSuccess,
  loadCartSuccess,
  removeCartItemSuccess,
  setQuantitySuccess,
} from './cart.actions';
import { cartAdapter, CartState } from './cart.state';

export const initialState: CartState = cartAdapter.getInitialState({
  isLoading: false,
  error: null,
  lastCartItemId: null,
  errorMessages: '',
  itemsCount: {
    cartItemCount: 0,
    favoriteItemCount: 0,
  }
});

const reducer = createReducer(
  initialState,
  on(loadCartSuccess, (state, action) => {
    return cartAdapter.setAll(action.cart, state);
  }),
  on(addToCart, (state, action) => {
    return { ...state, isLoading: true, errorMesages: null, error: null, lastCartItemId: null };
  }),
  on(addToCartSuccess, (state, action) => {
    return cartAdapter.upsertOne(action.cartItem, {
      ...state,
      isLoading: false,
      errorMesages: null,
      error: null,
      lastCartItemId: action.cartItem.id,
    });
  }),
  on(ApplyPromoCodeFailed, (state, action) => {
    let errorMessage: string;
    switch (action.error.error.message) {
      case 'promo code not found':
        errorMessage = 'Code promo introuvable';
        break;
      case 'promo code already used':
        errorMessage = 'Code promo déjà utilisé';
        break;
      case 'promo code already exists':
        errorMessage = 'Code promo existe déjà';
        break;
      case 'order price didn\'t reach minimum value to benefit promo code':
        errorMessage = 'le prix de la commande n\'a pas atteint la valeur minimale pour bénéficier du code promo';
        break;
      case 'product has dicount':
        errorMessage = 'le produit a une remise';
        break;
      case 'promo code expired':
        errorMessage = 'Malheureusement ce code promo à expiré ';
        break;
      case 'you reached maximum authorized promo code usage':
        errorMessage = 'Malheureusement vous avez dépassé le nombre d\'utilisation autorisé pour ce code promo!';
        break;
      default:
        errorMessage = 'une erreur est survenue !';
        break;
    }
    return {
      ...state,
      isLoading: false,
      error : action.error,
      errorMessages : errorMessage ,
      lastCartItemId: null,
    };
  }),
  on(addFromFavoriteToCart, (state, action) => {
    return { ...state, isLoading: true, errorMesages: null, error: null, lastCartItemId: null };
  }),
  on(ApplyPromoCodeFailed, (state, action) => {
    return { ...state, isLoading: false, errorMesages: null, error: action.error, lastCartItemId: null };
  }),
  on(setQuantitySuccess, (state, action) => {
    return cartAdapter.updateOne(action.update, {
      ...state,
      lastCartItemId: null,
    });
  }),
  on(removeCartItemSuccess, (state, action) => {
    return cartAdapter.removeOne(action.id, {
      ...state,
      lastCartItemId: null,
    });
  }),
  on(clearCart, (state) => {
    return cartAdapter.removeAll(state);
  }),
  on(getCartAndFavoriteItemCountSuccess, (state, action) => {
    return {
      ...state,
      itemsCount: action.itemsCount
    }
  }),
  on(getCartAndFavoriteItemCountFailure, (state, action) => {
    return {
      ...state,
      error: action.error
    }
  })
);

export function cartReducer(state: CartState, action: Action) {
  return reducer(state, action);
}
