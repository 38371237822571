import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {Component, Input, OnChanges, OnInit} from '@angular/core';
import { ProductDTO } from 'projects/tekkeys-common/src/lib/DTO/product.dto';

@Component({
  selector: 'app-window-product-carousel',
  templateUrl: './window-product-carousel.component.html',
  styleUrls: ['./window-product-carousel.component.scss']
})
export class WindowProductCarouselComponent implements OnInit, OnChanges  {
  @Input() product: ProductDTO;

  currentSlideIndex = 0;

  scrollbar = true;

  constructor(private breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.retrieveDefaultImage();
    this.breakpointObserver
      .observe(['(min-width: 914px)'])
      .subscribe((state: BreakpointState) => {
        this.currentSlideIndex = 0;
        this.scrollbar = state.matches;
      });
  }


  ngOnChanges() {
    this.currentSlideIndex = 0;
    this.retrieveDefaultImage();
    if (this.product.productResources.length === 0) {
      const image = {
        label: 'image placeholer',
        link: 'assets/imtech-logo.png',
        isDefault: true,
        type: 'IMAGE',
      };
      this.product.productResources.push(image);
    }
  }

  retrieveDefaultImage() {
    const defaultImage = this.product.productResources.find((r) => r.isDefault);
    if (defaultImage) {
      this.product.productResources.splice(
        this.product.productResources.indexOf(defaultImage),
        1
      );
      this.product.productResources = [defaultImage].concat(
        this.product.productResources
      );
    }
  }

  setSlide(slideIndex: number) {
    this.currentSlideIndex = slideIndex;
  }

  previousSlide() {
    if (this.currentSlideIndex === 0) {
      this.currentSlideIndex = this.product.productResources.length - 1;
    } else {
      this.currentSlideIndex = this.currentSlideIndex - 1;
    }
  }

  nextSlide() {
    this.currentSlideIndex =
      (this.currentSlideIndex + 1) % this.product.productResources.length;
  }

  zoom(e) {
    const zoom = e.currentTarget;
    const x = (e.offsetX / zoom.offsetWidth) * 100;
    const y = (e.offsetY / zoom.offsetHeight) * 100;
    zoom.style.backgroundPosition = x + '% ' + y + '%';
  }

  resize(e) {
    e.preventDefault();
    const zoom = e.currentTarget;
    const y = +zoom.style.backgroundSize.split('%').join('') - e.deltaY / 10;
    if (y < 300 && y > 100) {
      zoom.style.backgroundSize = `${y}%`;
    }
  }

  reset(e) {
    const zoom = e.currentTarget;
    zoom.style.backgroundSize = `150%`;
  }
}
