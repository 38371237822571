<ng-scrollbar style="min-width: 360px">
  <mat-card>
    <mat-card-content>
      <div fxLayout="row" fxLayoutAlign="none none">
        <h2 title="notifications">
          Mes notifications ({{ unseenNotificationsCount | async }})
        </h2>
      </div>
    </mat-card-content>
  </mat-card>

  <div
    *ngIf="(notificationsCount | async) > 0"
    class="drop-down-menu-content"
    fxLayout="column"
  >
    <app-notifications></app-notifications>
  </div>
  <div *ngIf="(notificationsCount | async) < 1">
    <span class="no-notifications">Aucune notification trouvée</span>
  </div>
</ng-scrollbar>
