import {BreakpointObserver} from '@angular/cdk/layout';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {selectAllCategories, selectParentCategories} from 'store/categories/categories.selectors';
import {CategoryDTO} from 'tekkeys-common';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-category-menu2',
  templateUrl: './category-menu2.component.html',
  styleUrls: ['./category-menu2.component.scss'],
})


export class CategoryMenu2Component implements OnInit {
  showDropDown = false;
  allCategories: CategoryDTO[] = [];
  parentCategories: CategoryDTO[] = [];
  subCategories: CategoryDTO[] = [];
  showItems = 7;

  selectedCategory: CategoryDTO = null;

  tablet = false;

  constructor(
    private breakPointObserver: BreakpointObserver,
    private store: Store,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectParentCategories)
      .subscribe((categories) =>
          {
            this.parentCategories = this.sortCategories(categories);
            this.selectedCategory = this.parentCategories[0];
          }
      );

    this.store
    .select(selectAllCategories)
    .subscribe((categories) => {
      this.allCategories = categories.filter((cat) => cat.status === true);
      });

    this.breakPointObserver
      .observe('(max-width: 1024px)')
      .subscribe((state) => {
        this.tablet = state.matches;
      });
  }

  private sortCategories(categories: CategoryDTO[]): CategoryDTO[] {
    categories.sort((a, b) => a.position - b.position);
    return categories;
  }

  selectCategory(category: CategoryDTO) {
    this.selectedCategory = category;
    this.getSubCategories(category);
  }

  getSubCategories(category: CategoryDTO) {
    this.subCategories = [];
    const filteredSubCategories = this.allCategories.filter(
      (c) => c.parentId === category?.id && c.status === true
    );
    this.subCategories = this.sortCategories(filteredSubCategories);
    this.subCategories = this.subCategories.map((subCategory) => {
      const children = this.allCategories.filter(
        (cat) => cat.parentId === subCategory.id && cat.status === true
      );

      return {
        ...subCategory,
        children: this.sortCategories(children),
      };
    });
  }

  navigate(
    categoryId: number,
    categoryName: string,
    categoryStatus: boolean,
    subCategoryId?: number,
    subCategoryName?: string,
    subCategoryStatus?: boolean,
    queryParams?: any
  ) {
    categoryName = categoryName?.trim().replace(/[^a-zA-ZÀ-ÿ0-9]/g, '-');
    subCategoryName = subCategoryName?.trim().replace(/[^a-zA-ZÀ-ÿ0-9]/g, '-');
    let navigationPath: any[];

    if (!subCategoryId) {
      if (!categoryStatus) {
        navigationPath = ['/not-found'];
      } else {
        navigationPath = ['/catalog/browse', categoryId, categoryName, categoryStatus];
      }
    } else if (!categoryStatus || !subCategoryStatus) {
      navigationPath = ['/not-found'];
    } else {
      navigationPath = [
        '/catalog/browse',
        categoryId,
        categoryName,
        categoryStatus,
        subCategoryId,
        subCategoryName,
        subCategoryStatus
      ];
    }


    this.router.navigate(navigationPath ,

        {
          queryParams: queryParams || null,
        }
      )
      .then(() => (this.showDropDown = false));
  }

  autoCloseForDropdownCars(event) {
    const target = event.target;
    if (!target.closest('.drop-down')) {
      this.showDropDown = false;
    }
  }

  show() {
    if (this.showDropDown === true){
    this.showDropDown = !this.showDropDown;
    }
  }
  toggleShowItems(items: CategoryDTO[]) {
    this.showItems = this.showItems === 7 ?  items.length : 7 ;
  }
}
