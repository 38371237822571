import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, switchMapTo } from 'rxjs/operators';
import { logout } from 'store/auth/auth.actions';
import { ClientService } from 'tekkeys-common';

import { clearCollaborators, loadCollaborators, loadCollaboratorsSuccess } from './collaborators.actions';

@Injectable()
export class CollaboratorsEffects {
  loadCollaborators = createEffect(() =>
    this.actions.pipe(
      ofType(loadCollaborators),
      switchMap(() =>
        this.clientService.getCollaborators().pipe(
          map((response) =>
            loadCollaboratorsSuccess({ collaborators: response })
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  logout = createEffect(() =>
    this.actions.pipe(ofType(logout), switchMapTo(of(clearCollaborators())))
  );

  constructor(private clientService: ClientService, private actions: Actions) {}
}
