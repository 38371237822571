import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { ClientAddressDTO } from 'tekkeys-common';

export const loadAddresses = createAction('[Addresses] Load Addresses');

export const loadAddressesSuccess = createAction(
  '[Addresses] Load Addresses Success',
  props<{ addresses: ClientAddressDTO[] }>()
);
export const loadAddressesFailure = createAction(
  '[Addresses] Load Addresses Failure',
  props<{ errorCode: number }>()
);

export const addToAddresses = createAction(
  '[Addresses] Add To Addresses',
  props<{ addresses: ClientAddressDTO; userId: number }>()
);

export const addToAddressesSuccess = createAction(
  '[Addresses] Add To Addresses Success',
  props<{ addresses: ClientAddressDTO }>()
);

export const addToAddressesFailed = createAction(
  '[Addresses] Add To Addresses Failed',
  props<{ error: Error }>()
);

export const updateAddresses = createAction(
  '[Account] Update Addresses',
  props<{
    addresses: ClientAddressDTO;
    partialAddress: Update<ClientAddressDTO>;
    userId: number;
  }>()
);

export const updateAddressesSuccess = createAction(
  '[Addresses] Update Addresses Success',
  props<{
    addresses: ClientAddressDTO;
    partialAddress: Update<ClientAddressDTO>;
  }>()
);

export const updateAddressesFailure = createAction(
  '[Addresses] Update Addresses Failure',
  props<{ errorCode: number }>()
);

export const removeAddress = createAction(
  '[Addresses] Remove Item',
  props<{ id: number; idUser: number }>()
);

export const removeAddressSuccess = createAction(
  '[Addresses] Remove Item Success',
  props<{ id: number }>()
);

export const clearAddresses = createAction('[Addresses] Clear Addresses');
