import { Component} from '@angular/core';
import {NewQuotationDialogComponent} from 'shared/components/new-quotation-dialog/new-quotation-dialog.component';
import {ComponentPortal} from '@angular/cdk/portal';
import {
  CompleteRegistrationComponent
} from '../../../../account/components/complete-registration/complete-registration.component';
import {OverlayService} from 'shared/services/overlay/overlay.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-snackbar-dialog',
  templateUrl: './snackbar-dialog.component.html',
  styleUrls: ['./snackbar-dialog.component.scss']
})
export class SnackbarDialogComponent  {

  constructor(
    private dialogRef: MatDialogRef<NewQuotationDialogComponent>,
    private overlayService: OverlayService,
  ) { }


close(){
    this.dialogRef.close();
}

  addCompany(){
    this.dialogRef.close();
    const newCompanyPortal = new ComponentPortal(CompleteRegistrationComponent);
    this.overlayService.openDialog(newCompanyPortal, true);  }
}
