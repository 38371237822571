import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../config/app.config';
import { ClaimDto } from '../../DTO/claim.dto';
import { PageableDTO } from '../../DTO/pageable.dto';
import { ClaimReasonDto } from '../../DTO/claim-reason.dto';
import { ConversationDto } from '../../DTO/conversation.dto';
import { ClaimOptDto } from '../../DTO/claimOpt.dto';

@Injectable({
  providedIn: 'root',
})
export class ClaimService {
  private url: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/complaint`;
  }
  public getMyClaims(
    page: number,
    size: number,
    sort: string,
    startDate: string,
    endDate: string,
    keyword: string
  ) {
    const url = `${this.url}/me/filter`;
    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('size', size.toString());
    params = sort ? params.append('sort', sort) : params;
    params = startDate ? params.append('startDate', startDate) : params;
    params = endDate ? params.append('endDate', endDate) : params;
    params = keyword ? params.append('keyword', keyword) : params;
    return this.http.get<PageableDTO<ClaimOptDto>>(url, { params });
  }

  public addClaim(claimDTO: any, files: File[]): Observable<ClaimDto> {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file, file.name);
    }
    formData.append('complaint', JSON.stringify(claimDTO));
    return this.http.post<ClaimDto>(this.url, formData);
  }

  public getClaimById(id: number): Observable<ClaimDto> {
    const url = `${this.url}/${id}`;
    return this.http.get<ClaimDto>(url);
  }

  public getClaimReasons(): Observable<ClaimReasonDto[]> {
    const url = `${this.url}/reason/activated`;
    return this.http.get<ClaimReasonDto[]>(url);
  }

  public getClaimReasonById(id: number): Observable<ClaimReasonDto> {
    const url = `${this.url}/reason/${id}`;
    return this.http.get<ClaimReasonDto>(url);
  }

  public addConversation(
    complaintId: number,
    files: File[],
    message: string
  ): Observable<ConversationDto> {
    const url = `${this.url}/${complaintId}/conversation/client`;

    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file, file.name);
    }
    formData.append('conversation', JSON.stringify({ message }));

    return this.http.post<ConversationDto>(url, formData);
  }
}
