import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {APP_CONFIG, AppConfig} from '../../config/app.config';
import {ProductDTO} from '../../DTO/product.dto';
import {PageableDTO} from '../../DTO/pageable.dto';
import {ProductFilterDTO} from '../../DTO/product-filter.dto';
import {Page} from '../../DTO/page.dto';
import {CharacteristicRequest} from '../../DTO/caracteristic-request';
import {ProductCardDTO} from '../../DTO/product-card.dto';
import {PartialProductDTO} from '../../DTO/partial-product.dto';
import {ProductFilterForm} from '../../DTO/productFilterForm';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private url: string;
  private url1: string;
  private urls: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/product`;
    this.url1 = `${this.appConfig.apiEndpoint}`;
    this.urls = `${this.appConfig.apiEndpoint}/stock`;


  }

  public getById(id: number): Observable<ProductDTO> {
    const url = `${this.url}/${id}`;
    return this.http.get<ProductDTO>(url);
  }

  public getByIdAndVisibility(id: number): Observable<ProductDTO> {
    const url = `${this.url}/visible/${id}`;
    return this.http.get<ProductDTO>(url);
  }

  getfais() {
    const url1 = `${this.url1}/deliveryFees`;
    return this.http.get<any>(url1);

  }

  public downloadProductList() {
    const url = `${this.url}/excel/products`;
    const headers = new HttpHeaders({
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const options = {
      headers,
      responseType: 'blob' as 'json', // Indique que la réponse est un blob
    };

    return this.http.get(url, options);
  }

  getQuantity(id: number) {
    const url1 = `${this.urls}/${id}`;
    return this.http.get<any>(url1);
  }
   getMinMax(id:number){
     const url = `${this.url1}/product/min-max/categories/${id}`;
     return this.http.get(url);
   }
  public getByFilter(
    filter: ProductFilterDTO
  ): Observable<PageableDTO<ProductCardDTO>> {
    const url = `${this.url}/filter`;

    const categories: number[] = [];
    if (filter.parentCategory && !filter.childCategory) {
      categories.push(filter.parentCategory);
    }
    if (filter.childCategory && filter.grandChildCategories.length === 0) {
      categories.push(filter.childCategory);
    }
    if (filter.grandChildCategories.length > 0) {
      categories.push(...filter.grandChildCategories);
    }

    const form: ProductFilterForm = {
      min: filter?.minPrice,
      max: filter?.maxPrice,
      keyword: filter?.query,
      inStock: filter?.inStock,
      manufacturers: filter?.manufacturerIds,
      categories
    };

    const params = new HttpParams()
      .set('page', String(filter.page || 0))
      .set('size', String(filter.pageSize || 30))
      .set('sort', filter.sortBy || 'name');

    return this.http.post<PageableDTO<ProductCardDTO>>(url, form, {params}).pipe(catchError(error => of(error)));
  }

  public getByQuery(
    query: string,
    page: number = 0,
    pageSize: number = 15
  ): Observable<PartialProductDTO[]> {
    const url = `${this.url}/index`;

    const params = new HttpParams()
      .append('search', query)
      .append('page', page.toString())
      .append('size', pageSize.toString());

    const options = {params};

    return this.http.get<Page<PartialProductDTO>>(url, options)
      .pipe(map(p => p.content));
  }

  public getByQueryOld(
    query: string,
    page: number = 0,
    pageSize: number = 15
  ): Observable<ProductDTO[]> {
    const url = `${this.url}`;

    const params = new HttpParams()
      .append('search', query)
      .append('page', page.toString())
      .append('size', pageSize.toString());

    const options = {params};

    return this.http
      .get<Page<ProductDTO>>(url, options)
      .pipe(map((p) => p.content.filter((p) => p.visible)));
  }

  public getSimilarProduct(
    id: string,
    pageIndex: number,
    pageSize: number
  ): Observable<PageableDTO<ProductCardDTO>> {
    const url = `${this.url}/${id}/similarProductsCards`;
    const params = new HttpParams()
      .append('page', pageIndex.toString())
      .append('size', pageSize.toString());
    return this.http.get<PageableDTO<ProductCardDTO>>(url, {params});
  }

  public getProductAccessories(
    id: string,
    pageIndex: number,
    pageSize: number
  ): Observable<PageableDTO<ProductDTO>> {
    const url = `${this.url}/${id}/accessories`;
    const params = new HttpParams().append('page', pageIndex.toString()).append('size', pageSize.toString());
    return this.http.get<PageableDTO<ProductDTO>>(url, {params});
  }


  public getProductSpecifications(
    id: number
  ): Observable<CharacteristicRequest[]> {
    const url = `${this.url}/${id}/productcharacterstics`;
    return this.http.get<any>(url);
  }

  getProductLicenses(id: number): Observable<any> {
    const url = `${this.url}/${id}/licenses`;
    return this.http.get<any>(url);
  }
}
