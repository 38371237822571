import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProductFavOptDto } from 'projects/tekkeys-common/src/lib/DTO/productFavOpt.dto';
import { CartItemOptDTO } from 'projects/tekkeys-common/src/public-api';
import { BehaviorSubject } from 'rxjs';
import { CartItemDTO, CartService, ProductDTO } from 'tekkeys-common';

@Injectable({
  providedIn: 'root',
})
export class LocalCartService {
  cartItems: CartItemDTO[] = [];
  cartItemsOpt: CartItemOptDTO[] = [];

  cartSubject = new BehaviorSubject<CartItemDTO[]>([]);
  public readonly cartItems$ = this.cartSubject.asObservable();
  terminateSubject = new BehaviorSubject<boolean>(false);

  public readonly isTerminated$ = this.terminateSubject.asObservable();

  constructor(
    private cartService: CartService,
    private router: Router
  ) {
    this.cartItems = localStorage.getItem('cart')
      ? JSON.parse(localStorage.getItem('cart'))
      : [];
    this.cartSubject.next(this.cartItems);
  }

  getShippingCost() {
    return this.cartItems.filter((item) => item.product.physical).length
      ? 15
      : 0;
  }

  getCartItemsPrice() {
    return this.cartItems.reduce(
      (price: number, item: CartItemDTO) =>
        price +
        Number((item.product.price - item.product.discount).toFixed(2)) *
        item.quantity,
      0
    );
  }

  getCartItem(productId: number) {
    return this.cartItems.find((item) => item.product.id === productId);
  }

  getCartItems() {
    this.cartItems = localStorage.getItem('cart')
      ? JSON.parse(localStorage.getItem('cart'))
      : this.cartItems;
    this.cartSubject.next(this.cartItems);
    return this.cartItems;
  }

  addCartItem(product: ProductDTO, quantity: number) {
    let cartItem = this.cartItems.find((f) => f.product.id === product.id);
    cartItem = cartItem
      ? {...cartItem, quantity: cartItem.quantity + quantity}
      : {
        product,
        quantity,
        createdAt: new Date(),
      };
    this.cartItems = [
      ...this.cartItems.filter((c) => c.product.id !== product.id),
      cartItem,
    ];
    localStorage.setItem('cart', JSON.stringify(this.cartItems));
    this.getCartItems();
  }
  addCartItemOpt(product: ProductFavOptDto, quantity: number) {
    let cartItem = this.cartItemsOpt.find((f) => f.product.id === product.id);
    cartItem = cartItem
      ? {...cartItem, quantity: cartItem.quantity + quantity}
      : {
        product,
        quantity,
        createdAt: new Date(),
      };
    this.cartItemsOpt = [
      ...this.cartItemsOpt.filter((c) => c.product.id !== product.id),
      cartItem,
    ];
    localStorage.setItem('cart', JSON.stringify(this.cartItems));
    this.getCartItems();
  }

  deleteCartItem(productId: number) {
    localStorage.setItem(
      'cart',
      JSON.stringify(this.cartItems.filter((f) => f.product.id !== productId))
    );
    this.getCartItems();
  }

  addToCart() {
    if (this.getCartItems().length) {
      this.cartService
        .addMultiple(this.getCartItems()).pipe().subscribe(cartItems => {
          if (cartItems) {
            this.terminateSubject.next(true);
          }
      });
    }
  }

  clearCart() {
    if (JSON.parse(localStorage.getItem('cart'))) {
      localStorage.removeItem('cart');
      this.cartItems = [];
      this.cartSubject.next(null);
      this.router.ngOnDestroy();
    }
  }
}
