import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {of} from 'rxjs';
import {GetFooterInfo, loadFooterInfoFailure, loadFooterInfoSuccess} from 'store/footer-info/footer-info.action';
import {FooterInfoService} from '../../../../projects/tekkeys-common/src/lib/DAO/footer/footer-info.service';

@Injectable()
export class FooterInfoEffects {

  constructor(
    private footerInfo: FooterInfoService,
    private actions: Actions
  ) {}
  loadFooterInfo = createEffect(() =>
    this.actions.pipe(
      ofType(GetFooterInfo),
      switchMap(() =>
        this.footerInfo.getAllContactInformation().pipe(
          map((response) => loadFooterInfoSuccess({ footerInfo: response })),
          catchError((error: HttpErrorResponse) =>
            of(loadFooterInfoFailure({ errorCode: error.status }))
          )
        )
      )
    )
  );
}
