import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, switchMapTo } from 'rxjs/operators';
import { SnackbarService } from 'shared/services/snackbar/snackbar.service';
import {
  loadAccount,
  loadAccountFailure,
  loadAccountSuccess,
  unloadAccount,
  updateAccount,
  updateAccountFailure,
  updateAccountSuccess,
} from 'store/account/account.actions';
import { loginSuccess, logout } from 'store/auth/auth.actions';
import { ClientService } from 'tekkeys-common';

@Injectable()
export class AccountEffects {
  init = createEffect(() =>
    this.actions.pipe(ofType(ROOT_EFFECTS_INIT), switchMapTo(of(loadAccount())))
  );

  loadAccount = createEffect(() =>
    this.actions.pipe(
      ofType(loadAccount),
      switchMap(() =>
        this.clientService.me().pipe(
          map((response) => loadAccountSuccess({ account: response })),

          catchError((error: HttpErrorResponse) =>
            of(loadAccountFailure({ errorCode: error.status }))
          )
        )
      )
    )
  );

  updateAccount = createEffect(() =>
    this.actions.pipe(
      ofType(updateAccount),
      switchMap((action) =>
        this.clientService.updateMe(action.accountUpdateRequest).pipe(
          map((response) => updateAccountSuccess({ account: response })),
          catchError((error: HttpErrorResponse) =>
            of(updateAccountFailure({ errorCode: error.status }))
          )
        )
      )
    )
  );

  updateAccountSuccess = createEffect(
    () =>
      this.actions.pipe(
        ofType(updateAccountSuccess),
        map(() =>
          this.snackbarService.openSnackBar(
            'Vos informations on été mis à jour',
            'success-snackbar'
          )
        )
      ),
    { dispatch: false }
  );

  updateAccountFailure = createEffect(
    () =>
      this.actions.pipe(
        ofType(updateAccountFailure),
        map(() =>
          this.snackbarService.openSnackBar(
            'Une erreur est survenue !',
            'error-snackbar'
          )
        )
      ),
    { dispatch: false }
  );
  // Note : we can retrieve a custom error message from AccountState
  // see the commented code in EditIdentityDialogComponent

  loginSuccess = createEffect(() =>
    this.actions.pipe(ofType(loginSuccess), switchMapTo(of(loadAccount())))
  );

  logout = createEffect(() =>
    this.actions.pipe(ofType(logout), switchMapTo(of(unloadAccount())))
  );

  constructor(
    private clientService: ClientService,
    private snackbarService: SnackbarService,
    private actions: Actions
  ) {}
}
