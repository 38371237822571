import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as AccessosiesAction from './accessories.actions';
import { AccessoriesDto } from '../../../../projects/tekkeys-common/src/lib/DTO/accessories.dto';

export interface AccessoriesState extends EntityState<AccessoriesDto[]> {
  listLoading: boolean;
}

export const adapter: EntityAdapter<AccessoriesDto[]> =
  createEntityAdapter<AccessoriesDto[]>();

export const initialAccessorieState: AccessoriesState = adapter.getInitialState({
  listLoading: false
});

const accessorieReducer = createReducer(
  initialAccessorieState,

// get list accissories product
  on(AccessosiesAction.AccessoriesPageRequested, (state, action) => ({
    ...state,
    listLoading: false
  })),
  on(AccessosiesAction.AccessoriesPageLoadedSuccessfully, (state, action) =>
    adapter.setAll(action.accessories.content, {
      ...state,
      listLoading: false,
    })
  ),
  on(AccessosiesAction.AccessoriesPageLoadFailed, (state: AccessoriesState, action) => {
    let errorMessage: string;
    if (action.error.status === 500) {
      errorMessage = 'EDIT.ERROR.SERVER';
    } else {
      errorMessage = 'EDIT.ERROR.UNKNOWN';
    }
    return {
      ...state,
      listLoading: false,
      actionsLoading: false,
      errorMessage,
      error: action.error,
    };
  }),
);
export function accessoriesReducer(
  state: AccessoriesState | undefined,
  action: Action
) {
  return accessorieReducer(state, action);
}

export const {selectAll} = adapter.getSelectors();

