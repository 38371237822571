import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { auditTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ProductDTO, ProductService, PartialProductDTO } from 'tekkeys-common';

@Component({
  selector: 'app-navbar-search',
  templateUrl: './navbar-search.component.html',
  styleUrls: ['./navbar-search.component.scss'],
})
export class NavbarSearchComponent {
  searchControl = new UntypedFormControl('');
  searchResults: Observable<PartialProductDTO[]> = this.searchControl.valueChanges.pipe(
    auditTime(300),
    distinctUntilChanged(),
    map((query: string) => query?.trim().replace(/\s\s+/g, ' ')),
    switchMap((query: string) =>
      query !== '' ? this.productService.getByQuery(query) : of(null)
    )
  );

  constructor(private productService: ProductService, private router: Router) {
  }

  search(event: ProductDTO) {
    this.searchResults.subscribe(res => console.log('hey', res) );
    if (this.searchControl?.value !== '') {
      if ('id' in event) {
        const productName = event.name.trim().replace('/', '%2F');
        this.router.navigateByUrl(
          '/catalog/product/' + event.id + '/' + productName
        );
      } else {
        this.router.navigate(['/catalog/browse/'], {
          queryParams: {
            query: this.searchControl.value
              ? this.searchControl.value?.trim().replace(/\s\s+/g, ' ')
              : null,
          },
        });
      }
      this.searchControl.setValue('');
    }
  }
  searchIconClicked(){
    if (this.searchControl?.value !== '') {
        this.router.navigate(['/catalog/browse/'], {
          queryParams: {
            query: this.searchControl.value
              ? this.searchControl.value?.trim().replace(/\s\s+/g, ' ')
              : null,
          },
        });
        this.searchControl.setValue('');
    }
  }
}
