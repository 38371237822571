import { Component, Inject, OnInit } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ClientService } from 'tekkeys-common';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-request-password-reset-popup-component',
  templateUrl: './request-password-reset-popup.component.html',
  styleUrls: ['./request-password-reset-popup.component.scss']
})
export class RequestPasswordResetPopupComponent implements OnInit {
  isCount = true;
  time = 59;
  timer$: Observable<number>;
  timerSub: Subscription;
  show = false;

  constructor( public dialogRef: MatDialogRef<RequestPasswordResetPopupComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any,
               private clientService: ClientService) {}

  ngOnInit(): void {
    this.countDown(0);
  }
  countDown(time: number) {
    this.timer$ = timer(0, 1000).pipe(
      map((i) => {
        return time - i;
      }),
      take(time + 1)
      // finalize(() => console.log('DONE')),
      // share()
    );
    this.timerSub = this.timer$.subscribe();
  }
  close() {
    this.dialogRef.close();
  }
  passwordMail() {
    const payload = this.data.email;
    this.clientService.requestPasswordReset(payload).subscribe();
  }

}
