import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { seenNotification } from 'store/notifications/notifications.actions';
import { selectNotifications } from 'store/notifications/notifications.selectors';
import { NotificationsState } from 'store/notifications/notifications.state';

import { NotificationDTO } from '../../../../../projects/tekkeys-common/src/lib/DTO/notification.dto';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent{
  constructor(private store: Store<{ notifications: NotificationsState }>) {}

  notificationsStore$: Observable<NotificationDTO[]> = this.store.select(
    selectNotifications
  );

  seenNotification(notification: NotificationDTO) {
    this.store.dispatch(
      seenNotification({ notificationId: notification.id, seen: true })
    );
  }

  getNotificationTitle(notification: NotificationDTO): string {
    let preTitle = '';
    let title = '';
    let postTitle = '';

    switch (notification.objectAction) {
      case 'REFUSED':
        postTitle = 'est réfusé';
        break;
      case 'ACCEPTED':
        postTitle = 'est accepté';
        break;
    }

    switch (notification.objectType) {
      case 'COMPLAINT':
        title = 'réclamation';
        postTitle = postTitle + 'e';
        break;
      case 'ORDER':
        title = 'commande';
        postTitle = postTitle + 'e';
        break;
      case 'QUOTATION':
        title = 'devis';
        break;
    }

    if (notification.objectType === 'COMPLAINT' ||
      notification.objectType === 'ORDER' ||
      notification.objectType === 'QUOTATION') {
      preTitle = 'votre';
    }

    return `${preTitle} <span>${title}</span> ${postTitle}`;
  }

  getNotificationSubTitle(notification: NotificationDTO): string {
    let preTitle = '';
    switch (notification.objectType) {
      case 'order':
        preTitle = 'N° Commande ';
        break;
      case 'quotation':
        preTitle = 'N° Devis ';
        break;
      case 'promo':
        preTitle = 'sur le produit ';
        break;
    }
    return `${preTitle}${notification.objectTitle}`;
  }
}
