<div [ngClass]="isDefault ? 'dialog' : 'mini_dialog'">
  <div class="dialog-titlebar">

    <button mat-icon-button (click)="close()">
      <img src="assets/icons/close.svg" alt="close"/>
    </button>
  </div>

  <div class="dialog-content">
    <div class="">

      <div class="note" fxLayout="column">
        <span class="text" fxFlex="70" fxFlex.xs="100">
          Certain de vos produits n'acceptent pas les reliquats, il vont par conséquent être transféré vers la section devis! sinon cliquer annuler pour commander uniquement la quantité en stock!
        </span>
      </div>
    </div>

    <div gdArea="controls" fxLayout fxLayoutGap="16px" fxLayoutGap.xs="8px" style="margin-top: 12px">
      <button mat-button class="strocked-btn" (click)="close()" fxFlex="">
        ANNULER
      </button>

      <button
        mat-button
        class="next-btn"
        (click)="next() ;"
        fxFlex=""
      >
        CONFIRMER
      </button>
      <button
        *ngIf="isVirtual"
        mat-button
        class="next-btn"
        (click)="next()"
        fxFlex=""
        [ngClass]="{
          'btn-disabled':
            message.value === ''
        }"
      >
        ENVOYER
      </button>
    </div>
  </div>
</div>
