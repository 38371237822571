<div class="dialog">
  <div class="dialog-titlebar">
    <span >
      <img alt="" src="assets/icons/check_circle_white.svg" />Produit ajouté au devis avec
      succès</span
    >
    <button  style="border: none;background-color: transparent" (click)="close()">
      <img src="assets/icons/close.svg" alt="close" />
    </button>
  </div>

  <div
    gdColumns="155px 1fr"
    gdRows="auto auto"
    gdGap="16px"
    gdAreas="image info | controls controls"
    gdColumns.xs="1fr"
    gdRows.xs="102px auto auto"
    gdGap.xs="8px"
    gdAreas.xs="image | info | controls"
    class="dialog-content"
  >
    <img
      gdArea="image"
      class="image"
      [src]="imageUrl"
      (error)="imageUrl = 'assets/imtech-new-logo.svg'"
      alt="product"
    />

    <div gdArea="info">
      <h1 class="designation" title="{{ data.product.designation }}">
        {{ data.product.designation }}
      </h1>

      <div
        class="info"
        fxLayout
        fxLayoutGap="25px"
        fxLayout.xs="column"
        fxLayoutGap.xs="2px"
      >
        <span *ngIf="data.product.code">Imtech : {{ data.product.code }}</span>
      </div>

      <div *ngIf="data.overQuantity && !data.remainder" class="info-msg_container">
        <img alt="" src="assets/icons/info.svg" />
        <span>Il y a que <strong>{{this.data.product.quantity}}</strong> articles en stock. Vous pouvez demander un devis pour le reste <strong>({{this.data.devisQuantity}})</strong></span>
      </div>

      <div
        class="info-input_container"
        fxLayout="row"
        fxLayoutGap="16px"
        fxLayoutGap.xs="auto"
        fxLayoutAlign="start end"
      >

        <div  fxLayout.xs="column" class="total-price" >
          <div class="align-section">
          <span>Quantité:<span class="quantity">
            {{this.data.quantity}}
            </span
            >  </span>

            <span>Total :<span class="emphasis">
            {{
              (data.product.price - data.product.discount) * this.data.quantity| number: "1.2-2"
              }}
              €</span> </span>

          </div>
        </div>
      </div>
    </div>

    <div
      gdArea="controls"
      fxLayout
      fxLayoutGap="16px"
      fxLayout.xs="column"
      fxLayoutGap.xs="8px"
    >
      <button *ngIf="!this.data.overQuantity" mat-button class="strocked-btn" (click)="close()" fxFlex="">
        POURSUIVRE MES ACHATS
      </button>
      <button *ngIf="this.data.overQuantity" mat-button class="strocked-btn" (click)="close(); OpenQuotation()" fxFlex="">
        RÉALISER UN DEVIS POUR LE REST
      </button>

      <button mat-button class="add-to-cart-btn" (click)="goToCart()" fxFlex="">
        <div style="display: flex">
          <img src="assets/icons/shopping_cart-white.svg" alt="shopping cart" />
          <span style="margin-top: 3px" >VOIR MON DEVIS</span>
        </div>
      </button>
    </div>
  </div>
</div>
